<template>
<div v-if="flag === 'sale'">
  <block-tech-sale></block-tech-sale>
</div>
<div v-else-if="flag === 'tech'">
  <block-tech-tech></block-tech-tech>
</div>

</template>

<script>
import BlockTechSale from './techSale' //销售
import BlockTechTech from './techTech' //技术

export default {
  data() {
    return {}
  },
  props: {
    flag: {
      type: String,
      default: function() {
        return '';
      }
    }
  },
  components: {
    'block-tech-sale': BlockTechSale,
    'block-tech-tech': BlockTechTech
  }
}
</script>
