<style scoped lang="less">
  .header-title{
    font-size: 16px;
  }

  .header-title .el-button{
    height: 32px;
    padding: 0 15px;
    margin-left: 10px;
    background: #14B8D4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .apply-for>header{
    background: #fff;
  }
  .apply-for>div{
    background: #fff;
  }

  .apply-for-header>form{
    padding: 10px 10px 0 10px;
  }

  label{
    margin-right: 20px;
  }

  .content-header{
    border-bottom: 1px solid #14B8D4;
    padding: 10px;
  }
  .content-header>div{
    display: inline-block;
    line-height: 30px;
  }
  .btn-container{
    display: inline-block;
    float: right;
  }
  .el-button{
    margin-left: 5px;
    background: #fff;
    border: 1px solid #14B8D4;
    border-radius: 10px;
    color: #14B8D4;
    font-weight: 600;
    padding: 6px 10px;
  }
  .el-button i{
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-size: contain;
    vertical-align: middle;
  }
  i.icon-add{
    background: url('../../assets/applyFor/add.png') no-repeat;
  }
  i.icon-load{
    background: url('../../assets/applyFor/load.png') no-repeat;
  }
  .el-button.btn-blue{
    background: #14B8D4;
    color: #fff;
  }

  .form-container{
    padding: 20px 20px 0 20px;
  }
  .form-group{
    margin-bottom: 20px;
    color: #333;
  }
  .form-group span{
    display: inline-block;
    text-align: right;
    width: 120px;
    margin: 0 4px;
  }
  .form-group span.ifAbroadMarket{
    width: 164px;
  }
  .form-group span.validate-tip{
    width: 100%;
    padding: 10px 140px 0;
    text-align: left;
    display: block;
    color: #f00;
  }
  .form-group mark{
    background: #fff;
    color: #f00;
    margin: 4px;
    font-size: 16px;
  }
  .form-group>input{
    width: 320px;
    height: 32px;
    padding-left: 20px;
  }
  .form-group select{
    width: 100px;
    height: 32px;
    color: #333;
  }

  .card-container{
    padding: 10px 60px 0;
  }
  .card-container>header{
    padding: 0 10px 20px;
  }
  .card-container>header>.btn-container{
    float: none;
  }
  .card-container>header>.btn-container>.el-button{
    font-size: 14px;
    font-weight: 400;
  }
  .avatar-uploader{
    display: inline-block;
  }

  .card-container>section{
    text-align: center;
  }
  .card-wrapper{
    display: inline-block;
    width: 400px;
    height: 300px;
    border: 1px solid #14B8D4;
    position: relative;
  }
  .card-wrapper>img{
    width: 400px;
    height: 300px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .cancelOk{
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  .header-choose{
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .cancel-active{
    white-space: nowrap;
  }

  .search-input {
    display: inline-block;
    width: 320px;
    height: 32px;
  }
  .form-input {
    display: inline-block;
    width: 320px;
    height: 32px;
    /deep/ .el-input, /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
  .date-deadline{
    width: 120px;
    margin-right: 20px;
  }
  .form-select {
    width: 100px;
    /deep/ .el-input {
      width: 100%;
      height: 100%;
    }
  }

  .search-info{
    width: 200px;
    height: 32px;
    margin-left: 10px;
  }
</style>

<style lang="less">
  @import '../../../public/static/css/common.css';
</style>

<template>
  <section>
    <div>
      <header>
        <div class="header-title header-choose">
          <label for="applyForList">
            <el-radio id="applyForList" label="1" v-model="changeListFlag">申请试用</el-radio>
          </label>
          <label for="postponeList">
            <el-radio id="postponeList" label="0" v-model="changeListFlag">申请延期记录</el-radio>
          </label>
        </div>
      </header>
    </div>
    <div class="apply-for-content" v-show="changeListFlag + '' === '1'">
      <header class="content-header">
        <div class="header-title">
          <span>账号状态</span>
        </div>
        <div class="btn-container">
          <el-button type="primary" @click="addAccount()"><i class="icon icon-add"></i>新增账号</el-button>

          <el-upload
            class="avatar-uploader"
            :action="formUploadActionUrl"
            :data="formUploadParams"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="handleUploadFileSuccess"
            :on-change="beforeUploadFileUpload">
            <el-button class="btn-blue" type="primary">
              <i class="icon-load"></i>批量导入
            </el-button>
          </el-upload>

          <a class="el-button btn-blue" role="button" @click="downloadFile"><i class="icon icon-load"></i>下载模板</a>
        </div>
      </header>
      <section>
        <div class="header-title pt-10">
          <el-input class="search-info" v-model="searchInfo" placeholder="查询手机号码" />
          <el-input class="search-info" v-model="searchCompany" placeholder="查询公司" />
          <el-button @click="initCustomerList(true)">查询</el-button>
        </div>
        <div class="query-result" v-show="showDataContainer">
          <div class="table-wrapper">
            <table>
              <thead>
              <tr>
                <th>手机号</th>
                <th>姓名</th>
                <th>公司名称</th>
                <th v-if="tab === 'com-bidding'">用户类型</th>
                <th>职位</th>
                <th>申请时间</th>
                <th>通过时间</th>
                <th>试用期限</th>
                <th>会员有效期</th>
                <th v-if="tab === 'com-invest'">是否有海外权限</th>
                <th>审核状态</th>
                <th>取消申请</th>
                <th>编辑信息</th>
                <th>编辑名片</th>
                <th v-if="tab === 'com-invest'">申请方向</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
                <td><div>{{item.phone}}</div></td>
                <td><div>{{item.name}}</div></td>
                <td class="text-left"><div>{{item.company}}</div></td>
                <td v-if="tab === 'com-bidding'"><div>{{item.userType === 0 ? '采购方' : item.userType === 2 ? '供应商': '--'}}</div></td>
                <td><div>{{item.position}}</div></td>
                <td><div>{{item.createTime}}</div></td>
                <td><div>{{item.insertTime}}</div></td>
                <td><div>{{item.dateType}}</div></td>
                <td><div>{{item.token}}</div></td>
                <td v-if="tab === 'com-invest'"><div>{{item.abroadMarket + '' === '1' ? '是' : '否'}}</div></td>
                <td>
                  <div v-if="item.applyState === '1'" class="passed">已通过</div>
                  <div v-if="item.applyState === '2'" class="unpassed">未通过</div>
                  <div v-if="item.applyState === '0'" class="unpassed">审核中</div>
                </td>
                <td>
                  <div v-if="item.applyState === '1'"><a class="cancel-disabled disabled">取消</a></div>
                  <div v-if="item.applyState === '2'"><a @click="ifSubmit(item)" class="cancel-active">重新提交</a></div>
                  <div v-if="(item.applyState === '0' && item.cancel === '1')"><a @click="ifCancel(item)" class="cancel-active">重新提交</a></div>
                  <div v-if="(item.applyState === '0' && item.cancel === '0')"><a @click="ifCancel(item)" class="cancel-active">取消</a></div>
                </td>
                <td>
                  <div v-if="item.applyState === '1'">
                    <a class="disabled"><img src="../../assets/applyFor/un-edit.png" /></a>
                  </div>
                  <div v-if="item.applyState !== '1'">
                    <a @click="editInfo(item)"><img src="../../assets/applyFor/edit.png" /></a>
                  </div>
                </td>
                <td>
                  <div v-if="item.applyState === '1'">
                    <a class="disabled"><img src="../../assets/applyFor/un-upload.png" /></a>
                  </div>
                  <div v-if="item.applyState !== '1'">
                    <a @click="editCard(item)"><img src="../../assets/applyFor/upload.png" /></a>
                  </div>
                </td>
                <td v-if="tab === 'com-invest'">
                  <div>{{ childPlatform(item.childPlatform) }}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <page-control
              v-on:getPageData="renderData"
              v-on:getCustomerList="initCustomerList"
              :customerData="customerListStore"
              :currentPage="currentPage"
              :pageNum="pageNum"
              :firstPageShow="firstPageShow"
              :prePageShow="prePageShow"
              :nextPageShow="nextPageShow"
              :pageRefer="pageRefer"
              :showPageNum="true"
              :loading="imgLoading">
          </page-control>

        </div>
        <div class="no-data" v-show='noData'>
          <img src="../../assets/noData.png" />
          <span>暂无数据</span>
        </div>
      </section>
    </div>

    <div class="apply-for-content" v-show="changeListFlag + '' === '0'">
      <header class="content-header">
        <div class="header-title">
          <span>账号状态</span>
        </div>
      </header>
      <section>
        <div class="header-title pt-10">
          <el-input class="form-input search-info" v-model="searchInfoPostpone" placeholder="查询手机号码" />
          <el-input class="form-input search-info" v-model="searchCompanyPostpone" placeholder="查询公司" />
          <el-button @click="initPostponeList(true)">查询</el-button>
        </div>
        <div class="query-result" v-show="showDataContainerPostpone">
          <div class="table-wrapper">
            <table>
              <thead>
              <tr>
                <th>手机号</th>
                <th>姓名</th>
                <th>公司名称</th>
                <th>职位</th>
                <th>申请时间</th>
                <th>通过时间</th>
                <th>延长期限</th>
                <th>审核状态</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in postponeList" :class="index % 2 === 0 ? '' : 'tr-even'">
                <td><div>{{item.phone}}</div></td>
                <td><div>{{item.name}}</div></td>
                <td class="text-left"><div>{{item.company}}</div></td>
                <td><div>{{item.position}}</div></td>
                <td><div>{{item.createTime}}</div></td>
                <td><div>{{item.insertTime}}</div></td>
                <td><div>{{item.dateType}}</div></td>
                <td>
                  <div v-if="item.applyState === '1'" class="passed">已通过</div>
                  <div v-if="item.applyState === '2'" class="unpassed">未通过</div>
                  <div v-if="item.applyState === '0'" class="unpassed">审核中</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <page-control
            v-on:getPageData="renderDataPostpone"
            v-on:getCustomerList="initPostponeList"
            :customerData="postponeListStore"
            :currentPage="currentPagePostpone"
            :pageNum="pageNumPostpone"
            :firstPageShow="firstPageShowPostpone"
            :prePageShow="prePageShowPostpone"
            :nextPageShow="nextPageShowPostpone"
            :pageRefer="pageReferPostpone"
            :showPageNum="true"
            :loading="imgLoadingPostpone">
          </page-control>
        </div>
        <div class="no-data" v-show='noDataPostpone'>
          <img src="../../assets/noData.png" />
          <span>暂无数据</span>
        </div>
      </section>
    </div>

    <div class="dialog-container">
      <!--新增账号弹窗-->
      <el-dialog title="新增账号" :visible.sync="addAccountDialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>手机号:</span>
              <el-input class="form-input" type="text" v-on:blur="validatePhone(addAccountData.phone,'phone')" v-model="addAccountData.phone" />
              <span class="validate-tip" v-show="phoneError">请输入正确的手机号码！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>姓名:</span>
              <el-input class="form-input" type="text" v-on:blur="validatePhone(addAccountData.name,'name')" v-model="addAccountData.name" />
              <span class="validate-tip" v-show="nameError">请输入姓名！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>公司名称:</span>
<!--              <input type="text" v-on:blur="validatePhone(addAccountData.company,'company')" v-model="addAccountData.company" />-->
              <company-search
                class="search-input"
                @search="getCompanyList"
                @blur="companyBlur"
                @scrollLoad="getCompanyList"
                @itemClick="companyClick"
                :list="list"
                :total="total"
                :companyName="addAccountData.company"
                :companyShow="tab === 'com-bidding'"
              ></company-search>
              <span class="validate-tip" v-show="companyError">请输入公司名称！</span>
            </div>
            <div class="form-group" v-if="tab === 'com-bidding'">
              <span><mark>*</mark>用户类型:</span>
              <el-select class="form-input" v-model="addAccountData.userType">
                <el-option value="0" label="采购方">采购方</el-option>
                <el-option value="2" label="供应商">供应商</el-option>
              </el-select>
            </div>
            <div class="form-group">
              <span><mark>*</mark>职位:</span>
              <el-input class="form-input" type="text" v-on:blur="validatePhone(addAccountData.position,'position')" v-model="addAccountData.position" />
              <span class="validate-tip" v-show="positionError">请输入职位！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>试用期限:</span>
              <el-input class="form-input date-deadline" v-on:blur="validatePhone(addAccountData.dateDate,'dateDate')" type="text" v-model="addAccountData.dateDate" />
              <el-select class="form-input form-select" v-model="addAccountDateType">
                <el-option value="日">日</el-option>
                <el-option value="周">周</el-option>
                <el-option value="月">月</el-option>
                <el-option value="年">年</el-option>
              </el-select>
              <span class="validate-tip" v-show="dateError">请输入试用期限(最多两位数字格式)！</span>
            </div>
            <div class="form-group" v-if="tab === 'com-invest'">
              <span class="ifAbroadMarket"><mark>*</mark>是否有海外权限:</span>
              <label><el-radio type="radio" label="1" v-model="addAccountData.abroadMarket">是</el-radio></label>
              <label><el-radio type="radio" label="0" v-model="addAccountData.abroadMarket">否</el-radio></label>
            </div>

            <div class="form-group" v-if="tab === 'com-invest'">
              <span><mark>*</mark>申请方向:</span>
              <el-select v-model="applyForValue" class="com-select">
                <el-option v-for="(item, keys) in applyForSelectList" :key="keys" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </form>
        </div>
        <div class="card-container">
          <header>
            <span>名片：</span>
            <div class="btn-container">
              <el-upload
                class="avatar-uploader"
                :action="formActionUrl"
                :data="formParams"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAddCardUpload">
                <el-button class="btn-blue" type="primary">点击上传</el-button>
              </el-upload>
              <el-button type="primary" @click="rotateDeg()">旋转90°</el-button>
            </div>
          </header>
          <section>
            <div class="card-wrapper">
              <img v-if="imageUrl" :style="rotateStyle" :src="imageUrl" alt="">
            </div>
            <p>只支持 JPEG、PNG、GIF，大小不超过10M</p>
          </section>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitAddAccount(addAccountData)">提交</el-button>
			    <el-button @click="addAccountDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>

      <!--编辑修改信息-->
      <el-dialog title="编辑信息" :visible.sync="editAccountDialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>手机号:</span>
              <el-input class="form-input" type="text" v-model="formData.phone" v-on:blur="validatePhone(formData.phone,'emptyPhone')" />
              <span class="validate-tip" v-show="phoneError">请输入正确的手机号码！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>姓名:</span>
              <el-input class="form-input" type="text" v-on:blur="validatePhone(formData.name,'name')" v-model="formData.name" />
              <span class="validate-tip" v-show="nameError">请输入姓名！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>公司名称:</span>
<!--              <el-input class="form-input" type="text" v-on:blur="validatePhone(formData.company,'company')" v-model="formData.company" />-->
              <company-search
                  class="search-input"
                  @search="getEditCompanyList"
                  @blur="editCompanyBlur"
                  @scrollLoad="getEditCompanyList"
                  @itemClick="editCompanyClick"
                  :list="editList"
                  :total="editTotal"
                  :companyName="formData.company"
                  :companyShow="tab === 'com-bidding'"
              ></company-search>
              <span class="validate-tip" v-show="companyError">请输入公司名称！</span>
            </div>
            <div class="form-group" v-if="tab === 'com-bidding'">
              <span><mark>*</mark>用户类型:</span>
              <el-select class="form-input" v-model="formData.userType">
                <el-option value="0" label="采购方">采购方</el-option>
                <el-option value="2" label="供应商">供应商</el-option>
              </el-select>
            </div>
            <div class="form-group">
              <span><mark>*</mark>职位:</span>
              <el-input class="form-input" type="text" v-on:blur="validatePhone(formData.position,'position')" v-model="formData.position" />
              <span class="validate-tip" v-show="positionError">请输入职位！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>试用期限:</span>
              <el-input class="form-input date-deadline"  v-on:blur="validatePhone(formData.dateDate,'dateDate')" type="text" v-model="formData.dateDate" />
              <el-select class="form-input form-select" v-model="editDataDateType">
                <el-option value="日">日</el-option>
                <el-option value="周">周</el-option>
                <el-option value="月">月</el-option>
                <el-option value="年">年</el-option>
              </el-select>
              <span class="validate-tip" v-show="dateError">请输入试用期限(最多两位数字格式)！</span>
            </div>
            <div class="form-group" v-if="tab === 'com-invest'">
              <span class="ifAbroadMarket"><mark>*</mark>是否有海外权限:</span>
              <label><el-radio type="radio" label="1" v-model="formData.abroadMarket">是</el-radio></label>
              <label><el-radio type="radio" label="0" v-model="formData.abroadMarket">否</el-radio></label>
            </div>

            <div class="form-group" v-if="tab === 'com-invest'">
              <span><mark>*</mark>申请方向:</span>
              <el-select v-model="formData.childPlatform" class="com-select">
                <el-option v-for="(item, keys) in applyForSelectList" :key="keys" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </form>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitEditInfo(formData)">提交</el-button>
			    <el-button @click="editAccountDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>

      <!--确定取消申请-->
      <el-dialog :visible.sync="cancelApplyDialogVisible" width="300px">
        <span>{{cancelTipText}}</span>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitCancelApply(ifResubmit)">确定</el-button>
			    <el-button @click="cancelApplyDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>
      <!--取消成功-->
      <el-dialog :visible.sync="cancelSuccessDialogVisible" width="300px">
        <div class="cancelOk">提交成功！</div>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>
      <!--取消成功-->
      <el-dialog :visible.sync="cancelFailDialogVisible" width="300px">
        <div class="cancelOk">提交失败！</div>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>

      <!--编辑名片-->
      <el-dialog title='编辑名片' :visible.sync="editCardDialogVisible" width="600px">
        <div class="card-container">
          <header>
            <span>名片：</span>
            <div class="btn-container">
              <el-upload
                class="avatar-uploader"
                :action="formActionUrl"
                :data="formParams"
                :show-file-list="false"
                :on-success="handleEditCardUploadSuccess"
                :before-upload="beforeEditCardUpload">
                <el-button class="btn-blue" type="primary">点击上传</el-button>
              </el-upload>

              <el-button type="primary" @click="rotateDeg()">旋转90°</el-button>
            </div>
          </header>
          <section>
            <div class="card-wrapper">
              <img v-if="editCardImgUrl" :style="rotateStyle" :src="editCardImgUrl" />
            </div>
            <p>只支持 JPEG、PNG、GIF，大小不超过10M</p>
          </section>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitEditCard()">确定</el-button>
			    <el-button @click="editCardDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
  import PageControl from '@/components/PageControl'
  import syAxios from '../../../public/static/common/axios.js'
  import getConfig from '../../../public/static/common/config.js'
  import axios from "axios";
  import Utils from "../../../public/static/common/common";
  import CompanySearch from './components/companySearch.component';

  export default{
    data(){
      return{
        changeListFlag : '1',
        postponeList : [],
        postponeListStore : [],
        showDataContainerPostpone : false,
        noDataPostpone : false,
        pageSizePostpone : 10,
        pageNumPostpone : 1,
        currentPagePostpone : 0,
        firstPageShowPostpone : false,
        prePageShowPostpone : false,
        nextPageShowPostpone : false,
        imgLoadingPostpone : false,
        pageReferPostpone : 'postpone',
        searchInfoPostpone : '',
        searchCompanyPostpone : '',

        pageSize : 10,
        pageNum : 1,
        currentPage : 0,
        searchInfo : '',
        searchCompany : '',
        phoneError : false,
        nameError : false,
        companyError : false,
        positionError : false,
        dateError : false,
        addAccountDialogVisible : false,
        editAccountDialogVisible : false,
        cancelApplyDialogVisible : false,
        editCardDialogVisible : false,
        cancelSuccessDialogVisible : false,
        cancelFailDialogVisible : false,
        showDataContainer : false,
        noData : false,
        firstPageShow : false,
        prePageShow : false,
        nextPageShow : false,
        imgLoading : false,
        pageRefer : 'applyFor',
        currentEditId : '',
        currentEditCard : '',
        currentCancelId : '',
        currentCancelPhone: '',
        currentCancelApplyState: '',
        isCancel : '',
        optionsData : [],
        customerList : [],
        customerListStore : [],
        ifResubmit : false,

        rotateCount : 0,
        rotateStyle : '',
        formUploadActionUrl : '',//导入
        formUploadParams : {
          filename : ''
        },
        imageUrl : '',
        editCardImgUrl : '',
        formParams : {
          userId : ''
        },
        formActionUrl : '',//上传
        dialogImageUrl : '',

        addAccountDateType : '日',
        addAccountData : {
          phone : '',
          name : '',
          company : '',
          position : '',
          dateDate : '',
          dateType : '日',
          abroadMarket : '0',
          applyId : '',
          card : '',
          userType: '0'
        },
        editDataDateType : '日',
        formData : {
          phone : '',
          name : '',
          company : '',
          position : '',
          dateDate : '',
          dateType : '日',
          abroadMarket : '0',
          applyId : '',
          childPlatform: '',
          userType: '0'
        },
        cancelTipText : '确定取消申请吗？',
        submitAddClicked : false,

        applyForValue: 1,
        applyForSelectList: [
          {
            label: '产融平台',
            value: 1
          },
          {
            label: '乡村振兴',
            value: 2
          },
          {
            label: '绿色金融',
            value: 3
          }
        ],
        list: [],
        total: 0,
        editList: [],
        editTotal: 0,
        timer: null
      }
    },
    components: {
      PageControl,
      CompanySearch
    },
    props: {
      tab: {
        type: String,
        default: function(){
          return '';
        }
      },
      queryId: {
        type: String,
        default: function(){
          return '';
        }
      },
    },
    watch: {
      queryId: function(newVal){
        if(newVal){
          this.initCustomerList(true);
          this.initPostponeList(true);
        }
      }
    },
    activated() {
      let that = this;
      let config = getConfig();
      that.formUploadActionUrl = config.proxyHeader + config.importMould;
      if(that.tab === 'com-risk'){
        that.formUploadActionUrl = config.proxyHeader + config.importMouldBond;
      }
    },
    mounted : function(){
      let that = this;
      let config = getConfig();
      that.initCustomerList(true);
      that.initPostponeList(true);
      that.formUploadActionUrl = config.proxyHeader + config.importMould;
      if(that.tab === 'com-risk'){
        that.formUploadActionUrl = config.proxyHeader + config.importMouldBond;
      }
      that.formActionUrl = config.proxyHeader + config.uploadMould;
    },
    methods : {
      beforeUpload(file) {
        const isFile = file.name.split('.')[1] === 'xls' || file.name.split('.')[1] === 'xlsx';

        if (!isFile) {
          alert('请上传excel类型文件!');
          return false;
        }
        this.readFile(file);
        return false;
      },
      // 读取文件转换json
      readFile(file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadstart = (e) => {
          this.tableLoading = true;
        };
        reader.onprogress = (e) => {
          this.progressPercent = Math.round((e.loaded / e.total) * 100);
        };
        reader.onerror = (e) => {
          alert("文件读取出错");
        };
        reader.onload = (e) => {
          const data = e.target.result;
          const {results} = this.$VUtils.read(data, "array");
          let tableKey = [];
          if (this.tab === 'com-invest') {
            tableKey = [
              {
                name: '手机号',
                key: 'phone'
              },
              {
                name: '姓名',
                key: 'name'
              },
              {
                name: '公司名称',
                key: 'company'
              },
              {
                name: '职位',
                key: 'position'
              },
              {
                name: '申请期限',
                key: 'date'
              },
              {
                name: '是否具有海外权限',
                key: 'abroadMarket'
              },
              {
                name: '申请方向',
                key: 'childPlatform'
              }
            ];
          } else if (this.tab === 'com-risk') {
            tableKey = [
              {
                name: '手机号',
                key: 'phone'
              },
              {
                name: '姓名',
                key: 'name'
              },
              {
                name: '公司名称',
                key: 'company'
              },
              {
                name: '职位',
                key: 'position'
              },
              {
                name: '申请期限',
                key: 'date'
              }
            ];
          } else if (this.tab === 'com-bidding') {
            tableKey = [
              {
                name: '手机号',
                key: 'phone'
              },
              {
                name: '姓名',
                key: 'name'
              },
              {
                name: '公司名称',
                key: 'company'
              },
              {
                name: '职位',
                key: 'position'
              },
              {
                name: '用户类型',
                key: 'userType'
              },
              {
                name: '申请期限',
                key: 'date'
              }
            ]
          }
          let tableData = [];
          //这里的tableData就是拿到的excel表格中的数据
          tableData = results.map((item, index) => {
            let value = {};
            tableKey.forEach(res => {
              for (let i in item) {
                if (i.includes(res.name)) {
                  value[res.key] = item[i];
                  break;
                } else {
                  value[res.key] = '';
                }
              }
            })
            value.id = index + 1;
            return value;
          })
          let date = Date.now();
          window.localStorage.setItem('checkTableData' + date, JSON.stringify(tableData));
          //跳转校验列表
          this.$router.push({
            name: 'checkList',
            query: {
              type: this.tab,
              date: date
            }
          })
        };
      },
      childPlatform(platform) {
        let text = '';
        platform = platform + '';
        switch (platform) {
          case '0':
            text = '招采';
            break;
          case '1':
            text = '产融平台';
            break;
          case '2':
            text = '乡村振兴';
            break;
          case '3':
            text = '绿色金融';
            break;
        }
        return text || '--';
      },
      getCompanyList(pageNum, value) {
        let that = this;
        let config = getConfig();
        let data = {
          searchContent: encodeURIComponent(value),
          type: 100,
          contain: 'ACompany,ThirdCompany,FourthCompany,OthersCompany',
          pageNum: pageNum,
          pageSize: that.pageSize
        }

        syAxios({
          ifProxy : true,
          url: config.index.applyFor.searcheTip,
          data: data,
          success: function(d){
            let data = d.data || {};
            that.list = data.dataList || [];
            if (that.list.length > 10) {
              that.list = that.list.slice(0, 10);
            }
            that.total = data.total || 0;
          }
        })
      },
      companyBlur(value) {
        this.addAccountData.company = value || '';
        this.validatePhone(this.addAccountData.company,'company');
      },
      companyClick(item) {
        this.addAccountData.company = item.cname || '';
        this.validatePhone(this.addAccountData.company,'company')
        if(this.tab === 'com-bidding') {
          if (item.type === '采购方') {
            this.addAccountData.userType = '0'
          } else if (item.type === '供应商') {
            this.addAccountData.userType = '2'
          }
        }
      },
      getEditCompanyList(pageNum, value) {
        let that = this;
        let config = getConfig();
        let data =  {
          searchContent: encodeURIComponent(value),
          type: 100,
          contain: 'ACompany,ThirdCompany,FourthCompany,OthersCompany',
          pageNum: pageNum,
          pageSize: that.pageSize
        }

        syAxios({
          ifProxy : true,
          url: config.index.applyFor.searcheTip,
          data: data,
          success: function(d){
            let data = d.data || {};
            that.editList = data.dataList || [];
            if (that.editList.length > 10) {
              that.editList = that.editList.slice(0, 10);
            }
            that.editTotal = data.total || 0;
          }
        })
      },
      editCompanyBlur(value) {
        this.formData.company = value || '';
        this.validatePhone(this.formData.company,'company');
      },
      editCompanyClick(item) {
        this.formData.company = item.cname || '';
        this.validatePhone(this.formData.company,'company');
        if(this.tab === 'com-bidding') {
          if (item.type === '采购方') {
            this.formData.userType = '0'
          } else if (item.type === '供应商') {
            this.formData.userType = '2'
          }
        }
      },
      validatePhone : function(value,type){
        let that = this;
        if(type + '' === 'phone'){
//					let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/g;
          let reg = /1\d{10}$/g;
          if(!reg.test(Number(value))){
            that.phoneError = true;
            return false;
          }else{
            that.phoneError = false;
            return true;
          }
        } else if(type + '' === 'emptyPhone'){
          let reg = /1\d{10}$/g;
          if(!reg.test(Number(value)) && !(/^(\d{3})(?:\*+)(\d{4})$/.test(value + ''))){
            that.phoneError = true;
            return false;
          }else{
            that.phoneError = false;
            return true;
          }
        } else if(type + '' === 'name'){
          if(value + '' === ''){
            that.nameError = true;
            return false;
          }else{
            that.nameError = false;
            return true;
          }
        }else if(type + '' === 'company'){
          if(value + '' === ''){
            that.companyError = true;
            return false;
          }else{
            that.companyError = false;
            return true;
          }
        }else if(type + '' === 'position'){
          if(value + '' === ''){
            that.positionError = true;
            return false;
          }else{
            that.positionError = false;
            return true;
          }
        }else if(type + '' === 'dateDate'){
          let ifNumber = /^\d{1,2}$/;
          if(value + '' === '' || ifNumber.test(value) === false){
            that.dateError = true;
            return false;
          }else{
            that.dateError = false;
            return true;
          }
        }
      },
      format: function(time, format) {
        let t = new Date(time);
        let tf = function(i) {
          return(i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
          switch(a) {
            case 'yyyy':
              return tf(t.getFullYear());
              break;
            case 'MM':
              return tf(t.getMonth() + 1);
              break;
            case 'mm':
              return tf(t.getMinutes());
              break;
            case 'dd':
              return tf(t.getDate());
              break;
            case 'HH':
              return tf(t.getHours());
              break;
            case 'ss':
              return tf(t.getSeconds());
              break;
          }
        })
      },
      initPostponeList : function(init, numPerPage){
        let that = this;
        let config = getConfig();
        if(numPerPage){
          that.pageSizePostpone = Number(numPerPage);

        }
        let data = {
          queryId : that.queryId,
          pageNum : that.pageNumPostpone,
          numPerPage : that.pageSizePostpone,
          phone : encodeURIComponent(that.searchInfoPostpone),
          company : encodeURIComponent(that.searchCompanyPostpone.trim())
        };
        // that.pageSizePostpone = pageSize || 10;
        if(init){
          data.pageNum = 1;
          that.pageNumPostpone = 1;
          that.currentPagePostpone = 0;
        }
        that.firstPageShowPostpone = false;
        that.prePageShowPostpone = false;
        that.nextPageShowPostpone = false;

        let url = config.index.applyFor.applyPostponeRoster;
        if(that.tab === 'com-risk'){
          url = config.index.applyFor.applyPostponeRosterBond;
        } else if(that.tab === 'com-invest') {
          data.platformNo = 1
        } else if(that.tab === 'com-bidding') {
          data.platformNo = 0
        }
          syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            let pageSize = that.pageSizePostpone;
            let data = d.data || {},
              data_list = data.data_list || [],
              l = data_list.length;

            let tableData = [];
            if(l > 0){
              for(let i=0;i<l;i++){
                let phone = data_list[i].phone || '',
                  name = data_list[i].name || '',
                  company = data_list[i].company || '',
                  position = data_list[i].position || '',
                  createTime = data_list[i].createTime || '',
                  insertTime = data_list[i].insertTime || '',
                  dateType = data_list[i].dateType || '',
                  applyId = data_list[i].applyId || '',
                  applyState = data_list[i].applyState === undefined ? '' : (data_list[i].applyState + '');

                tableData.push({
                  phone : phone,
                  name : name,
                  company : company,
                  position : position,
                  createTime : createTime,
                  insertTime : insertTime,
                  dateType : dateType,
                  applyId : applyId,
                  applyState : applyState
                })
              }
              that.showDataContainerPostpone = true;
              that.noDataPostpone = false;
            }
            that.postponeList = [].concat(tableData);

            if(init){
              if(l > 0){
                that.postponeListStore = [].concat(tableData);
                if(l === pageSize){
                  that.nextPageShowPostpone = true;
                  that.pageNumPostpone++;
                  that.currentPagePostpone = that.pageNumPostpone - 1;
                }
              }else{
                that.noDataPostpone = true;
                that.showDataContainerPostpone = false;
              }
            }else{
              if(l > 0){
                that.postponeListStore = that.postponeListStore.concat(tableData);
                that.firstPageShowPostpone = true;
                that.prePageShowPostpone = true;
                if(l === pageSize){
                  that.nextPageShowPostpone = true;
                  that.pageNumPostpone++;
                  that.currentPagePostpone = that.pageNumPostpone - 1;
                }else{
                  that.nextPageShowPostpone = false;
                  that.currentPagePostpone = that.pageNumPostpone;
                }
              }else{
                that.firstPageShowPostpone = true;
                that.prePageShowPostpone = true;
                that.nextPageShowPostpone = false;
                that.currentPagePostpone = that.pageNumPostpone - 1;
                that.postponeList = that.postponeListStore.slice(that.postponeListStore.length - that.pageSizePostpone,that.postponeListStore.length);
              }

            }
          }
        })
      },
      renderDataPostpone : function(data,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow){
        let that = this;

        that.postponeList = [].concat(data);
        that.pageNumPostpone = pageNum;
        that.currentPagePostpone = currentPage;
        that.firstPageShowPostpone = firstPageShow;
        that.prePageShowPostpone = prePageShow;
        that.nextPageShowPostpone = nextPageShow;
      },

      initCustomerList : function(init, numPerPage){
        let that = this;
        let config = getConfig();
        if(numPerPage){
          that.pageSize = Number(numPerPage);
        }
        let data = {
          queryId : that.queryId,
          pageNum : that.pageNum,
          numPerPage : that.pageSize,
          phone : encodeURIComponent(that.searchInfo),
          company : encodeURIComponent(that.searchCompany.trim())
        };
        // that.pageSize = pageSize || 10;
        if(init){
          data.pageNum = 1;
          that.pageNum = 1;
          that.currentPage = 0;
        }
        that.firstPageShow = false;
        that.prePageShow = false;
        that.nextPageShow = false;

        let url = config.index.applyFor.applyRoster;
        if(that.tab === 'com-risk'){
          url = config.index.applyFor.applyRosterBond;
        } else if(that.tab === 'com-invest') {
          data.platformNo = 1
        } else if(that.tab === 'com-bidding') {
          data.platformNo = 0
        }
        syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            let pageSize = that.pageSize;
            let data = d.data || {},
              data_list = data.data_list || [],
              l = data_list.length;

            let tableData = [];
            if(l > 0){
              for(let i=0;i<l;i++){
                let phone = data_list[i].phone || '',
                  name = data_list[i].name || '',
                  company = data_list[i].company || '',
                  position = data_list[i].position || '',
                  createTime = data_list[i].createTime || '',
                  insertTime = data_list[i].insertTime || '',
                  token = data_list[i].token || '',
                  dateType = data_list[i].dateType || '',
                  abroadMarket = data_list[i].abroadMarket === undefined ? '0' : data_list[i].abroadMarket,
                  card = data_list[i].card || '',
                  applyId = data_list[i].applyId || '',
                  applyState = data_list[i].applyState === undefined ? '' : (data_list[i].applyState + ''),
                  cancel = data_list[i].cancel === undefined ? '' : (data_list[i].cancel + ''),
                  childPlatform = data_list[i].childPlatform === undefined ? '' : data_list[i].childPlatform,
                  userType = data_list[i].userType === undefined ? '' : data_list[i].userType;

                tableData.push({
                  phone : phone,
                  name : name,
                  company : company,
                  position : position,
                  createTime : createTime,
                  insertTime : insertTime,
                  token : token,
                  dateType : dateType,
                  abroadMarket : abroadMarket,
                  applyId : applyId,
                  applyState : applyState,
                  cancel : cancel,
                  card : card,
                  childPlatform: childPlatform,
                  userType: userType
                })
              }
              that.showDataContainer = true;
              that.noData = false;
            }
            that.customerList = [].concat(tableData);

            if(init){
              if(l > 0){
                that.customerListStore = [].concat(tableData);
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }
              }else{
                that.noData = true;
                that.showDataContainer = false;
              }
            }else{
              if(l > 0){
                that.customerListStore = that.customerListStore.concat(tableData);
                that.firstPageShow = true;
                that.prePageShow = true;
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }else{
                  that.nextPageShow = false;
                  that.currentPage = that.pageNum;
                }
              }else{
                that.firstPageShow = true;
                that.prePageShow = true;
                that.nextPageShow = false;
                that.currentPage = that.pageNum - 1;
                that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize,that.customerListStore.length);
              }
            }
          }
        })
      },
      renderData : function(data,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow){
        let that = this;

        that.customerList = [].concat(data);
        that.pageNum = pageNum;
        that.currentPage = currentPage;
        that.firstPageShow = firstPageShow;
        that.prePageShow = prePageShow;
        that.nextPageShow = nextPageShow;
      },
      addAccount : function(){
        let that = this;
        let addAccountData = this.addAccountData;
        for(let i in addAccountData){
          if(Object.hasOwnProperty.call(addAccountData, i)){
            addAccountData[i] = '';
          }
        }
        if(that.tab === 'com-invest') {
          that.applyForValue = 1
        } else if(that.tab === 'com-bidding') {
          that.applyForValue = 0
        }
        addAccountData.abroadMarket = '0'
        addAccountData.userType = '0'
        that.phoneError = false;
        that.nameError = false;
        that.companyError = false;
        that.positionError = false;
        that.dateError = false;
        that.addAccountDateType = '日';
        that.imageUrl = '';
        that.addAccountDialogVisible = true;
      },
      submitAddAccount : function(accountData){
        let that = this;
        let config = getConfig();

        if(that.submitAddClicked === false){
          if(that.validatePhone(that.addAccountData.phone,'phone')&&
            that.validatePhone(that.addAccountData.name,'name')&&
            that.validatePhone(that.addAccountData.company,'company')&&
            that.validatePhone(that.addAccountData.position,'position')&&
            that.validatePhone(that.addAccountData.dateDate,'dateDate')
          ){
            that.submitAddClicked = true;

            let data = {
              phone :  encodeURIComponent(accountData.phone.replace(/^\s*|\s*$/g,'')),
              name : encodeURIComponent(accountData.name.trim()),
              company : encodeURIComponent(accountData.company.trim()),
              position : encodeURIComponent(accountData.position.trim()),
              abroadMarket : accountData.abroadMarket,
              dateType : encodeURIComponent((accountData.dateDate + '').trim() + that.addAccountDateType),
              card : accountData.card
            };
            let url = config.index.applyFor.addApplyCustomer;
            if(that.tab === 'com-risk'){
              url = config.index.applyFor.addApplyCustomerBond;
            } else if(that.tab === 'com-invest') {
              data.platformNo = 1;
              data.childPlatform = that.applyForValue;
            } else if(that.tab === 'com-bidding') {
              data.platformNo = 0;
              data.childPlatform = that.applyForValue;
              data.userType = accountData.userType;
            }
            syAxios({
              ifProxy : true,
              url : url,
              data : data,
              success : function(d){
                if(d.data.return_msg){
                  alert(d.data.return_msg);
                }else{
                  that.customerList = [];
                  that.customerListStore = [];
                  that.initCustomerList(true);
                  that.addAccountDialogVisible = false;
                }
                that.submitAddClicked = false
              },
              error : function(){
                that.submitAddClicked = false
              }
            })
          }
        }
      },
      ifCancel : function(data){
        let that = this;
        that.currentCancelId = data.applyId;
        that.currentCancelPhone = data.phone;
        that.currentCancelApplyState = data.applyState;
        that.isCancel = data.cancel + '' === '0' ? '1' : '0';
        that.isCancel === '1' ? that.cancelTipText = '确定取消申请吗？' : that.cancelTipText = '确定重新提交吗？';
        that.isCancel === '1' ? that.ifResubmit = false : that.ifResubmit = true;
        that.cancelApplyDialogVisible = true;
      },
      ifSubmit(data) {
        let that = this;
        that.currentCancelId = data.applyId;
        that.currentCancelPhone = data.phone;
        that.currentCancelApplyState = data.applyState;
        that.isCancel = '0';
        that.cancelTipText = '确定重新提交吗？';
        that.ifResubmit = true;
        that.cancelApplyDialogVisible = true;
      },
      editInfo : function(data){
        let that = this;

        that.phoneError = false;
        that.nameError = false;
        that.companyError = false;
        that.positionError = false;
        that.dateError = false;

        that.formData.phone = data.phone;
        that.formData.name = data.name;
        that.formData.company = data.company;
        that.formData.position = data.position;
        that.formData.dateDate = parseInt(data.dateType) || '';
        that.formData.abroadMarket = data.abroadMarket + '' || '0';

        if(that.tab === 'com-invest') {
          that.formData.childPlatform = data.childPlatform || 1;
        } else if(that.tab === 'com-bidding') {
          that.formData.childPlatform = data.childPlatform || 0;
          that.formData.userType = data.userType + '' || '0';
        }

        that.formData.dateType = data.dateType.substr(data.dateType.length -1 ,1) || '日';
        that.editDataDateType = data.dateType.substr(data.dateType.length -1 ,1) || '日';
        that.formData.applyId = data.applyId;

        that.editAccountDialogVisible = true;
      },
      editCard : function(data){
        let that = this;
        let config = getConfig();

        that.currentEditId = data.applyId;
        that.currentEditCard = data.card;
        if(data.card === ''){
          that.editCardImgUrl = ''
        }else{
          that.editCardImgUrl = config.imgUrlHeader + data.card || '';
        }
        that.editCardDialogVisible = true;
        this.rotateCount = 0;
        this.rotateStyle = "transform:rotate(0deg);width:400px;height:300px;position:absolute;left:0;top:0;"
      },
      submitEditInfo : function(currentData){
        let that = this;
        let config = getConfig();
        if(
					that.validatePhone(that.formData.phone,'emptyPhone')&&
          that.validatePhone(that.formData.name,'name')&&
          that.validatePhone(that.formData.company,'company')&&
          that.validatePhone(that.formData.position,'position')&&
          that.validatePhone(that.formData.dateDate,'dateDate')
        ){
          let data = {
            applyId : currentData.applyId,
            phone : currentData.phone.replace(/^\s*|\s*$/g,''),
            name : encodeURIComponent(currentData.name.trim()),
            company : encodeURIComponent(currentData.company.trim()),
            position : encodeURIComponent(currentData.position.trim()),
            abroadMarket : currentData.abroadMarket,
            dateType : encodeURIComponent((currentData.dateDate + '').trim() + that.editDataDateType)
          };
          let url = config.index.applyFor.updateApplyCustomer;
          if(that.tab === 'com-risk'){
            url = config.index.applyFor.updateApplyCustomerBond;
          } else if(that.tab === 'com-invest') {
            data.platformNo = 1;
            data.childPlatform = that.formData.childPlatform;
          } else if(that.tab === 'com-bidding') {
            data.platformNo = 0;
            data.childPlatform = that.formData.childPlatform;
            data.userType = that.formData.userType;
          }
            syAxios({
            ifProxy : true,
            url : url,
            data : data,
            success : function(){
              let customerList = that.customerList;
              for(let i in customerList){
                if(customerList[i].applyId + '' === currentData.applyId){
                  customerList[i].phone = currentData.phone;
                  customerList[i].name = currentData.name;
                  customerList[i].company = currentData.company;
                  customerList[i].position = currentData.position;
                  customerList[i].dateType = currentData.dateDate + that.editDataDateType;
                  customerList[i].abroadMarket = currentData.abroadMarket;
                  customerList[i].childPlatform = currentData.childPlatform;
                }
              }
              that.editAccountDialogVisible = false;
              that.initCustomerList(true);
            },
            error : function(d){
              console.log(d)
            }
          })
          that.editAccountDialogVisible = false;
        }
      },
      submitEditCard : function(){
        let that = this;
        let config = getConfig();

        let data = {
          applyId : that.currentEditId,
          card : that.currentEditCard
        };
        let url = config.index.applyFor.updateApplyCard;
        if(that.tab === 'com-risk'){
          url = config.index.applyFor.updateApplyCardBond;
        }
        syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            let customerList = that.customerList;
            for(let i in customerList){
              if(customerList[i].applyId + '' === data.applyId){
                customerList[i].card = data.card;
              }
            }
            that.editCardImgUrl = config.imgUrlHeader + data.card || '';
          },
          error : function(d){

          }
        })
        that.currentEditId = '';
        that.currentEditCard = '';
        that.editCardDialogVisible = false;
      },
      submitCancelApply : function(ifReSubmit){
        let that = this;
        let config = getConfig();
        let data = {
          applyId : that.currentCancelId,
          cancel : that.isCancel,
          applyState: that.currentCancelApplyState
        };
        let url = config.index.applyFor.cancelApply;
        if(that.tab === 'com-risk'){
          url = config.index.applyFor.cancelApplyBond;
        } else if(that.tab === 'com-invest') {
          data.platformNo = 1;
        } else if(that.tab === 'com-bidding') {
          data.platformNo = 0;
          data.phone = that.currentCancelPhone;
        }

        clearTimeout(that.timer)

        syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            if (d.data.return_msg) {
              alert(d.data.return_msg);
            } else {
              let customerList = that.customerList;
              for (let i in customerList) {
                if (customerList[i].applyId + '' === data.applyId) {
                  if (that.isCancel + '' === '0') {
                    customerList[i].cancel = '0';
                  } else {
                    customerList[i].cancel = '1';
                  }
                  if (that.ifResubmit) {
                    customerList[i].createTime = that.format(new Date(), 'yyyy-MM-dd HH:mm');
                    customerList[i].applyState = '0';
                    that.ifResubmit = false;
                  }
                }
              }
              that.cancelSuccessDialogVisible = true;
              that.timer = setTimeout(function () {
                that.cancelSuccessDialogVisible = false;
              }, 3000);
            }
            that.currentCancelId = '';
            that.isCancel = '';
            that.currentCancelPhone = '';
            that.currentCancelApplyState = '';
          },
          error : function(d){
            that.cancelFailDialogVisible = true;
            that.timer = setTimeout(function(){
              that.cancelFailDialogVisible = false;
            },3000);
            that.currentCancelId = '';
            that.isCancel = '';
            that.currentCancelPhone = '';
            that.currentCancelApplyState = '';
          }
        })
        that.cancelApplyDialogVisible = false;
      },
      handleAvatarSuccess(res, file) {
        let that = this;
        let data = res.data || {};
        let card = data.card;
        if(typeof card === 'string'){
          that.addAccountData.card = card;
          that.imageUrl = URL.createObjectURL(file.raw);
        }
      },
      handleEditCardUploadSuccess(res, file){
        let that = this;
        let data = res.data || {};
        let card = data.card;
        if(typeof card === 'string'){
          that.currentEditCard = card;
          that.editCardImgUrl = URL.createObjectURL(file.raw);
        }
      },
      handleUploadFileSuccess(res, file){
        let that = this;
        if(res.data.success){
          alert('上传成功！');
          that.initCustomerList(true);
        }
        if(res.data.return_msg){
          alert(res.data.return_msg)
        }
      },
      beforeUploadFileUpload(file){
        this.formUploadParams.filename = file.name;
      },
      beforeEditCardUpload(file){
        this.imgUploadCondition(file);
      },
      beforeAddCardUpload(file) {
        this.imgUploadCondition(file);
      },
      imgUploadCondition : function(file){
        let fileData = file;

        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isJPG) {
          alert('上传头像图片只能是 JPEG、PNG、GIF格式!');
        }
        if (!isLt2M) {
          alert('上传头像图片大小不能超过 10MB!');
        }
        return isJPG && isLt2M;
      },

      rotateDeg : function(){
        //为什么旋转90和270会偏移50px？？？？
        let that = this;
        that.rotateCount++;
        if(that.rotateCount % 4 === 0){
          that.rotateStyle = "transform:rotate(0deg);-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);width:400px;height:300px;position:absolute;left:0;top:0;"
        }else if(that.rotateCount % 4 === 1){
          that.rotateStyle = "transform:rotate(90deg);-webkit-transform:rotate(90deg);-moz-transform:rotate(90deg);-ms-transform:rotate(90deg);-o-transform:rotate(90deg);width:300px;height:400px;position:absolute;left:50px;top:-50px;"
        }else if(that.rotateCount % 4 === 2){
          that.rotateStyle = "transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);width:400px;height:300px;position:absolute;left:0;top:0;"
        }else if(that.rotateCount % 4 === 3){
          that.rotateStyle = "transform:rotate(270deg);-webkit-transform:rotate(270deg);-moz-transform:rotate(270deg);-ms-transform:rotate(270deg);-o-transform:rotate(270deg);width:300px;height:400px;position:absolute;left:50px;top:-50px;"
        }
      },
      downloadFile() {
        let that = this;
        let url = window.EXCEL_SY_TEMPLATE || '';
        let fileName = '股权投融资视野.xlsx';
        if(that.tab === 'com-risk'){
          url = window.EXCEL_YQ_TEMPLATE || '';
          fileName = '股权风险视野.xlsx';
        } else if(that.tab === 'com-invest'){
          url = window.EXCEL_SY_TEMPLATE || '';
          fileName = '股权投融资视野.xlsx';
        } else if(that.tab === 'com-bidding'){
          url = window.EXCEL_BIDDING_TEMPLATE || '';
          fileName = '智能招采.xlsx';
        }
        axios({
          method : 'get',
          url : url,
          responseType: 'blob',
          data: {},
        }).then(function(d){
          Utils.saveBlobFile(d.data, fileName);
        })
      }
    }
  }
</script>
