<template>
	<section class="apply-for">
		<permit-header v-on:getCustomerList="initCustomerList(true)" v-on:getQueryId="initQueryId"></permit-header>
    <com-tabs :tabs="tabsData" @tabChange="tabChange"></com-tabs>
    <keep-alive>
      <component :is="curTab" :tab="curTab" :queryId="queryId"></component>
    </keep-alive>
	</section>
</template>

<script>
	import PermitUserHeader from '../../components/permitUserHeader'
  import ComTabs from "../../components/tabs.component";
  import ComInvest from "./block.component";

	export default{
		data(){
			return{
        curTab: 'com-invest',
        tabsData: [
          {text: '产融平台', com: 'com-invest', active : true},
          {text: '债权平台', com: 'com-risk', active : false},
        ],
				queryId : '',
			}
		},
		components : {
			'permit-header' : PermitUserHeader,
      ComTabs,
      ComInvest,
      ComRisk: {extends: ComInvest}
		},
		methods : {
      tabChange (index){
        let data = this.tabsData || [];
        for (let i = 0; i < data.length; i++) {
          data[i].active = false;
        }
        data[index].active = true;
        this.curTab = data[index].com || '';
      },
			initQueryId : function(queryId){
				this.queryId = queryId;
			},
		}
	}
</script>
