<style scoped lang="less">
.apply-for-header > form {
  padding: 20px 10px 0 10px;
}

.content-header {
  padding: 10px;
  border-bottom: 1px solid #14B8D4;
}

.content-header > div {
  display: inline-block;
  line-height: 30px;
}

select {
  height: 26px;
  border: 1px solid #ccc;
  outline: none;
  margin: 0 5px;
}

.send-message {
  display: inline-block;
  font-size: 14px;
  margin-left: 20px;
}

.send-message label {
  margin: 0 10px;
}

.send-img .send {
  cursor: pointer;
}

.send-img img {
  width: 24px;
  margin-top: 6px;
}

input::-ms-clear {
  display: none;
}

.form-select {
  width: 100px;
  height: 32px;
  margin-right: 10px;

  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}

.btn-excel {
  > span {
    margin-right: 10px;
    cursor: pointer;

    img {
      vertical-align: middle;
    }
  }
}

.btn {
  margin-top: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .info {
    color: #999;
    background-color: #fff6f6;
    padding: 13px 20px;
    font-size: 12px;
  }

  .btn-right {
    flex: 1;
    text-align: right;
  }

  .el-button {
    height: 32px;
    background: #fff;
    border: 1px solid #14B8D4;
    border-radius: 6px;
    color: #14B8D4;
    font-weight: 600;
    padding: 0px 15px;
  }

  .btn-submit {
    background: #14B8D4;
    color: #fff;
  }
}

.success {
  color: #14B8D4;
}

.error {
  color: red;
}

.disabled {
  color: #c9c9c9;
}

.form-container {
  padding: 20px 20px 0 20px;
}

.form-group {
  margin-bottom: 20px;
  color: #333;
}

.form-group span {
  display: inline-block;
  text-align: right;
  width: 120px;
  margin: 0 4px;
}

.form-group span.ifAbroadMarket {
  width: 164px;
}

.form-group span.validate-tip {
  width: 100%;
  padding: 10px 140px 0;
  text-align: left;
  display: block;
  color: #f00;
}

.form-group mark {
  background: #fff;
  color: #f00;
  margin: 4px;
  font-size: 16px;
}

.form-group > input {
  width: 320px;
  height: 32px;
  padding-left: 20px;
}

.form-group select {
  width: 100px;
  height: 32px;
  color: #333;
}

.search-input {
  display: inline-block;
  width: 320px;
  height: 32px;
}

.form-input {
  display: inline-block;
  width: 320px;
  height: 32px;

  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}

.date-deadline {
  width: 120px;
  margin-right: 20px;
}

.form-select {
  width: 100px;

  /deep/ .el-input {
    width: 100%;
    height: 100%;
  }
}

.search-info {
  width: 200px;
  height: 32px;
  margin-left: 10px;
}
.total-wrapper {
  display: inline-block;
  color: #999;
  white-space: nowrap;
  vertical-align: middle;
  margin: 10px 0 0 10px;
  .total {
    margin: 0 5px;
    font-size: 20px;
    color: #14B8D4;
  }
}
</style>

<template>
  <div>
    <section>
      <div class="btn">
        <div class="info">注：审核状态为“手机号已存在”的用户，需删除该条数据后方能提交后台。</div>
        <div class="total-wrapper">
          <span>共</span>
          <span class="total">{{ tableList.length }}</span>
          <span>条</span>
        </div>
        <div class="btn-right">
          <el-button class="btn-submit" @click="handleSubmitAll">提交</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </div>
      <div class="query-result" v-show="showDataContainer">
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th>手机号</th>
              <th>姓名</th>
              <th>公司名称</th>
              <th>职位</th>
              <th>试用期限</th>
              <th>审核状态</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody v-loading="submitAddClicked">
            <tr v-for="(item,index) in tableList" :key="index" :class="index % 2 === 0 ? '' : 'tr-even'">
              <td>
                <div :class="{
                  'error': item.phoneEmptyError || item.phoneError || item.phoneRepeatError
                }">{{ getPhone(item.phone, item.phoneEmptyError) }}
                </div>
              </td>
              <td>
                <div :class="{
                  'error': item.nameEmptyError
                }">{{ item.name }}
                </div>
              </td>
              <td class="text-left">
                <div :class="{
                'error': item.companyEmptyError || item.companyError
                }">{{ item.company }}
                </div>
              </td>
              <td>
                <div :class="{
                'error': item.positionEmptyError
                }">{{ item.position }}
                </div>
              </td>
              <td>
                <div :class="{
                'error': item.dateError
                }">{{ item.date }}
                </div>
              </td>
              <td class="text-left">
                <span v-show="item.phoneEmptyError" class="error">手机号有误;</span>
                <span v-show="item.phoneRepeatError" class="error">手机号重复;</span>
                <span v-show="item.nameEmptyError" class="error">姓名为空;</span>
                <span v-show="item.companyEmptyError" class="error">公司名称为空;</span>
                <span v-show="item.positionEmptyError" class="error">职位为空;</span>
                <span v-show="item.dateError" class="error">
                  试用期限有误
                  <span>({{getDateError(item.date)}});</span>
                </span>
                <span v-show="item.state" class="error">{{ item.state }}</span>
                <span v-show="item.done || item.doneAll" class="success">已通过</span>
              </td>
              <td>
                <div class="btn-excel" :class="{
                  'disabled': item.done || item.doneAll
                }">
                  <span @click="handleDel(item, index)">
                    <img v-if="item.done || item.doneAll" src="../../../assets/del_disbled.png"/>
                    <img v-else src="../../../assets/del.png"/>
                    删除
                  </span>
                  <span @click="handleEdit(item, index)">
                    <img v-if="item.done || item.doneAll" src="../../../assets/edit_disbled.png"/>
                    <img v-else src="../../../assets/edit.png"/>
                    修改
                  </span>
                  <span @click="handleSubmit(item)">
                    <img v-if="item.done || item.doneAll" src="../../../assets/submit_disbled.png"/>
                    <img v-else src="../../../assets/submit.png"/>
                    提交
                  </span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="no-data" v-show='!showDataContainer'>
        <img src="../../../assets/noData.png"/>
        <span>暂无数据</span>
      </div>
    </section>
    <div class="dialog-container">
      <!--修改信息弹窗-->
      <el-dialog title="修改信息" :visible.sync="dialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>手机号:</span>
              <el-input class="form-input" type="text" v-on:blur="validateEditPhone(addAccountData.phone,'phone')"
                        v-model="addAccountData.phone"/>
              <span class="validate-tip" v-show="phoneError">请输入正确的手机号码！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>姓名:</span>
              <el-input class="form-input" type="text" v-on:blur="validateEditPhone(addAccountData.name,'name')"
                        v-model="addAccountData.name"/>
              <span class="validate-tip" v-show="nameError">请输入姓名！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>公司名称:</span>
              <!--              <input type="text" v-on:blur="validateEditPhone(addAccountData.company,'company')" v-model="addAccountData.company" />-->
              <company-search
                  class="search-input"
                  @search="getCompanyList"
                  @scrollLoad="getCompanyList"
                  @blur="companyBlur"
                  @itemClick="companyClick"
                  :list="list"
                  :total="total"
                  :companyName="addAccountData.company"
              ></company-search>
              <span class="validate-tip" v-show="companyError">请输入公司名称！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>职位:</span>
              <el-input class="form-input" type="text" v-on:blur="validateEditPhone(addAccountData.position,'position')"
                        v-model="addAccountData.position"/>
              <span class="validate-tip" v-show="positionError">请输入职位！</span>
            </div>
            <div class="form-group">
              <span><mark>*</mark>试用期限:</span>
              <el-input class="form-input date-deadline" v-on:blur="validateEditPhone(addAccountData.date,'date')"
                        type="text" v-model="addAccountData.date"/>
              <el-select class="form-input form-select" v-model="addAccountData.type">
                <el-option :value="item" v-for="(item,keys) in dateType" :key="keys">{{ item }}</el-option>
              </el-select>
              <span class="validate-tip" v-show="dateError">请输入试用期限(最多两位数字格式)！</span>
              <span class="validate-tip" v-show="typeError">请选择试用期限范围！</span>
            </div>
          </form>
        </div>
        <div slot="footer" class="dialog-footer btn">
          <el-button class="btn-submit" @click="submitAddAccount">提交</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import syAxios from '../../../../public/static/common/axios.js'
import getConfig from '../../../../public/static/common/config.js'
import CompanySearch from '../../applyFor/components/companySearch.component';

export default {
  data() {
    return {
      dialogVisible: false,
      tableList: [],
      addAccountData: {
        id: '',
        phone: '',
        name: '',
        company: '',
        position: '',
        date: '',
        type: '',
        dateType: '日',
      },
      list: [],
      total: 0,
      phoneError: false,
      nameError: false,
      companyError: false,
      positionError: false,
      dateError: false,
      typeError: false,
      showDataContainer: false,
      pageSize: 10,
      submitAddClicked: false,
      editIndex: '',
      localStorageName: '',
      submitAllSuccess: false,
      dateType: ['日', '周', '月', '年']
    }
  },
  components: {
    CompanySearch
  },
  mounted() {
    this.localStorageName = 'checkTableData' + this.$route.query.date || '';
    this.initTableList(true);
  },
  methods: {
    getPhone(phone, error) {
      if (phone) {
        return error ? phone : (phone + '').replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
      } else {
        return '';
      }
    },
    getDateError(date){
      let type = (date + '').replace(/^\d+/, '');
      let isDateType = this.dateType.some(item => type === item);
      let error = '';
      if (/^\d{1,2}$/.test(parseInt(date)) === false) {
        error = '最多两位数字格式'
      }
      if (!isDateType) {
        error += error === '' ? '单位有误' : '、单位有误'
      }
      return error;
    },
    handleDel(item, index) {
      if (item.done || item.doneAll) {
        return true;
      }
      this.tableList.splice(index, 1);
      let checkTableData = JSON.parse(window.localStorage.getItem(this.localStorageName)) || [];
      checkTableData.splice(checkTableData.findIndex(check => check.id === item.id), 1)
      window.localStorage.setItem(this.localStorageName, JSON.stringify(checkTableData))
    },
    handleEdit(item, index) {
      if (item.done || item.doneAll) {
        return true;
      }
      this.phoneError = false;
      this.nameError = false;
      this.companyError = false;
      this.positionError = false;
      this.dateError = false;
      this.typeError = false;
      this.addAccountData.id = item.id;
      this.addAccountData.phone = item.phone || '';
      this.addAccountData.name = item.name || '';
      this.addAccountData.company = item.company || '';
      this.addAccountData.position = item.position || '';
      this.addAccountData.date = parseInt(item.date) || '';
      this.addAccountData.type = (item.date + '').replace(/^\d+/, '');
      let isDateType = this.dateType.some(item => this.addAccountData.type === item);
      if (!isDateType) {
        this.addAccountData.type = '';
      }
      this.editIndex = index;
      this.dialogVisible = true;
    },
    handleSubmit(item) {
      if (item.done || item.doneAll) {
        return true;
      }
      let that = this;
      if (that.submitAddClicked === false) {
        item.phoneEmptyError = !this.validatePhone(item.phone, 'phone');
        item.phoneRepeatError = !this.validatePhone(item.phone, 'phoneRepeat');
        item.nameEmptyError = !this.validatePhone(item.name, 'name');
        item.companyEmptyError = !this.validatePhone(item.company, 'company');
        item.positionEmptyError = !this.validatePhone(item.position, 'position');
        item.dateError = !this.validatePhone(item.date, 'date');

        if (!item.phoneEmptyError &&
            !item.phoneRepeatError &&
            !item.nameEmptyError &&
            !item.companyEmptyError &&
            !item.positionEmptyError &&
            !item.dateError
        ) {
          that.submitAddClicked = true;

          let data = [{
            id: item.id,
            phone: (item.phone + '').replace(/^\s*|\s*$/g, ''),
            name: encodeURIComponent(item.name.trim()),
            company: encodeURIComponent(item.company.trim()),
            position: encodeURIComponent(item.position),
            dateType: encodeURIComponent(item.date.trim()),
            state: ''
          }];
          syAxios({
            ifProxy: true,
            data: {
              jsonObject: JSON.stringify(data),
              tab: 2
            },
            success: function (d) {
              if (d.data.return_msg) {
                if (typeof d.data.return_msg === 'string') {
                  alert(d.data.return_msg);
                } else {
                  d.data.return_msg.forEach(msg => {
                    if (msg.id === item.id + '') {
                      item.state = msg.state;
                      item.phoneError = msg.state.includes('手机号已存在');
                      item.companyError = msg.state.includes('公司全称有误');
                    }
                  })
                }
              } else {
                item.done = true;
                item.state = '';
                item.phoneError = false;
                item.companyError = false;

                let checkTableData = JSON.parse(window.localStorage.getItem(that.localStorageName));
                let checkIndex = checkTableData.findIndex(check => check.id === item.id);
                checkTableData[checkIndex] = item;
                window.localStorage.setItem(that.localStorageName, JSON.stringify(checkTableData));

                that.submitAllSuccess = that.tableList.findIndex(item => item.done === false) === -1;
                alert('提交成功');
              }
              that.submitAddClicked = false
            },
            error: function () {
              that.submitAddClicked = false
            },
            always: function(){
              that.submitAddClicked = false
            }
          })
        }
      }
    },
    handleSubmitAll() {
      let that = this;
      if (that.submitAddClicked === false) {
        if (that.submitAllSuccess === false) {
          let data = [];
          this.tableList.forEach(item => {
            if (!item.done) {
              item.phoneEmptyError = !this.validatePhone(item.phone, 'phone');
              item.phoneRepeatError = !this.validatePhone(item.phone, 'phoneRepeat');
              item.nameEmptyError = !this.validatePhone(item.name, 'name');
              item.companyEmptyError = !this.validatePhone(item.company, 'company');
              item.positionEmptyError = !this.validatePhone(item.position, 'position');
              item.dateError = !this.validatePhone(item.date, 'date');
              if (!item.phoneEmptyError &&
                  !item.phoneRepeatError &&
                  !item.nameEmptyError &&
                  !item.companyEmptyError &&
                  !item.positionEmptyError &&
                  !item.dateError) {
                data.push({
                  id: item.id,
                  phone: (item.phone + '').replace(/^\s*|\s*$/g, ''),
                  name: encodeURIComponent(item.name.trim()),
                  company: encodeURIComponent(item.company.trim()),
                  position: encodeURIComponent(item.position),
                  dateType: encodeURIComponent(item.date.trim()),
                  state: ''
                })
              }
            }
          });
          let doneData = this.tableList.filter(item => !item.done) || [];
          if (data.length > 0 && doneData.length === data.length) {
            that.submitAddClicked = true;
            syAxios({
              ifProxy: true,
              data: {
                jsonObject: JSON.stringify(data),
                tab: 2
              },
              success: function (d) {
                if (d.data.return_msg) {
                  if (typeof d.data.return_msg === 'string') {
                    alert(d.data.return_msg);
                  } else {
                    that.tableList.forEach(item => {
                      if (!item.done &&
                          !item.phoneEmptyError &&
                          !item.phoneRepeatError &&
                          !item.nameEmptyError &&
                          !item.companyEmptyError &&
                          !item.positionEmptyError &&
                          !item.dateError) {
                        let msg = d.data.return_msg.find(msg => msg.id === item.id + '') || {};
                        if (Object.keys(msg).length > 0) {
                          item.doneAll = false;
                          item.state = msg.state;
                          item.phoneError = msg.state.includes('手机号已存在');
                          item.companyError = msg.state.includes('公司全称有误');
                        } else {
                          item.doneAll = true;
                          item.state = '';
                          item.phoneError = false;
                          item.companyError = false;
                        }
                      }
                    })
                  }

                } else {
                  that.tableList.forEach(item => {
                    if (!item.done &&
                        !item.phoneEmptyError &&
                        !item.phoneRepeatError &&
                        !item.nameEmptyError &&
                        !item.companyEmptyError &&
                        !item.positionEmptyError &&
                        !item.userTypeEmptyError &&
                        !item.dateError
                    ) {
                      item.done = true;
                      item.state = '';
                      item.phoneError = false;
                      item.companyError = false;
                    }
                  })
                  window.localStorage.setItem(that.localStorageName, JSON.stringify(that.tableList));
                  that.submitAllSuccess = true;
                  alert('提交成功');
                  that.handleCancel();
                }
                that.submitAddClicked = false
              },
              error: function () {
                that.submitAddClicked = false
              },
              always: function () {
                that.submitAddClicked = false
              }
            })
          }
        } else {
          this.handleCancel();
        }
      }
    },
    handleCancel() {
      window.localStorage.removeItem(this.localStorageName);
      this.$router.push({
        path: '/index',
        query: {
          type: this.$route.query.type || ''
        }
      });
    },
    initTableList() {
      let that = this;
      let tableData = window.localStorage.getItem(that.localStorageName);
      let data = tableData ? JSON.parse(tableData) : [];
      that.tableList = data.sort((a, b) => {
        if (that.validatePhone(b.phone, 'phone') &&
            that.validatePhone(b.phone, 'phoneRepeat') &&
            that.validatePhone(b.name, 'name') &&
            that.validatePhone(b.company, 'company') &&
            that.validatePhone(b.position, 'position') &&
            that.validatePhone(b.date, 'date')) {
          return -1
        } else {
          return 1
        }
      })
      that.tableList.forEach((item, index) => {
        that.$set(that.tableList[index], 'done', item.done || false);
        that.$set(that.tableList[index], 'doneAll', false);
        that.$set(that.tableList[index], 'state', item.state || '');
        that.$set(that.tableList[index], 'phoneError', false);
        that.$set(that.tableList[index], 'companyError', false);
        that.$set(that.tableList[index], 'phoneEmptyError', !that.validatePhone(item.phone, 'phone'));
        that.$set(that.tableList[index], 'phoneRepeatError', !that.validatePhone(item.phone, 'phoneRepeat'));
        that.$set(that.tableList[index], 'nameEmptyError', !that.validatePhone(item.name, 'name'));
        that.$set(that.tableList[index], 'companyEmptyError', !that.validatePhone(item.company, 'company'));
        that.$set(that.tableList[index], 'positionEmptyError', !that.validatePhone(item.position, 'position'));
        that.$set(that.tableList[index], 'dateError', !that.validatePhone(item.date, 'date'));
      });
      that.showDataContainer = that.tableList.length > 0;
      that.submitAllSuccess = that.tableList.findIndex(item => item.done === false) === -1;
    },
    validatePhone(value, type) {
      if (type + '' === 'phone') {
        let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (!reg.test(Number(value))) {
          return false;
        } else {
          return true;
        }
      } else if (type + '' === 'phoneRepeat') {
        if (value + '' !== '') {
          return this.tableList.filter(item => item.phone == value).length <= 1;
        } else {
          return true;
        }
      } else if (type + '' === 'name') {
        if (value + '' === '') {
          return false;
        } else {
          return true;
        }
      } else if (type + '' === 'company') {
        if (value + '' === '') {
          return false;
        } else {
          return true;
        }
      } else if (type + '' === 'position') {
        if (value + '' === '') {
          return false;
        } else {
          return true;
        }
      } else if (type + '' === 'date') {
        let ifNumber = /^\d{1,2}[\u4e00-\u9fa5]{1}$/;
        let isDateType = this.dateType.some(item => (value + '').includes(item));
        if (value + '' === '' || ifNumber.test(value) === false || !isDateType) {
          return false;
        } else {
          return true;
        }
      }
    },
    validateEditPhone(value, type) {
      let that = this;
      if (type + '' === 'phone') {
        let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (!reg.test(Number(value))) {
          that.phoneError = true;
          return false;
        } else {
          that.phoneError = false;
          return true;
        }
      } else if (type + '' === 'name') {
        if (value + '' === '') {
          that.nameError = true;
          return false;
        } else {
          that.nameError = false;
          return true;
        }
      } else if (type + '' === 'company') {
        if (value + '' === '') {
          that.companyError = true;
          return false;
        } else {
          that.companyError = false;
          return true;
        }
      } else if (type + '' === 'position') {
        if (value + '' === '') {
          that.positionError = true;
          return false;
        } else {
          that.positionError = false;
          return true;
        }
      } else if (type + '' === 'date') {
        let ifNumber = /^\d{1,2}$/;
        if (value + '' === '' || ifNumber.test(value) === false) {
          that.dateError = true;
          return false;
        } else {
          that.dateError = false;
          return true;
        }
      } else if (type + '' === 'type') {
        if (value + '' === '') {
          that.typeError = true;
          return false;
        } else {
          that.typeError = false;
          return true;
        }
      }
    },
    getCompanyList(pageNum, value) {
      let that = this;
      let config = getConfig();
      let data = {
        searchContent: encodeURIComponent(value),
        type: 100,
        contain: 'ACompany,ThirdCompany,FourthCompany,OthersCompany',
        pageNum: pageNum,
        pageSize: that.pageSize
      }

      syAxios({
        ifProxy: true,
        url: config.index.applyFor.searcheTip,
        data: data,
        success: function (d) {
          let data = d.data || {};
          that.list = data.dataList || [];
          if (that.list.length > 10) {
            that.list = that.list.slice(0, 10);
          }
          that.total = data.total || 0;
        }
      })
    },
    companyBlur(value) {
      this.addAccountData.company = value || '';
      this.validatePhone(this.addAccountData.company, 'company');
    },
    companyClick(item) {
      this.addAccountData.company = item.cname || '';
      this.validatePhone(this.addAccountData.company, 'company')
    },
    submitAddAccount() {
      let that = this;
      if (that.submitAddClicked === false) {
        if (that.validateEditPhone(that.addAccountData.phone, 'phone') &&
            that.validateEditPhone(that.addAccountData.name, 'name') &&
            that.validateEditPhone(that.addAccountData.company, 'company') &&
            that.validateEditPhone(that.addAccountData.position, 'position') &&
            that.validateEditPhone(that.addAccountData.date, 'date') &&
            that.validateEditPhone(that.addAccountData.type, 'type')
        ) {
          that.tableList[that.editIndex].phone = (that.addAccountData.phone + '').replace(/^\s*|\s*$/g, '');
          that.tableList[that.editIndex].name = that.addAccountData.name.trim();
          that.tableList[that.editIndex].company = that.addAccountData.company.trim();
          that.tableList[that.editIndex].position = that.addAccountData.position;
          that.tableList[that.editIndex].date = (that.addAccountData.date + '').trim() + that.addAccountData.type;
          that.dialogVisible = false;

          that.tableList[that.editIndex].phoneError = false;
          that.tableList[that.editIndex].companyError = false;
          that.tableList[that.editIndex].phoneEmptyError = false;
          that.tableList[that.editIndex].phoneRepeatError = false;
          that.tableList[that.editIndex].nameEmptyError = false;
          that.tableList[that.editIndex].companyEmptyError = false;
          that.tableList[that.editIndex].positionEmptyError = false;
          that.tableList[that.editIndex].dateError = false;
          that.tableList[that.editIndex].state = '';
        }
      }
    },
  }
}
</script>
