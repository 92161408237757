<template>
  <div class="page-control">
    <ul>
      <li><a :class="['first-page', firstPageShow === true ? '' : 'disabled']" title="首页"
             @click="firstPage(customerData,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow)"><span>首页</span></a>
      </li>
      <li><a :class="['pre-page', prePageShow === true ? '' : 'disabled']" title="上一页"
             @click="prePage(customerData,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow)"><span>上一页</span></a>
      </li>
      <li><a :class="['next-page', nextPageShow === true ? '' : 'disabled']" title="下一页"
             @click="nextPage(customerData,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow)"><span>下一页</span></a>
      </li>
    </ul>
    <img v-show="false" class="icon icon-loading" src="../assets/loading.gif"/>

    <div class="show-pageNum" v-show="showPageNum">
      <span>每页显示</span>
      <el-select class="page-select" v-model="numPerPage" @change="changeNumPerPage">
        <el-option value="10">10</el-option>
        <el-option value="20">20</el-option>
        <el-option value="50">50</el-option>
        <el-option value="100">100</el-option>
      </el-select>
      <span>条</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize: 10,
      numPerPage: 10
    }
  },
  props: ['customerData', 'currentPage', 'pageNum', 'firstPageShow', 'prePageShow', 'nextPageShow', 'pageRefer', 'showPageNum'],
  methods: {
    changeNumPerPage: function () {
      var that = this;

      that.pageSize = that.numPerPage;
      that.$emit('getCustomerList', true, that.pageSize);
    },
    firstPage: function (data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow) {
      if (firstPageShow === true) {
        var currentData = data.slice(0, this.pageSize);
        if (currentData.length > 0) {
          currentPage = 1;
          firstPageShow = false;
          prePageShow = false;
          nextPageShow = true;

          this.$emit('getPageData', currentData, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow);
        }
      }
    },
    prePage: function (data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow) {
      if (prePageShow === true) {
        var pageSize = this.pageSize;
        if (currentPage > 1) {
          currentPage--;
          if (currentPage === 1) {
            firstPageShow = false;
            prePageShow = false;
          }

          var currentData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
          if (currentData.length > 0) {
            nextPageShow = true;

            this.$emit('getPageData', currentData, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow);
          }
        } else {
          var currentData = data.slice(0, this.pageSize);
          if (currentData.length > 0) {
            currentPage = 1;
            firstPageShow = false;
            prePageShow = false;
            nextPageShow = true;

            this.$emit('getPageData', currentData, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow);
          }
        }
      }
    },
    nextPage: function (data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow) {
      if (nextPageShow === true) {
        var pageSize = this.pageSize;
        firstPageShow = true;
        prePageShow = true;

        if (data.length > currentPage * pageSize) {
          currentPage++;
          var currentData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);
          if (currentData.length < pageSize) {
            pageNum = currentPage;
            nextPageShow = false;
          }
          this.$emit('getPageData', currentData, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow);
        } else {
          this.$emit('getCustomerList', false, pageSize);
        }
      }
    }
  }
}
</script>

<style lang="less">
.page-control {
  padding: 10px;
  text-align: right;
  white-space: nowrap
}

.page-control:after, .page-control:before {
  content: '';
  display: table;
  clear: both
}

.page-control > .show-pageNum {
  display: block;
  color: #666;
  float: right;
}

.show-pageNum select {
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.page-control > ul {
  float: right;
  display: block
}

.page-control > ul:after, .page-control > ul:before {
  content: '';
  display: table;
  clear: both
}

.page-control > ul > li {
  float: left;
  display: inline-block
}

.page-control > ul > li > a {
  display: block;
  margin: 0 5px;
  padding: 2px 0;
  width: 60px;
  font-size: 14px;
  color: #666;
  text-align: center;
  border: 1px solid #e9edef;
  border-radius: 4px;
  cursor: pointer
}

.page-control > ul > li > a:hover {
  color: #14b8d4;
  text-decoration: none
}

.page-control > ul > li > a.disabled {
  color: #999;
  cursor: not-allowed;
  background: #eee
}

.icon-loading {
  width: 20px;
  margin: 2px 10px 0 0;
}
.page-select {
  width: 80px;
  height: 26px;
  margin: 0 5px;
  .el-input, .el-input input {
    width: 100%;
    height: 100%;
  }
  .el-input__suffix,  .el-input__icon {
    line-height: 26px;
  }
}
</style>
