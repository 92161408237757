import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Router from 'vue-router'

import Login from '@/pages/login/login'
import Index from '@/pages/index/index'
import ModifyPassWord from '@/pages/modifyPassWord/modifyPassWord'
import CheckList from '@/pages/checkList/checkList'

Vue.use(ElementUI)
Vue.use(Router)

const router = new Router({
//	mode : 'history', //如果不配置 mode，就会使用默认的 hash 模式，该模式下会将路径格式化为 #! 开头。//设置后刷新页面404？
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: {title: "登录"}
        },
        {
            path: '/index',
            name: 'index',
            component: Index,
            meta: {title: "首页"}
        },
        {
            path: '/modify',
            name: 'modify',
            component: ModifyPassWord,
            meta: {title: "修改密码"}
        },
        {
            path: '/checkList',
            name: 'checkList',
            component: CheckList,
            meta: {title: "校验列表"}
        }
    ]
})

export default router