<style scoped lang="less">
	.header-title{
		font-size: 16px;
	}
	.apply-for>header{
		background: #fff;
	}
	.apply-for>div{
		background: #fff;
	}

	.apply-for-header>form{
		padding: 10px 10px 0 10px;
	}

	.charts-container{
		margin-bottom: 10px;
	}
	.charts-container select{
		margin-left: 10px;
	}

	.charts-container{
		/*min-height: 386px;*/ /*切换时高度会改变*/
	}
	.charts-container>section{
		width: 100%;
		display: table;
		border-top: 10px solid #F5F8FA;
	}
	.pie-container{
		display: table-cell;
		box-sizing: border-box;
		width: 25%;
	}
	.pie-container.right-border{
		border-right: 10px solid #F5F8FA;
	}
	.pie-container>header{
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid #eee;
		text-align: center;
	}
	.pie-container>section{
		width: 100%;
		height: 240px;
	}

	.content-header{
		padding: 10px 10px;
		border-bottom: 1px solid #14B8D4;
	}
	.block{
	 	display: inline-block;
	 	margin: 10px 10px 0 0;
	}
	.block.start-date{
		margin: 0 0 0 0;
	}
	input.pageStart,
	input.pageEnd{
		width: 38px;
		height: 26px;
		border-radius: 0;
		border: 1px solid #ccc;
	}
	.el-date-editor.el-input{
		width: 130px;
		height: 32px;
    /deep/ .el-input__inner{
      width: 130px;
      height: 32px;
    }
	}

	.form-header input{
		height: 28px;
		padding-left: 10px;
		color: #666;
		margin: -1px 10px 0 0;
	}
	.form-header button{
		height: 32px;
		padding: 0 15px;
		background: #14B8D4;
		color: #fff;
		border: none;
		border-radius: 4px;
		cursor: pointer;
    margin-left: 10px;
	}

	td.text-left{
		max-width: 204px;
	}
	.allDates{
		word-break: break-all;
	}
	.allDates span{
		color: #14B8D4;
		cursor: pointer;
		text-decoration: underline;
		display: inline-block;
		margin-left: 5px;
	}

	.no-data{
		padding: 50px 0;
	}
	.no-data img{
	 	width: 160px;
	}

  input::-ms-clear{
    display: none;
  }

  .form-input {
    display: inline-block;
    width: 200px;
    height: 32px;
    margin-left: 10px;
    /deep/ .el-input, /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
  .form-select {
    width: 100px;
  }
  .form-number {
    width: 80px;
  }
  .start-input {
    margin-left: 0;
  }
  .date-text {
    margin-left: 10px;
  }

</style>

<template>
	<section class="apply-for">
		<permit-header v-on:getCustomerList="updatePageData" v-on:getQueryId="initQueryId"></permit-header>

		<div class="apply-for-content charts-container">
			<header class="content-header">
				<div class="header-title form-header">
					<div class="block">客户的使用统计图</div>
					<span>付费状态</span>
					<el-select class="form-input form-select" v-model="userRole" @change="updatePageData">
						<el-option value="" label="全部">全部</el-option>
						<el-option value="2" label="付费">付费</el-option>
						<el-option value="0" label="试用">试用</el-option>
						<el-option value="1" label="试用过期">试用过期</el-option>
						<el-option value="3" label="付费过期">付费过期</el-option>
					</el-select>
					<div class="block start-date">
						<el-date-picker
              v-model="startDate"
              class="form-input"
              type="date"
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="开始日期"
              @change="checkDate">
            </el-date-picker>
					</div>
					<span class="date-text">至</span>
					<div class="block">
						<el-date-picker
              v-model="endDate"
              class="form-input"
              type="date"
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="结束日期"
              @change="checkDate">
            </el-date-picker>
					</div>

					<div class="block">
						<span>访问页面数</span>
						<el-input class="form-input form-number pageStart" type="number" min="0" v-model.number="startPage" />
						<span class="date-text">至</span>
						<el-input class="form-input form-number pageStart" type="number" min="0" v-model.number="endPage" />
					</div>

					<div class="block">
						<el-input class="form-input start-input" v-model="queryPhone" placeholder="请输入查询手机号码" />
						<el-input class="form-input" v-model="queryCompany" placeholder="请输入查询公司名称" />
						<el-button @click="updatePageData()">查询</el-button>
					</div>
				</div>
			</header>
			<section>
				<div class="pie-container right-border">
					<header>
						<span>客户总数</span>
					</header>
					<section id="customerTotal" v-show="!customerTotalNoData"></section>
					<div class="no-data" v-show='customerTotalNoData'>
						<img src="../../assets/noData.png" />
						<span>暂无数据</span>
					</div>
				</div>
				<div class="pie-container right-border">
					<header>
						<span>付费用户使用情况</span>
					</header>
					<section id="customerPay" v-show='!customerPayNoData'></section>
					<div class="no-data" v-show='customerPayNoData'>
						<img src="../../assets/noData.png" />
						<span>暂无数据</span>
					</div>
				</div>
				<div class="pie-container right-border">
					<header>
						<span>试用客户使用情况</span>
					</header>
					<section id="customerTry" v-show='!customerTryNoData'></section>
					<div class="no-data" v-show='customerTryNoData'>
						<img src="../../assets/noData.png" />
						<span>暂无数据</span>
					</div>
				</div>
				<div class="pie-container">
					<header>
						<span>付费比</span>
					</header>
					<section id="payRatio" v-show='!customerTotalNoData'></section>
					<div class="no-data" v-show='customerTotalNoData'>
						<img src="../../assets/noData.png" />
						<span>暂无数据</span>
					</div>
				</div>
			</section>
		</div>

		<div class="apply-for-content">
			<header class="content-header">
				<div class="header-title">
					<span>{{startDate}}至{{endDate}}客户的使用情况</span>
				</div>
			</header>
			<section>
				<div class="query-result" v-show="queryNoDataContainer">
					<div class="table-wrapper">
						<table>
							<thead>
								<tr>
									<th>手机号</th>
									<th>姓名</th>
									<th>公司名称</th>
									<th>付费状态</th>
									<th>使用日期</th>
									<th>访问页面数(仅网站)</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
									<td><div>{{item.phone}}</div></td>
									<td><div>{{item.name}}</div></td>
									<td class="text-left"><div>{{item.company}}</div></td>
									<td>
										<div v-if="item.userRole === '4'" class="unpassed">未付费</div>
										<div v-if="item.userRole === '3'" class="unpassed">付费过期</div>
										<div v-if="item.userRole === '2'" class="unpassed">已付费</div>
										<div v-if="item.userRole === '1'" class="unpassed">试用过期</div>
										<div v-if="item.userRole === '0'" class="unpassed">试用</div>
									</td>
									<td class="text-left">
										<div class="allDates">{{item.useDate.split(';').slice(0,7).join(',')}}<span v-show="item.useDate.split(';').length > 7 ? true : false" @click="showAllDates(item.useDate)">全部</span></div>
									</td>
									<td>{{item.num}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<page-control
						v-on:getPageData="renderData"
						v-on:getCustomerList="initCustomerList"
						 :customerData="customerListStore"
						 :currentPage="currentPage"
						 :pageNum="pageNum"
						  :firstPageShow="firstPageShow"
						   :prePageShow="prePageShow"
						    :nextPageShow="nextPageShow"
						    :pageRefer="pageRefer"
						    :loading="imgLoading">
					</page-control>
				</div>
				<div class="no-data" v-show='queryNoDataImg'>
					<img src="../../assets/noData.png" />
					<span>暂无数据</span>
				</div>
			</section>
		</div>

		<div>
			<el-dialog title="使用日期" :visible.sync="allDatesDialogVisible" width="600px">
			  <div class="allDates">{{allDatesInDialog}}</div>
			  <span slot="footer" class="dialog-footer"></span>
			</el-dialog>
		</div>

	</section>
</template>

<script>
	import PermitUserHeader from '../../components/permitUserHeader'
	import echarts from '../../../public/static/common/echarts.js'
	import syAxios from '../../../public/static/common/axios.js'
	import getConfig from '../../../public/static/common/config.js'
	import PageControl from '@/components/PageControl'

	export default{
		data(){
			return{
				userId : '',
				queryId : '',
				userRole : '',
				startDate : '',
				endDate : '',
				startPage : '',
				endPage : '',
				queryPhone : '',
				queryCompany : '',
				pageSize : 10,
				pageNum : 1,
				currentPage : 0,
				phoneError : false,
				allDatesInDialog : '',
				allDatesDialogVisible : false,
				firstPageShow : false,
				prePageShow : false,
				nextPageShow : false,
				imgLoading : false,
				pageRefer : 'customerList',
				optionsData : [],
				weekDates : [],
				customerList : [],
				customerListStore : [],
				echarts : {
					customerTotal : null,
					customerPay : null,
					customerTry : null,
					payRatio : null
				},
				queryNoDataContainer : false,
				queryNoDataImg : false,
				customerTotalNoData : false,
				customerPayNoData : false,
				customerTryNoData : false,
				dateRange : ''
			}
		},
		components : {
			'permit-header' : PermitUserHeader,
			'page-control' : PageControl
		},
		mounted : function(){
			var that = this;

			that.userId = window.localStorage.getItem('userId') ||'';

			that.startDate = that.format(new Date(new Date().getTime() - 7*24*3600*1000),'yyyy-MM-dd');
			that.endDate = that.format(new Date(),'yyyy-MM-dd');
			that.updatePageData();

			window.onresize = function(){
				that.onResize();
			}
		},
		methods : {
			format : function(time, format){
				var t = new Date(time);
				var tf = function(i){return (i < 10 ? '0' : '') + i};
					return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
					switch(a){
					case 'yyyy':
					return tf(t.getFullYear());
					break;
					case 'MM':
					return tf(t.getMonth() + 1);
					break;
					case 'mm':
					return tf(t.getMinutes());
					break;
					case 'dd':
					return tf(t.getDate());
					break;
					case 'HH':
					return tf(t.getHours());
					break;
					case 'ss':
					return tf(t.getSeconds());
					break;
					}
				})
			},
			showAllDates : function(dates){
				var that = this;
				that.allDatesInDialog = dates;
				that.allDatesDialogVisible = true;
			},
			checkDate : function(data){
				var that = this;

				if(new Date(that.startDate).getTime() > new Date(that.endDate).getTime()){
					alert('开始时间不能晚于结束时间，请重新选择！');
				}else{
					that.updateCustomerUseStatisticsChart();
					that.updateUseStatisticsChart('customerPay');
					that.updateUseStatisticsChart('customerTry');
					that.initCustomerList(true);
				}
			},
			updatePageData : function(){
				var that = this;

				if(new Date(that.startDate).getTime() > new Date(that.endDate).getTime()){
					alert('开始时间不能晚于结束时间，请重新选择！');
				}else if((that.endPage + '').trim() !== '' && (that.startPage + '').trim() === ''){
					alert('请正确填写访问页面数！');
				}else{
					that.updateCustomerUseStatisticsChart();
					that.updateUseStatisticsChart('customerPay');
					that.updateUseStatisticsChart('customerTry');
					that.initCustomerList(true);
				}
			},
			initQueryId : function(queryId){
				this.queryId = queryId;
			},
			initCustomerList : function(init){
				var that = this;
				var config = getConfig();
				var data = {
				    queryId : that.queryId,
				    phone : that.queryPhone.trim(),
				    company : encodeURIComponent(that.queryCompany.trim()),
				    startPage : that.startPage,
				    endPage : that.endPage,
				    startDate : that.startDate,
				    endDate : that.endDate,
				    userRole : that.userRole,
		            pageNum : that.pageNum
				};
				if(init){
					data.pageNum = 1;
					that.pageNum = 1;
					that.currentPage = 0;
				}
				that.firstPageShow = false;
				that.prePageShow = false;
				that.nextPageShow = false;
//				this.loading = false;
				syAxios({
					ifProxy : true,
					url : config.index.customerStatistics.customerUseStatistics,
					data : data,
					success : function(d){
						var pageSize = that.pageSize;
						var data = d.data || {},
						    data_list = data.data_list || [],
						    l = data_list.length;

						var tableData = [];
					    if(l > 0){
							for(var i=0;i<l;i++){
								var phone = data_list[i].phone || '',
									name = data_list[i].name || '',
									company = data_list[i].companyName || '',
									useDate = data_list[i].useDate || '',
									num = data_list[i].num || '',
									applyId = data_list[i].applyId || '',
									userRole = data_list[i].userRole === undefined ? '' : (data_list[i].userRole + '');

								tableData.push({
									phone : phone,
									name : name,
									company : company,
									useDate : useDate,
									num : num,
									userRole : userRole,
									applyId : applyId
								})
							}
							that.queryNoDataContainer = true;
							that.queryNoDataImg = false;
						}
					    that.customerList = [].concat(tableData);

						if(init){
							if(l > 0){
								that.customerListStore = [].concat(tableData);
								if(l === pageSize){
									that.nextPageShow = true;
									that.pageNum++;
									that.currentPage = that.pageNum - 1;
								}
							}else{
								that.queryNoDataImg = true;
								that.queryNoDataContainer = false;
							}
						}else{
							if(l > 0){
								that.customerListStore = that.customerListStore.concat(tableData);
								that.firstPageShow = true;
								that.prePageShow = true;
								if(l === pageSize){
									that.nextPageShow = true;
									that.pageNum++;
									that.currentPage = that.pageNum - 1;
								}else{
									that.nextPageShow = false;
									that.currentPage = that.pageNum;
								}
							}else{
								that.firstPageShow = true;
								that.prePageShow = true;
								that.nextPageShow = false;
								that.currentPage = that.pageNum - 1;
								that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize,that.customerListStore.length);
							}
						}
//						this.loading = true;
					}
				})
			},
			renderData : function(data,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow){
				var that = this;

				that.customerList = [].concat(data);
				that.pageNum = pageNum;
				that.currentPage = currentPage;
				that.firstPageShow = firstPageShow;
				that.prePageShow = prePageShow;
				that.nextPageShow = nextPageShow;
			},
			onResize : function(){
				var that = this;
				for(var i in that.echarts){
					if(that.echarts.hasOwnProperty(i) && that.echarts[i].resize){
						that.echarts[i].resize();
					}
				}
			},
			updateCustomerUseStatisticsChart : function(){
				var that = this;
				var config = getConfig();

				var options = {
					color : ['#2091ef','#00cfff','#14b8d4','#12828e'],
					legend : {
				        itemWidth : 14,
				        itemHeight : 14,
				        bottom: 20,
				        left: 'center',
				        data: []
					},
					tooltip : {
						trigger: 'item',
						formatter: '{b}:<br />数量:{c}<br />比例:{d}%'
					},
					series : {
		                type : 'pie',
		                radius : [0,'40%'],
		                data : []
		            }
				};
				var optionsRatio = {
					color : ['#ccc','#00c0ff'],
					legend : {
				        itemWidth : 14,
				        itemHeight : 14,
				        borderRadius : 14,
				        bottom: 20,
				        left: 'center',
				        data: [
					        {name : '试用未付费',icon : 'circle'},
					        {name : '试用付费',icon : 'circle'}
				        ]
					},
					tooltip : {
						trigger: 'item',
						formatter: '{b}:<br />数量:{c}<br />比例:{d}%'
					},
					series : {
		                type : 'pie',
		                radius : [0,'40%'],
		                data : []
		            }
				};

				that.echarts.customerTotal = echarts.init(document.getElementById('customerTotal'));
				that.echarts.customerTotal.showLoading();

				that.echarts.payRatio = echarts.init(document.getElementById('payRatio'));
				that.echarts.payRatio.showLoading();

				var data = {
				    queryId : that.queryId,
				    phone : that.queryPhone,
				    startPage : that.startPage,
				    endPage : that.endPage,
				    company : encodeURIComponent(that.queryCompany.trim()),
				    userRole : that.userRole,
				    startDate : that.startDate,
				    endDate :that.endDate
				};

				syAxios({
					ifProxy : true,
					url : config.index.customerStatistics.customerUseStatisticsChart,
					data : data,
					success : function(d){
						var data = d.data || {},
							data_list = data.customer_total_list || [],
							len = data_list.length;
						if(len > 0){
							var result = data.result || {},
								pay_num = result.pay_num,
								tryout_num = result.tryout_num;

							optionsRatio.series.data.push({
								value : pay_num,
								name : '试用付费'
							},{
								value : tryout_num - pay_num,
								name : '试用未付费'
							});
							that.echarts.payRatio.setOption(optionsRatio);
							that.echarts.payRatio.hideLoading();

							for(var i=0;i<len;i++){
								var value = data_list[i].num || 0,
									userRole = data_list[i].userRole,
									name = '',
									ratio = ((value/tryout_num).toFixed(2))*100 + '%';
								switch(userRole + ''){
									case '0' :
										name = '试用';
										break;
									case '1' :
										name = '试用已过期';
										break;
									case '2' :
										name = '已付费';
										break;
									case '3' :
										name = '付费已过期';
										break;
									case '4' :
										name = '未付费';
										break;
									default :
										break;
								}

								options.series.data.push({
									value : value,
									name : name
								});
								options.legend.data.push({
									name : name,
									icon : 'circle'
								})
							}
							that.echarts.customerTotal.setOption(options);
							that.echarts.customerTotal.hideLoading();
							that.customerTotalNoData = false;
						}else{
							that.customerTotalNoData = true;
						}
					}
				})
			},
			updateUseStatisticsChart : function(type){
				var that = this;
				var config = getConfig();

				var colorsPay = ['#ccc','#fc7f68'],
					colorsTry = ['#ccc','#fd9162'];

				var options = {
					tooltip : {
						trigger: 'item',
						formatter: '{b}:<br />数量:{c}<br />比例:{d}%'
					},
					legend : {
				        itemWidth : 14,
				        itemHeight : 14,
				        bottom: 20,
				        left: 'center',
				        data: [
				        	{name : '使用',icon : 'circle'},
				        	{name : '未使用',icon : 'circle'}
				        ]
					},
					series : {
		                type : 'pie',
		                radius : [0,'40%'],
		                data : []
		            }
				};
				if(type === 'customerPay'){
					options.color = colorsPay;
				}else if(type === 'customerTry'){
					options.color = colorsTry;
				}

				that.echarts[type] = echarts.init(document.getElementById(type));
				that.echarts[type].showLoading();

				var data = {
				    queryId : that.queryId,
				    phone : that.queryPhone.trim(),
				    company : encodeURIComponent(that.queryCompany.trim()),
				    startPage : that.startPage,
				    endPage : that.endPage,
				    startDate : that.startDate,
				    endDate :that.endDate
				};

				if(type === 'customerPay'){
					data.userRole = '2';
				}else if(type === 'customerTry'){
					data.userRole = '0';
				}

				syAxios({
					ifProxy : true,
					url : config.index.customerStatistics.useStatisticsChart,
					data : data,
					success : function(d){
						var data = d.data || {},
							result = data.result || {};

						var unused = result.unused,
							used = result.used;

						if(unused === undefined && used === undefined){
							if(type === 'customerPay'){
								that.customerPayNoData = true;
							}else if(type === 'customerTry'){
								that.customerTryNoData = true;
							}
						}else{
							options.series.data.push({
								value : unused,
								name : '未使用'
							},
							{
								value : used,
								name : '使用'
							});
							that.echarts[type].setOption(options);

							if(type === 'customerPay'){
								that.customerPayNoData = false;
							}else if(type === 'customerTry'){
								that.customerTryNoData = false;
							}
						}
						that.echarts[type].hideLoading();
					}
				})
			}
		}
	}

</script>
