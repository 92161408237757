<!--技术人员模块-->
<style scoped lang="less">
@import '../../../public/static/css/common.css';

.header-title {
  position: relative;
  font-size: 16px;
}

.header-title select {
  height: 26px;
  margin-left: 10px;
}

.header-title .el-button {
  position: absolute;
  right: 10px;
  top: -3px;
  height: 28px;
  padding: 0 10px;
  background: #14B8D4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-for > header {
  margin-bottom: 10px;
  background: #fff;
}

.apply-for > div {
  background: #fff;
}

.content-header {
  padding: 10px;
  border-bottom: 1px solid #14B8D4;
}

a.link {
  color: #14B8D4;
  cursor: pointer;
}

.td-date {
  width: 1%;
  white-space: nowrap;
}

.el-input__inner {
  width: 130px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
}

.el-dialog {
  border-radius: 20px;
  padding: 20px;
}

.form-container {
  padding: 20px 20px 0 20px;
}

.form-group {
  margin-bottom: 20px;
  color: #333;
}

.form-group span {
  display: inline-block;
  text-align: right;
  width: 120px;
  margin: 0 4px;
}

.form-group mark {
  background: #fff;
  color: #f00;
  margin: 4px;
  font-size: 16px;
}

.form-group > input {
  width: 300px;
  height: 32px;
  padding-left: 20px;
}

.form-group > textarea {
  display: inline-block;
  vertical-align: top;
  width: 300px;
  padding-left: 20px;
}

.form-group select {
  width: 300px;
  height: 32px;
  color: #333;
}

.el-date-editor.el-input {
  width: 300px;
  height: 30px;
  border-radius: 0;
}

.el-input__inner {
  width: 300px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
}

.el-button {
  background: #fff;
  border: 1px solid #14B8D4;
  border-radius: 10px;
  color: #14B8D4;
  font-weight: 600;
  padding: 6px 16px;
}

.el-button.btn-blue {
  background: #14B8D4;
  color: #fff;
}

.avatar-uploader {
  display: inline-block;
}

.uploader-container ul {
  width: 300px;
  max-height: 107px;
  margin-top: 10px;
  margin-left: 130px;
  overflow-y: auto;
  background: #efefef;
}

.uploader-container ul > li {
  padding: 2px 10px;
  border-bottom: 1px solid #ccc;
}

.form-group .file-name {
  display: inline-block;
  width: 206px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-delete {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('./image/icon-delete.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.icon-excel {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('./image/icon-excel.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.icon-word {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('./image/icon-word.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.dialog-container .detail {
  padding: 10px;
}

.detail p {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  word-break: break-word;
}

.operate-wrapper {
  > span {
    margin-left: 5px;

    &:first-child{
      margin-left: 0;
    }
  }
}

.form-select {
  width: 100px;
  height: 32px;
  margin-left: 10px;
  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}

</style>

<template>
<section class="apply-for">
  <permit-header
      :customerList="false"
      v-on:getCustomerList="initCustomerList(true)"
      @getQueryId="initQueryId">
  </permit-header>
  <div class="apply-for-content">
    <section>
      <header class="content-header">
        <div class="header-title">
          <span>技术支持详情列表</span>
          <el-select class="form-select" v-model="progress" @change="initCustomerList(true)">
            <el-option value="" label="全部">全部</el-option>
            <el-option value="3" label="主管确认">主管确认</el-option>
            <el-option value="4" label="技术拒绝">技术拒绝</el-option>
            <el-option value="5" label="技术确认">技术确认</el-option>
            <el-option value="7" label="已导出">已导出</el-option>
          </el-select>
        </div>
      </header>
      <div class="query-result" v-show="showDataContainer">
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th v-if="techCompletedStatus === true">编辑</th>
              <th>发起日期</th>
              <th>支持类型</th>
              <th>发起人</th>
              <th>人员类型</th>
              <th>销售人员</th>
              <th>客户名称</th>
              <th>项目名称</th>
              <th>项目描述</th>
              <th>截止日期</th>
              <th>附件</th>
              <th>操作</th>
              <th>导出结果</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in customerList"
                :class="[index % 2 === 0 ? '' : 'tr-even', item.complete + '' === '1' ? 'completed' : '']">
              <!--不可编辑状态-->
              <template v-if="item.complete + '' === '111'">
              <td v-if="techCompletedStatus === true">
                <div v-if="item.complete + '' === '0'">
                  <input type="checkbox" @click="changeCheckStatus($event,item)"/>
                </div>
                <div v-else>
                  <input type="checkbox" checked="checked" disabled/>
                </div>
              </td>
              <td>
                <div>{{item.LaunchDate}}</div>
              </td>
              <td>
                <div v-if="item.supportType + '' === '1'">技术支持非现场</div>
                <div v-else-if="item.supportType + '' === '2'">技术支持现场</div>
                <div v-else-if="item.supportType + '' === '3'">poc测试</div>
                <div v-else-if="item.supportType + '' === '4'">项目实施</div>
              </td>
              <td>
                <div>{{item.userName}}</div>
              </td>
              <td>
                <div>{{item.personText}}</div>
              </td>
              <td>
                <div>{{item.customer}}</div>
              </td>
              <td>
                <div>{{item.project}}</div>
              </td>
              <td>
                <div>--</div>
              </td>
              <td>
                <div v-if="item.description.length > 0">
                  <a>详情</a>
                </div>
                <div v-else>--</div>
              </td>
              <td v-if="item.personType + '' === '1'">
                <template v-if="item.progress + '' === '3'">
                <div v-if="item.supportType + '' === '3'">
                  <span><a>上传</a></span>
                  <span><a>拒绝</a></span>
                </div>
                <div v-else>
                  <span><a>确认</a></span>
                  <span><a>拒绝</a></span>
                </div>
                </template>
                <template v-else-if="item.progress + '' === '4'">
                <div>已拒绝</div>
                </template>
                <template v-else-if="item.progress + '' === '5'">
                <div>已确认</div>
                </template>
                <template v-else-if="item.progress + '' === '6'">
                <div><a>上传</a></div>
                </template>
                <template v-else-if="item.progress + '' === '7'">
                <div>已导出</div>
                </template>
              </td>
              <td v-else>
                <div>--</div>
              </td>
              <td>
                <div>--</div>
              </td>
              </template>
              <!--可编辑状态-->
              <template v-else>
              <td v-if="techCompletedStatus === true">
                <div v-if="item.complete + '' === '0'">
                  <input type="checkbox" @click="changeCheckStatus($event,item)"/>
                </div>
                <div v-else>
                  <input type="checkbox" checked="checked" disabled/>
                </div>
              </td>
              <td>
                <div>{{item.LaunchDate}}</div>
              </td>
              <td>
                <div v-if="item.supportType + '' === '1'">技术支持非现场</div>
                <div v-else-if="item.supportType + '' === '2'">技术支持现场</div>
                <div v-else-if="item.supportType + '' === '3'">poc测试</div>
                <div v-else-if="item.supportType + '' === '4'">项目实施</div>
              </td>
              <td>
                <div>{{item.userName}}</div>
              </td>
              <td>
                <div>{{item.personText}}</div>
              </td>
              <td>
                <div>{{item.saleNames}}</div>
              </td>
              <td>
                <div>{{item.customer}}</div>
              </td>
              <td>
                <div>{{item.project}}</div>
              </td>
              <td>
                <div v-if="item.description.length > 0">
                  <a class="link" @click="techDetail(item.description)">详情</a>
                </div>
                <div v-else>--</div>
              </td>
              <td>
                <div>{{item.deadline}}</div>
              </td>
              <td>
                <div v-if="item.urlList && item.urlList.length > 0">
                  <a v-for="file in item.urlList" :href="file.url" :download="file.name" :key="file.url" target="_blank">
                    <i v-if="file.url.indexOf('.XLSX') > 0 || file.url.indexOf('.XLS') > 0" class="icon icon-excel"></i>
                    <i v-if="file.url.indexOf('.DOCX') > 0 || file.url.indexOf('.DOC') > 0" class="icon icon-word"></i>
                  </a>
                </div>
                <div v-else>--</div>
              </td>
              <td v-if="item.personType + '' === '1'">
                <template v-if="item.progress + '' === '3'">
                <div class="operate-wrapper" v-if="item.supportType + '' === '3'">
                  <span><a class="link" @click="uploadFileBtn(item)">上传</a></span>
                  <span><a class="link" @click="updateTechStatus(4,item.technicalSupportId)">拒绝</a></span>
                </div>
                <div class="operate-wrapper" v-else>
                  <span><a class="link" @click="updateTechStatus(5,item.technicalSupportId)">确认</a></span>
                  <span><a class="link" @click="updateTechStatus(4,item.technicalSupportId)">拒绝</a></span>
                </div>
                </template>
                <template v-else-if="item.progress + '' === '4'">
                <div>已拒绝</div>
                </template>
                <template v-else-if="item.progress + '' === '5'">
                <div>已确认</div>
                </template>
                <template v-else-if="item.progress + '' === '6'">
                <div><a class="link" @click="uploadFileBtn(item)">上传</a></div>
                </template>
                <template v-else-if="item.progress + '' === '7'">
                <div>已导出</div>
                </template>
              </td>
              <td v-else>
                <div>--</div>
              </td>
              <td>
                <div v-if="item.exportUrlList && item.exportUrlList.length > 0">
                  <a v-for="(file, fileIndex) in item.exportUrlList" :href="file.url" :download="file.name">
                    <i v-if="file.url.indexOf('.XLSX') > 0 || file.url.indexOf('.XLS') > 0" class="icon icon-excel"></i>
                    <i v-if="file.url.indexOf('.DOCX') > 0 || file.url.indexOf('.DOC') > 0" class="icon icon-word"></i>
                  </a>
                </div>
                <div v-else>--</div>
              </td>
              </template>
            </tr>
            </tbody>
          </table>
        </div>
        <page-control
            v-on:getPageData="renderData"
            v-on:getCustomerList="initCustomerList"
            :customerData="customerListStore"
            :currentPage="currentPage"
            :pageNum="pageNum"
            :firstPageShow="firstPageShow"
            :prePageShow="prePageShow"
            :nextPageShow="nextPageShow"
            :pageRefer="pageRefer"
            :loading="imgLoading">
        </page-control>
      </div>
      <div class="no-data" v-show='noData'>
        <img src="../../assets/noData.png"/>
        <span>暂无数据</span>
      </div>
    </section>
  </div>
  <!--编辑弹窗-->
  <div class="dialog-container">
    <!--上传附件弹窗-->
    <el-dialog title="技术支持" :visible.sync="dialog.dialogVisible" width="600px">
      <div class="form-container">
        <form>
          <div class="form-group uploader-container">
            <span>附件:</span>
            <el-upload
                class="avatar-uploader btn-blue"
                :action="fileActionUrl"
                :data="fileParams"
                :show-file-list="false"
                :on-success="handleFileUploadSuccess"
                :before-upload="beforeFileUpload">
              <el-button class="btn-blue" type="primary">点击上传</el-button>
            </el-upload>
            <small>word、excel格式的文件，大小不超过10M</small>
            <ul>
              <li v-for="(item, index) in fileList">
                <div>
                  <template v-if="item.url.length > 0">
                  <i v-if="item.url.indexOf('.XLSX') > 0 || item.url.indexOf('.XLS') > 0" class="icon icon-excel"></i>
                  <i v-if="item.url.indexOf('.DOCX') > 0 || item.url.indexOf('.DOC') > 0" class="icon icon-word"></i>
                  </template>
                  <span class="file-name">{{item.name}}</span>
                  <i class="file-delete" @click="deleteFile(item)"></i>
                </div>
              </li>
            </ul>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitFileUpload">确认完成</el-button>
			    <el-button @click="dialog.dialogVisible = false">取 消</el-button>
			  </span>
    </el-dialog>

    <!--详情弹窗-->
    <el-dialog title="项目描述" :visible.sync="dialog.detailVisible" width="600px">
      <div class="detail">
        <p>{{dialog.detail}}</p>
      </div>
    </el-dialog>
  </div>
</section>
</template>

<script>
import PermitUserHeader from '../../components/permitUserHeader'
import PageControl from '@/components/PageControl'
import syAxios from '../../../public/static/common/axios.js'
import getConfig from '../../../public/static/common/config.js'

export default {
  data() {
    return {
      userId: '',
      queryId: '',
      pageSize: 10,
      pageNum: 1,
      currentPage: 0,
      statisticsShow: true,
      showDataContainer: false,
      noData: false,
      firstPageShow: false,
      prePageShow: false,
      nextPageShow: false,
      showPageNum: true,
      numPerPage: 10,
      imgLoading: false,
      pageRefer: 'customerList',
      currentEditId: '',
      optionsData: [],
      customerList: [],
      customerListStore: [],
      userRole: '',
      progress: '', //筛选列表（进展）
      dialog: {
        dialogVisible: false,
        detailVisible: false,
      },
      techCompletedStatus: false,
      techCompletedIds: [],
      fileList: [],
      fileParams: {
        fileName: ''
      },
      editTechCompletedId: ''
    }
  },
  components: {
    'permit-header': PermitUserHeader,
    'page-control': PageControl
  },
  created: function() {
    let that = this;
    let config = getConfig();
    that.userId = window.localStorage.getItem('userId') || '';
    that.queryId = this.userId;
    that.initCustomerList(true);
    that.fileActionUrl = config.proxyHeader + config.uploadFile;
  },
  methods: {
    initQueryId: function(queryId) {
      this.queryId = queryId;
      this.initCustomerList(true);
    },
    initCustomerList: function(init, numPerPage) {
      let that = this;
      let config = getConfig();
      if (numPerPage) {
        that.pageSize = Number(numPerPage);
        that.numPerPage = that.pageSize;
      }
      let data = {
        progress: that.progress,
        leaderIdOrParticipantsId: that.queryId,
        pageNum: that.pageNum,
        numPerPage: that.numPerPage
      };

      if (init) {
        data.pageNum = 1;
        that.pageNum = 1;
        that.currentPage = 0;
      }
      that.firstPageShow = false;
      that.prePageShow = false;
      that.nextPageShow = false;
      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.technicalSupport,
        data: data,
        success: function(d) {
          let pageSize = that.pageSize;
          let data = d.data || {},
            data_list = data.leaderOrParticipantsResult || [],
            l = data_list.length;

          let tableData = [];
          if (l > 0) {
            for (let i = 0; i < l; i++) {
              let LaunchDate = data_list[i].LaunchDate || '--',
                supportType = data_list[i].supportType || '',
                userName = data_list[i].userName || '--',
                customer = data_list[i].customer || '--',
                saleNames = data_list[i].saleNames || '--',
                project = data_list[i].project || '--',
                progress = data_list[i].progress || '',
                finishDate = data_list[i].finishDate || '--',
                leaderId = data_list[i].leaderId || '',
                description = data_list[i].description || '',
                deadline = data_list[i].deadline || '--',
                url = data_list[i].exportUrl || '',
                urlList = data_list[i].urlList || [],
                exportUrlList = data_list[i].exportUrlList || [],
                technicalSupportId = data_list[i].technicalSupportId || '',
                complete = data_list[i].complete === undefined ? '' : data_list[i].complete;

              let personType = '', personText = '';
              if (leaderId === that.queryId) {
                personType = 1;
                personText = '负责人';
              } else {
                personType = 0;
                personText = '参与者';
              }
              tableData.push({
                LaunchDate: LaunchDate,
                supportType: supportType,
                userName: userName,
                customer: customer,
                saleNames: saleNames,
                project: project,
                progress: progress,
                finishDate: finishDate,
                deadline: deadline,
                personType: personType,
                personText: personText,
                description: description,
                url: url,
                urlList: urlList,
                exportUrlList: exportUrlList,
                technicalSupportId: technicalSupportId,
                complete: complete
              })
            }
            that.showDataContainer = true;
            that.noData = false;
          }
          that.customerList = [].concat(tableData);

          if (init) {
            if (l > 0) {
              that.customerListStore = [].concat(tableData);
              if (l === pageSize) {
                that.nextPageShow = true;
                that.pageNum++;
                that.currentPage = that.pageNum - 1;
              }
            } else {
              that.noData = true;
              that.showDataContainer = false;
            }
          } else {
            if (l > 0) {
              that.customerListStore = that.customerListStore.concat(tableData);
              that.firstPageShow = true;
              that.prePageShow = true;
              if (l === pageSize) {
                that.nextPageShow = true;
                that.pageNum++;
                that.currentPage = that.pageNum - 1;
              } else {
                that.nextPageShow = false;
                that.currentPage = that.pageNum;
              }
            } else {
              that.firstPageShow = true;
              that.prePageShow = true;
              that.nextPageShow = false;
              that.currentPage = that.pageNum - 1;
              that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize, that.customerListStore.length);
            }
          }
        }
      })
    },
    renderData: function(data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow, pageRefer) {
      let that = this;

      that.customerList = [].concat(data);
      that.pageNum = pageNum;
      that.currentPage = currentPage;
      that.firstPageShow = firstPageShow;
      that.prePageShow = prePageShow;
      that.nextPageShow = nextPageShow;
      that.pageRefer = pageRefer;
    },
    /**
     * 上传文件
     * */
    handleFileUploadSuccess(res, file) {
      let that = this;
      let data = res.data || {};
      let url = data.card;

      if (typeof url === 'string') {
        that.fileList.push({
          name: file.name || '',
          url: url
        });
      }
    },
    beforeFileUpload(file) {
      this.fileParams.fileName = file.name;
      this.fileUploadCondition(file);
    },
    fileUploadCondition: function(file) {
      let fileData = file;
      const isFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        || file.type === 'application/vnd.ms-excel'
        || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        || file.type === 'application/msword';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isFile) {
        alert('上传文件为 word、excel格式!');
      }
      if (!isLt2M) {
        alert('上传文件大小不能超过 10M!');
      }
      return isFile && isLt2M;
    },
    /**
     * 删除文件
     * */
    deleteFile: function(file) {
      let that = this;
      let fileList = that.fileList || [];
      for (let i = 0, l = fileList.length; i < l; i++) {
        if (fileList[i].url === file.url) {
          fileList.splice(i, 1);
          l = fileList.length;
        }
      }
    },
    /**
     * 上传按钮
     * */
    uploadFileBtn: function(data) {
      let that = this;
      that.fileList = data.exportUrlList || [];
      that.editTechCompletedId = data.technicalSupportId || '';
      that.dialog.dialogVisible = true;
    },
    /**
     * 提交上传文件
     * */
    submitFileUpload: function() {
      let that = this;
      let config = getConfig();
      let fileList = that.fileList || [], fileUrls = [];
      if (fileList.length > 0) {
        for (let i = 0, l = fileList.length; i < l; i++) {
          fileUrls.push({
            name: fileList[i].name || '',
            url: fileList[i].url || ''
          });
        }
      }
      let data = {
        url: encodeURIComponent(JSON.stringify(fileUrls)),
        technicalSupportId: that.editTechCompletedId
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.updateTechnicalSupportUrl,
        data: data,
        success: function(d) {
          let data = d.data || {};
          if (data.success) {
            that.initCustomerList(true);
            that.dialog.dialogVisible = false;
          }
        }
      })
    },
    /**
     * 查看详情
     * */
    techDetail: function(detail) {
      let that = this;
      that.dialog.detail = detail;
      that.dialog.detailVisible = true;
    },
    /**
     * 更改状态
     * */
    updateTechStatus: function(progress, id) {
      let that = this;
      let config = getConfig();
      let data = {
        progress: progress,
        technicalSupportId: id
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.updateTechnicalSupportProgress,
        data: data,
        success: function(d) {
          let data = d.data || {};
          if (data.success) {
            that.initCustomerList(true);
          }
        }
      })
    },
    /**
     * 编辑已完成按钮
     * */
    editTechCompleted: function() {
      this.techCompletedStatus = true;
    },
    /**
     * 更改技术支持状态
     * */
    changeCheckStatus: function(e, item) {
      let that = this;
      let check = e.target.checked;
      let techCompletedIds = that.techCompletedIds || [];
      if (check === true) {
        let count = 0;
        for (let i = 0, l = techCompletedIds.length; i < l; i++) {
          if (techCompletedIds[i] === item.technicalSupportId) {
            count++;
          }
        }
        if (count === 0) {
          techCompletedIds.push(item.technicalSupportId);
        }
      } else {
        for (let i = 0, l = techCompletedIds.length; i < l; i++) {
          if (techCompletedIds[i] === item.technicalSupportId) {
            techCompletedIds.splice(i, 1);
            l = techCompletedIds.length;
          }
        }
      }
      console.log(that.techCompletedIds);
    },
    /**
     * 确认已完成
     * */
    sureTechCompleted: function() {
      let that = this;
      if (that.techCompletedIds.length > 0) {
        let config = getConfig();
        let data = {
          technicalSupportId: that.techCompletedIds.join(',')
        };

        syAxios({
          ifProxy: true,
          url: config.index.customerTechnology.technicalSupportComplete,
          data: data,
          success: function(d) {
            let data = d.data || {};
            if (data.success) {
              alert('确认完成！');
              that.techCompletedStatus = false;
              that.initCustomerList(true);
            }
          }
        })
      } else {
        alert('请选择确认技术完成数据！');
      }
    },
  }
}
</script>
