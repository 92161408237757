<!--技术人员模块-->
<style scoped lang="less">
@import '../../../public/static/css/common.css';

.header-title {
  position: relative;
  font-size: 16px;
}

.header-title select {
  height: 26px;
  margin-left: 10px;
}

.header-title .el-button {
  position: absolute;
  right: 10px;
  top: -3px;
  height: 28px;
  padding: 0 10px;
  background: #14B8D4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-for > header {
  margin-bottom: 10px;
  background: #fff;
}

.apply-for > div {
  background: #fff;
}

.content-header {
  padding: 10px;
  border-bottom: 1px solid #14B8D4;
}

a.link {
  color: #14B8D4;
  cursor: pointer;
}

.td-date {
  width: 1%;
  white-space: nowrap;
}


.el-input__inner {
  width: 130px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
}

.dialog-container .detail {
  padding: 10px;
}

.detail p {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  word-break: break-word;
}

.el-dialog {
  border-radius: 20px;
  padding: 20px;
}

.form-container {
  padding: 20px 20px 0 20px;
}

.form-group {
  margin-bottom: 20px;
  color: #333;
}

.form-group > span {
  display: inline-block;
  text-align: right;
  width: 120px;
  margin: 0 4px;
}

.form-group mark {
  background: #fff;
  color: #f00;
  margin: 4px;
  font-size: 16px;
}

.form-group > input {
  width: 300px;
  height: 32px;
  padding: 0 5px;
}

.form-group > .el-textarea {
  display: inline-block;
  vertical-align: top;
  width: 300px;
  ::placeholder {
    color: #999;
  }
}

.form-group select {
  width: 300px;
  height: 32px;
  color: #333;
}

.part-group {
  display: inline-block;
}

.part-group > a {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 32px;
  padding-left: 5px;
  line-height: 30px;
  border: 1px solid #ccc;
  vertical-align: middle;
  cursor: pointer;
}

.part-group > a > span {
  display: inline-block;
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.part-group > a > i {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: #eee;
  background-image: url('./image/icon-arrow-down.png');
  background-repeat: no-repeat;
  background-position: center;
}

.check-container {
  position: absolute;
  left: -1px;
  top: 32px;
  width: 300px;
  height: 240px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ccc;
  overflow-y: auto;
  z-index: 99;
}

li {
  list-style: none;
}

.check-container i {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('./image/icon-check.png');
  background-repeat: no-repeat;
  vertical-align: middle;
}

.check-container li.checked i {
  background-image: url('./image/icon-checked.png');
}

.el-date-editor.el-input {
  width: 300px;
  height: 30px;
  border-radius: 0;
}

.el-input__inner {
  width: 300px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
}

.el-button {
  background: #fff;
  border: 1px solid #14B8D4;
  border-radius: 10px;
  color: #14B8D4;
  font-weight: 600;
  padding: 6px 16px;
}

.el-button.btn-blue {
  background: #14B8D4;
  color: #fff;
}

.avatar-uploader {
  display: inline-block;
}

.uploader-container ul {
  width: 300px;
  max-height: 107px;
  margin-top: 10px;
  margin-left: 130px;
  overflow-y: auto;
  background: #efefef;
}

.uploader-container ul > li {
  padding: 2px 10px;
  border-bottom: 1px solid #ccc;
}

.file-name {
  display: inline-block;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-delete {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('./image/icon-delete.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-excel {
  background-image: url('./image/icon-excel.png');
  cursor: pointer;
}

.icon-word {
  background-image: url('./image/icon-word.png');
  cursor: pointer;
}

.icon-excel-gray {
  background-image: url('./image/icon-excel-gray.png');
}

.icon-word-gray {
  background-image: url('./image/icon-word-gray.png');
}

.error-wrapper {
  text-align: center;
  color: #f00;
}

.form-select {
  width: 100px;
  height: 32px;
  margin-left: 10px;

  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}

.form-input {
  display: inline-block;
  width: 300px;
  height: 32px;

  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}
.edit-select {
  /deep/ .el-select-dropdown__item {
    padding: 0;
    .el-checkbox {
      width: 100%;
      padding: 0 40px 0 20px;
    }
    &::after {
      display: none;
    }
  }
}
</style>

<template>
  <section class="apply-for">
    <permit-header
        :customerList="false"
        v-on:getCustomerList="initCustomerList(true)"
        v-on:getQueryId="initQueryId">
    </permit-header>
    <div class="apply-for-content">
      <section>
        <header class="content-header">
          <div class="header-title">
            <span>技术支持详情列表</span>
            <el-select class="form-select" v-model="progress" @change="initCustomerList(true)">
              <el-option value="" label="全部">全部</el-option>
              <el-option value="0" label="撤销">撤销</el-option>
              <el-option value="1" label="待审核">待审核</el-option>
              <el-option value="2" label="主管拒绝">主管拒绝</el-option>
              <el-option value="3" label="主管确认">主管确认</el-option>
              <el-option value="4" label="技术拒绝">技术拒绝</el-option>
              <el-option value="5" label="技术确认">技术确认</el-option>
              <el-option value="7" label="已导出">已导出</el-option>
            </el-select>
            <el-button @click="addTechEvent">新增技术支持</el-button>
          </div>
        </header>
        <div class="query-result" v-show="showDataContainer">
          <div class="table-wrapper">
            <table>
              <thead>
              <tr>
                <th>发起日期</th>
                <th>支持类型</th>
                <th>客户名称</th>
                <th>项目名称</th>
                <th>项目描述</th>
                <th>进展</th>
                <th>完成日期</th>
                <th>操作</th>
                <th>下载</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
                <td>
                  <div>{{ item.LaunchDate }}</div>
                </td>
                <td>
                  <div v-if="item.supportType + '' === '1'">技术支持非现场</div>
                  <div v-else-if="item.supportType + '' === '2'">技术支持现场</div>
                  <div v-else-if="item.supportType + '' === '3'">poc测试</div>
                  <div v-else-if="item.supportType + '' === '4'">项目实施</div>
                </td>
                <td class="text-left">
                  <div>{{ item.customer }}</div>
                </td>
                <td>
                  <div>{{ item.project }}</div>
                </td>
                <td>
                  <div v-if="item.description.length > 0">
                    <a class="link" @click="showProjectDesc(item.description)">详情</a>
                  </div>
                  <div v-else>--</div>
                </td>
                <td>
                  <div v-if="item.progress + '' === '7'">已导出</div>
                  <div v-if="item.progress + '' === '6'">导出中</div>
                  <div v-if="item.progress + '' === '5'">技术确认</div>
                  <div v-if="item.progress + '' === '4'">技术拒绝</div>
                  <div v-if="item.progress + '' === '3'">主管确认</div>
                  <div v-if="item.progress + '' === '2'">主管拒绝</div>
                  <div v-if="item.progress + '' === '1'">待审核</div>
                  <div v-if="item.progress + '' === '0'">撤销</div>
                </td>
                <td>
                  <div v-html="item.finishDate"></div>
                </td>
                <td v-if="item.userId === userId">
                  <div v-if="item.progress + '' === '1'">
                    <a class="link" @click="updateTechStatus(item)">撤销</a>
                  </div>
                  <div
                      v-else-if="item.progress + '' === '0' || item.progress + '' === '2' || item.progress + '' === '4'">
                    <a class="link" @click="editTechEvent(item.technicalSupportId)">编辑</a>
                  </div>
                  <div v-else>
                    <span>--</span>
                  </div>
                </td>
                <td v-else>
                  <div v-if="item.progress + '' === '1'">
                    <span>撤销</span>
                  </div>
                  <div
                      v-else-if="item.progress + '' === '0' || item.progress + '' === '2' || item.progress + '' === '4'">
                    <span>编辑</span>
                  </div>
                  <div v-else>
                    <span>--</span>
                  </div>
                </td>
                <td v-if="item.userId === userId">
                  <div v-if="item.exportUrlList && item.exportUrlList.length > 0">
                    <a v-for="(file, fileIndex) in item.exportUrlList" @click.prevent="loadFile(file)">
                      <i v-if="file.url.indexOf('.XLSX') > 0 || file.url.indexOf('.XLS') > 0"
                         class="icon icon-excel"></i>
                      <i v-if="file.url.indexOf('.DOCX') > 0 || file.url.indexOf('.DOC') > 0"
                         class="icon icon-word"></i>
                    </a>
                  </div>
                  <div v-else>--</div>
                </td>
                <td v-else>
                  <div v-if="item.exportUrlList && item.exportUrlList.length > 0">
                    <a v-for="(file, fileIndex) in item.exportUrlList">
                      <i v-if="file.url.indexOf('.XLSX') > 0 || file.url.indexOf('.XLS') > 0"
                         class="icon icon-excel-gray"></i>
                      <i v-if="file.url.indexOf('.DOCX') > 0 || file.url.indexOf('.DOC') > 0"
                         class="icon icon-word-gray"></i>
                    </a>
                  </div>
                  <div v-else>--</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <page-control
              v-on:getPageData="renderData"
              v-on:getCustomerList="initCustomerList"
              :customerData="customerListStore"
              :currentPage="currentPage"
              :pageNum="pageNum"
              :firstPageShow="firstPageShow"
              :prePageShow="prePageShow"
              :nextPageShow="nextPageShow"
              :pageRefer="pageRefer"
              :loading="imgLoading">
          </page-control>
        </div>
        <div class="no-data" v-show='noData'>
          <img src="../../assets/noData.png"/>
          <span>暂无数据</span>
        </div>
      </section>
    </div>
    <!--编辑弹窗-->
    <div class="dialog-container" @click="hideDialogUnderList">
      <el-dialog title="技术支持" :visible.sync="dialog.dialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>支持类型:</span>
              <el-select class="form-input" v-model="formData.supportType" @change="clearSaleCheck">
                <el-option value="1" label="技术支持非现场">技术支持非现场</el-option>
                <el-option value="2" label="技术支持现场">技术支持现场</el-option>
                <el-option value="3" label="poc测试">poc测试</el-option>
                <el-option value="4" label="项目实施">项目实施</el-option>
              </el-select>
            </div>
            <div class="form-group">
              <span><mark>*</mark>负责人:</span>
              <el-select class="form-input" v-model="formData.leaderId" @change="checkLeader">
                <el-option value="" label="请选择负责人">请选择负责人</el-option>
                <el-option v-for="(item, index) in parterList" :key="index" :value="item.id" :label="item.name">
                  {{ item.name }}
                </el-option>
              </el-select>
            </div>
            <div class="form-group">
              <span><mark>*</mark>参与人员:</span>
              <el-select class="form-input edit-select" @remove-tag="removeTag" :popper-append-to-body="false" v-model="parterIdsList" multiple placeholder="请选择参与人员">
                <el-option
                    v-for="item in parterList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  <el-checkbox v-model="item.check" @change="(value) => selectChange(value,item.id)">{{ item.name }}</el-checkbox>
                </el-option>
              </el-select>
            </div>
            <div class="form-group" v-if="formData.supportType === '4'">
              <span><mark>*</mark>销售人员:</span>
              <el-select class="form-input edit-select" @remove-tag="removeSaleTag" :popper-append-to-body="false" v-model="saleIdsList" multiple placeholder="请选择销售人员">
                <el-option
                    v-for="item in saleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  <el-checkbox v-model="item.check" @change="(value) => selectSaleChange(value,item.id)">{{ item.name }}</el-checkbox>
                </el-option>
              </el-select>
            </div>
            <div class="form-group">
              <span><mark>*</mark>客户名称:</span>
              <el-input class="form-input date-deadline" type="text" v-model="formData.customer"/>
            </div>
            <div class="form-group">
              <span>项目名称:</span>
              <el-input class="form-input date-deadline" type="text" v-model="formData.project"/>
            </div>
            <div class="form-group">
              <span><mark>*</mark>截止日期:</span>
              <el-date-picker
                  v-model="formData.deadline"
                  type="date"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="截止日期">
              </el-date-picker>
            </div>
            <div class="form-group">
              <span><mark>*</mark>描述:</span>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="描述文字长度不超过5000"
                v-model="formData.description">
              </el-input>
            </div>
            <div class="form-group uploader-container">
              <span>附件:</span>
              <el-upload
                  class="avatar-uploader btn-blue"
                  :action="fileActionUrl"
                  :data="fileParams"
                  :show-file-list="false"
                  :on-success="handleFileUploadSuccess"
                  :before-upload="beforeFileUpload">
                <el-button class="btn-blue" type="primary">点击上传</el-button>
              </el-upload>
              <small>word、excel格式的文件，大小不超过10M</small>
              <ul>
                <li v-for="(item, index) in fileList">
                  <div>
                    <template v-if="item.url.length > 0">
                      <i v-if="item.url.indexOf('.XLSX') > 0 || item.url.indexOf('.XLS') > 0"
                         class="icon icon-excel"></i>
                      <i v-if="item.url.indexOf('.DOCX') > 0 || item.url.indexOf('.DOC') > 0"
                         class="icon icon-word"></i>
                    </template>
                    <span class="file-name">{{ item.name }}</span>
                    <i class="file-delete" @click="deleteFile(item)"></i>
                  </div>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div class="error-wrapper" v-show="dialog.hasError">
          <span>*为必填，请填写完整！</span>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="updateTechData" :disabled="isDisabled">提交</el-button>
			    <el-button @click="dialog.dialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>

      <!--详情弹窗-->
      <el-dialog title="项目描述详情" :visible.sync="projectDescDialog.show" width="600px">
        <div class="detail">
          <p>{{ projectDescDialog.content }}</p>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import PermitUserHeader from '../../components/permitUserHeader'
import PageControl from '@/components/PageControl'
import syAxios from '../../../public/static/common/axios.js'
import getConfig from '../../../public/static/common/config.js'

export default {
  data() {
    return {
      userId: '',
      queryId: '',
      pageSize: 10,
      pageNum: 1,
      currentPage: 0,
      statisticsShow: true,
      showDataContainer: false,
      noData: false,
      firstPageShow: false,
      prePageShow: false,
      nextPageShow: false,
      showPageNum: true,
      numPerPage: 10,
      imgLoading: false,
      pageRefer: 'customerList',
      currentEditId: '',
      optionsData: [],
      customerList: [],
      customerListStore: [],
      userRole: '',
      progress: '', //筛选列表（进展）
      projectDescDialog: {
        show: false,
        content: ''
      },
      dialog: {
        dialogVisible: false,
        checkBoxVisible: false,
        saleBoxVisible: false,
        hasError: false
      },
      formData: {
        supportType: '1',
        leaderId: '', //负责人
        participantsIds: '', //参与人员
        salesIds: '', // 销售人员
        deadline: '',
        customer: '',
        project: '',
        description: '',
        technicalSupportId: '', //技术支持id(编辑时有)
      },
      parterList: [],//参与人员
      saleList: [], // 销售列表
      //上传文件相关
      fileActionUrl: '',
      fileParams: {
        userId: '',
        fileName: ''
      },
      fileList: [],
      isDisabled: false,
      parterIdsList: [],
      saleIdsList: []
    }
  },
  components: {
    'permit-header': PermitUserHeader,
    'page-control': PageControl
  },
  computed: {
    participantsIds: function () {
      let result = [];
      let parterList = this.parterList || [];
      for (let i = 0, l = parterList.length; i < l; i++) {
        if (parterList[i].check === true) {
          result.push(parterList[i].id);
        }
      }
      return result.join(',');
    },
    parterNames: function () {
      let result = [];
      let parterList = this.parterList || [];
      for (let i = 0, l = parterList.length; i < l; i++) {
        if (parterList[i].check === true || this.formData.leaderId === parterList[i].id) {
          result.push(parterList[i].name);
        }
      }
      if (result.length === 0) {
        return '请选择参与人员';
      } else {
        return result.join('、');
      }
    },
    salesIds: function () {
      let result = [];
      let saleList = this.saleList || [];
      for (let i = 0, l = saleList.length; i < l; i++) {
        if (saleList[i].check === true) {
          result.push(saleList[i].id);
        }
      }
      return result.join(',');
    },
    saleNames: function () {
      let result = [];
      let saleList = this.saleList || [];
      for (let i = 0, l = saleList.length; i < l; i++) {
        if (saleList[i].check === true) {
          result.push(saleList[i].name);
        }
      }
      if (result.length === 0) {
        return '请选择销售人员';
      } else {
        return result.join('、');
      }
    }
  },
  mounted: function () {
    let that = this;
    that.userId = window.localStorage.getItem('userId') || '';
    that.queryId = this.userId;
    that.initCustomerList(true);
    that.getParterList();
    that.getSalesList();

    let config = getConfig();
    that.fileActionUrl = config.proxyHeader + config.uploadFile;
  },
  methods: {
    removeTag(id) {
      this.parterList.forEach(item => {
        item.id === id ? item.check = false : '';
      })
    },
    removeSaleTag(id) {
      this.saleList.forEach(item => {
        item.id === id ? item.check = false : '';
      })
    },
    selectChange(value, id) {
      if (value) {
        if (this.parterIdsList.indexOf(id) == -1) {
          this.parterIdsList.push(id)
        }
      } else {
        this.parterIdsList.forEach(item => {
          item === id ? this.parterIdsList.splice(this.parterIdsList.indexOf(id), 1) : '';
        })
      }
    },
    selectSaleChange(value, id) {
      if (value) {
        if (this.saleIdsList.indexOf(id) == -1) {
          this.saleIdsList.push(id)
        }
      } else {
        this.saleIdsList.forEach(item => {
          item === id ? this.saleIdsList.splice(this.saleIdsList.indexOf(id), 1) : '';
        })
      }
    },
    hideDialogUnderList() {
      this.dialog.checkBoxVisible = false;
      this.dialog.saleBoxVisible = false;
    },
    initQueryId: function (queryId) {
      this.queryId = queryId;
      this.initCustomerList(true);
    },
    initCustomerList: function (init, numPerPage) {
      let that = this;
      let config = getConfig();
      if (numPerPage) {
        that.pageSize = Number(numPerPage);
        that.numPerPage = that.pageSize;
      }
      let data = {
        progress: that.progress,
        salesmanId: that.queryId,
        pageNum: that.pageNum,
        numPerPage: that.numPerPage
      };

      if (init) {
        data.pageNum = 1;
        that.pageNum = 1;
        that.currentPage = 0;
      }
      that.firstPageShow = false;
      that.prePageShow = false;
      that.nextPageShow = false;
      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.technicalSupport,
        data: data,
        success: function (d) {
          let pageSize = that.pageSize;
          let data = d.data || {},
              data_list = data.userResult || [],
              l = data_list.length;

          let tableData = [];
          if (l > 0) {
            for (let i = 0; i < l; i++) {
              let LaunchDate = data_list[i].LaunchDate || '--',
                  supportType = data_list[i].supportType || '',
                  customer = data_list[i].customer || '--',
                  description = data_list[i].description || '--',
                  project = data_list[i].project || '--',
                  progress = data_list[i].progress === undefined ? '' : data_list[i].progress,
                  finishDate = data_list[i].finishDate || '--',
                  url = data_list[i].url || '',
                  exportUrlList = data_list[i].exportUrlList || [],
                  technicalSupportId = data_list[i].technicalSupportId || '',
                  userId = data_list[i].userId || '';

              tableData.push({
                LaunchDate: LaunchDate,
                supportType: supportType,
                customer: customer,
                project: project,
                description: description,
                progress: progress,
                finishDate: finishDate.replace(/\s+/g, '<br/>'),
                url: url,
                exportUrlList: exportUrlList,
                technicalSupportId: technicalSupportId,
                userId: userId
              })
            }
            that.showDataContainer = true;
            that.noData = false;
          }
          that.customerList = [].concat(tableData);

          if (init) {
            if (l > 0) {
              that.customerListStore = [].concat(tableData);
              if (l === pageSize) {
                that.nextPageShow = true;
                that.pageNum++;
                that.currentPage = that.pageNum - 1;
              }
            } else {
              that.noData = true;
              that.showDataContainer = false;
            }
          } else {
            if (l > 0) {
              that.customerListStore = that.customerListStore.concat(tableData);
              that.firstPageShow = true;
              that.prePageShow = true;
              if (l === pageSize) {
                that.nextPageShow = true;
                that.pageNum++;
                that.currentPage = that.pageNum - 1;
              } else {
                that.nextPageShow = false;
                that.currentPage = that.pageNum;
              }
            } else {
              that.firstPageShow = true;
              that.prePageShow = true;
              that.nextPageShow = false;
              that.currentPage = that.pageNum - 1;
              that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize, that.customerListStore.length);
            }
          }
        }
      })
    },
    renderData: function (data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow, pageRefer) {
      let that = this;

      that.customerList = [].concat(data);
      that.pageNum = pageNum;
      that.currentPage = currentPage;
      that.firstPageShow = firstPageShow;
      that.prePageShow = prePageShow;
      that.nextPageShow = nextPageShow;
      that.pageRefer = pageRefer;
    },
    /**
     * 上传文件
     * */
    handleFileUploadSuccess(res, file) {
      let that = this;
      let data = res.data || {};
      let url = data.card;

      if (typeof url === 'string') {
        that.fileList.push({
          name: file.name || '',
          url: url
        });
      }
    },
    beforeFileUpload(file) {
      this.fileParams.fileName = file.name;
      this.fileUploadCondition(file);
    },
    fileUploadCondition: function (file) {
      file = file || {};
      let isFile = false;
      if (file.type && file.type.length > 0) {
        isFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            || file.type === 'application/vnd.ms-excel'
            || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            || file.type === 'application/msword'
      } else {
        let extend = file.name || '';
        if (extend.indexOf('.') > 0) {
          extend = extend.slice(extend.indexOf('.') + 1);
        }
        extend = extend.toLowerCase();
        if (extend === 'doc' || extend === 'docx' || extend === 'csv' || extend === 'xls' || extend === 'xlsx') {
          isFile = true;
        }
      }

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isFile) {
        alert('上传文件为 word、excel格式!');
      }
      if (!isLt2M) {
        alert('上传文件大小不能超过 10M!');
      }

      return isFile && isLt2M;
    },
    /**
     * 删除文件
     * */
    deleteFile: function (file) {
      let that = this;
      let fileList = that.fileList || [];
      for (let i = 0, l = fileList.length; i < l; i++) {
        if (fileList[i].url === file.url) {
          fileList.splice(i, 1);
          l = fileList.length;
        }
      }
    },
    showProjectDesc(content) {
      this.projectDescDialog.content = content || '--';
      this.projectDescDialog.show = true;
    },
    /**
     * 下载文件
     * */
    loadFile: function (file) {
      window.open(file.url)
    },

    /**
     * 添加技术支持按钮
     * */
    addTechEvent: function () {
      let that = this;
      let parterList = that.parterList || [];
      for (let i = 0, l = parterList.length; i < l; i++) {
        parterList[i].check = false;
      }

      that.clearSaleCheck();

      let formData = that.formData || {};
      for (let key in formData) {
        if (key === 'supportType') {
          formData[key] = '1';
        } else {
          formData[key] = '';
        }
      }
      that.fileList = [];
      that.dialog.dialogVisible = true;
      that.dialog.checkBoxVisible = false;
      that.dialog.saleBoxVisible = false;
    },
    /**
     * 编辑技术支持按钮
     * */
    editTechEvent: function (id) {
      let that = this;
      let config = getConfig();
      let data = {
        technicalSupportId: id
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.technicalSupport,
        data: data,
        success: function (d) {
          let data = d.data || {};
          data = data.technicalSupportResult || [];
          if (data.length > 0) {
            data = data[0];
            let formData = that.formData;
            formData.supportType = data.supportType || '1';
            formData.supportType += '';
            formData.leaderId = data.leaderId || '';
            formData.participantsIds = data.participantsIds || '';
            formData.salesIds = data.salesId || '';
            formData.deadline = data.deadline || '';
            formData.customer = data.customer || '';
            formData.project = data.project || '';
            formData.description = data.description || '';
            formData.technicalSupportId = data.technicalSupportId || '';

            that.fileList = data.urlList || [];

            let parterList = that.parterList || [];
            for (let i = 0, l = parterList.length; i < l; i++) {
              parterList[i].check = false;
            }

            let participantsIds = formData.participantsIds;
            if (participantsIds.length > 0) {
              participantsIds = participantsIds.split(',');
              for (let i = 0, l = participantsIds.length; i < l; i++) {
                for (let ii = 0, ll = parterList.length; ii < ll; ii++) {
                  if (participantsIds[i] === parterList[ii].id) {
                    parterList[ii].check = true;
                  }
                }
              }
            }

            let salesIds = formData.salesIds;
            if (salesIds.length > 0) {
              let saleList = that.saleList || [];
              salesIds = salesIds.split(',');
              for (let i = 0, l = salesIds.length; i < l; i++) {
                for (let ii = 0, ll = saleList.length; ii < ll; ii++) {
                  if (salesIds[i] === saleList[ii].id) {
                    saleList[ii].check = true;
                  }
                }
              }
            }

            that.dialog.checkBoxVisible = false;
            that.dialog.saleBoxVisible = false;
            that.dialog.dialogVisible = true;
          }
        }
      })
    },
    checkLeader: function () {
      let that = this;
      let leaderId = that.formData.leaderId;
      let parterList = that.parterList || [];
      for (let i = 0, l = parterList.length; i < l; i++) {
        if (parterList[i].id === leaderId) {
          parterList[i].check = true;
          this.selectChange(parterList[i].check, leaderId);
        }
      }
    },
    /**
     * 添加、编辑技术支持
     * */
    updateTechData: function () {
      let that = this;
      let config = getConfig();
      let formData = that.formData || {};

      if (formData.customer.trim().length === 0
          || formData.leaderId === ''
          || that.participantsIds === ''
          || (that.salesIds === '' && formData.supportType === '4')
          || formData.deadline === '' || formData.deadline === 'null'
          || formData.description.trim().length === 0) {
        that.dialog.hasError = true;
        setTimeout(function () {
          that.dialog.hasError = false;
        }, 3000);
      } else {
        that.dialog.hasError = false;
        that.isDisabled = true;

        let fileList = that.fileList || [], fileUrls = [];
        if (fileList.length > 0) {
          for (let i = 0, l = fileList.length; i < l; i++) {
            fileUrls.push({
              name: fileList[i].name || '',
              url: fileList[i].url || ''
            });
          }
        }

        let data = {
          supportType: formData.supportType,
          customer: encodeURIComponent(formData.customer.trim()),
          leaderId: formData.leaderId,
          participantsIds: that.participantsIds,
          salesIds: that.salesIds,
          project: encodeURIComponent(formData.project.trim()),
          deadline: formData.deadline,
          description: encodeURIComponent(formData.description.trim().slice(0, 5000)),
          url: encodeURIComponent(JSON.stringify(fileUrls)),
          technicalSupportId: formData.technicalSupportId
        };

        syAxios({
          ifProxy: true,
          url: config.index.customerTechnology.addTechnicalSupport,
          data: data,
          success: function (d) {
            let data = d.data || {};
            if (data.success) {
              that.initCustomerList(true);
              that.dialog.dialogVisible = false;
            }
          },
          always: function () {
            that.isDisabled = false;
          }
        })
      }
    },
    toggleCheckBoxShow: function () {
      this.dialog.saleBoxVisible = false;
      this.dialog.checkBoxVisible = !this.dialog.checkBoxVisible;
    },
    // 获取参与人员列表
    getParterList: function () {
      let that = this;
      let config = getConfig();
      let data = {
        userId: that.userId
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.queryTechnicalPerson,
        data: data,
        success: function (d) {
          let data = d.data || {};
          data = data.data_list || [];
          if (data.length > 0) {
            for (let i = 0, l = data.length; i < l; i++) {
              that.parterList.push({
                name: data[i].name || '',
                id: data[i].userId || '',
                check: false
              })
            }
          }
        }
      })
    },
    checkParter: function (item) {
      let that = this;
      let parterList = that.parterList || [];
      for (let i = 0, l = parterList.length; i < l; i++) {
        if (parterList[i].id === item.id && item.id !== that.formData.leaderId) {
          parterList[i].check = !parterList[i].check;
        }
      }
    },
    // 获取销售人员列表
    getSalesList: function () {
      let that = this;
      let config = getConfig();
      let data = {
        userId: that.userId
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.getSalesList,
        data: data,
        success: function (d) {
          let data = d.data || {};
          data = data.data_list || [];
          if (data.length > 0) {
            for (let i = 0, l = data.length; i < l; i++) {
              that.saleList.push({
                name: data[i].name || '',
                id: data[i].userId || '',
                check: false
              })
            }
          }
        }
      })
    },
    // 选中销售人员
    checkSale: function (item) {
      let that = this;
      let saleList = that.saleList || [];
      for (let i = 0, l = saleList.length; i < l; i++) {
        if (saleList[i].id === item.id) {
          saleList[i].check = !saleList[i].check;
        }
      }
    },
    clearSaleCheck: function () {
      let saleList = this.saleList || [];
      for (let i = 0, l = saleList.length; i < l; i++) {
        saleList[i].check = false;
      }
    },
    toggleSaleBoxShow: function () {
      this.dialog.checkBoxVisible = false;
      this.dialog.saleBoxVisible = !this.dialog.saleBoxVisible;
    },
    /**
     * 更改状态
     * */
    updateTechStatus: function (item) {
      let that = this;
      let config = getConfig();
      let data = {
        progress: 0,
        technicalSupportId: item.technicalSupportId
      };

      syAxios({
        ifProxy: true,
        url: config.index.customerTechnology.updateTechnicalSupportProgress,
        data: data,
        success: function (d) {
          let data = d.data || {};
          if (data.success) {
            that.initCustomerList(true);
          }
        }
      })
    },
  }
}
</script>
