<template>
	<header class="apply-for-header">
		<div class="header-title" v-show="permitUser">
			<span>管理员筛选</span>
      <form style="display: inline-block;margin-left: 5px;">
        <el-select class="form-select" v-if="isMemberModel" v-model="seeyiiUserId" @change="queryUser2(seeyiiUserId)">
          <el-option value="all" label="全部">全部</el-option>
          <el-option v-for="item in permitUserList" :key="item.seeyiiUserId" :value="item.seeyiiUserId" :label="item.name" :selected="isCurrentUser">{{item.name}}</el-option>
        </el-select>

        <el-select class="form-select" v-else v-model="queryId" @change="queryUser(queryId)">
          <el-option value="all" label="全部">全部</el-option>
          <el-option v-for="item in permitUserList" :key="item.userId" :value="item.userId" :label="item.name">{{item.name}}</el-option>
        </el-select>
      </form>
		</div>
	</header>
</template>

<script>
import syAxios from '../../public/static/common/axios.js'
import getConfig from '../../public/static/common/config.js'

export default{
  data () {
    return {
      queryId: 'all',
      seeyiiUserId: 'all',
      permitUser: false,
      isCurrentUser: false,
      permitUserList: []
    }
  },
  props: ['customerList', 'companyNum', 'customerNum', 'isMemberModel'],
  components: {},
  mounted: function () {
    this.getPermitUser()
  },
  methods: {
    getPermitUser: function () {
      var userId = window.localStorage.getItem('userId') || ''
      var that = this
      var config = getConfig()

      syAxios({
        ifProxy: true,
        url: config.permitUser,
        success: function (d) {
          var data = d.data || {},
            data_list = data.data_list || []
          if (data_list.length > 0) {
            that.permitUserList = data_list
            that.permitUser = true
            for (var i = 0, l = data_list.length; i < l; i++) {
              if (data_list[i].userId === userId) {
                that.queryId = userId
                that.seeyiiUserId = data_list[i].seeyiiUserId
                that.isCurrentUser = true
              } else {
                that.isCurrentUser = false
              }
            }
          }
        }
      })
    },
    queryUser: function (queryId) {
      let that = this
      that.queryId = queryId
      that.$emit('getQueryId', queryId)
    },
    queryUser2: function (seeyiiUserId) {
      let that = this
      that.seeyiiUserId = seeyiiUserId
      that.$emit('getQueryId', seeyiiUserId)
    }
  }
}
</script>

<style scoped lang="less">
	.apply-for-header{
		background: #fff;
	}
	.apply-for-header>.header-title{
		padding: 10px;
		font-size: 16px;
	}
	.apply-for-header>.customerList{
		padding: 10px;
	}
  .apply-for-header>.customerList select{
    min-width: 100px;
  }
	.apply-for-header>form{
		padding: 5px 10px 0 10px;
	}
	.apply-for-header select{
		height: 26px;
		border: 1px solid #ccc;
		outline: none;
	}
  .form-select {
    width: 100px;
    margin-left: 10px;
    /deep/ input {
      width: 100%;
    }
  }
</style>
