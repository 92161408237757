<template>
  <el-container>
    <el-container>
      <el-header>
        <el-aside width="180px">
          <h2><span>SEEYII销售管理系统</span></h2>
        </el-aside>
        <div>
          <span>你好，管理员{{ userName }}!</span>
          <a href="javascript:void(0)" @click="feedback()"><i class="modify"></i><span>使用反馈</span></a>
          <span class="split">|</span>
          <a href="javascript:void(0)" @click="modifyPassword()"><i class="modify"></i><span>修改密码</span></a>
          <span class="split">|</span>
          <a href="javascript:void(0)" @click="logOut()"><i class="quit"></i><span>退出</span></a>
        </div>
      </el-header>
      <el-main>
        <block-component></block-component>
      </el-main>
    </el-container>

    <!--退出-->
    <div class="dialog-container">
      <el-dialog title="提示" :visible.sync="dialogLogOutVisible" width="300px">
        <span>确定要退出吗？</span>
        <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogLogOutVisible = false">取 消</el-button>
			    <el-button type="primary" @click="logOutSure()">确 定</el-button>
			  </span>
      </el-dialog>

      <el-dialog title="使用反馈" :visible.sync="dialogFeedBackVisible">
        <div class="feedback-content">
          <textarea v-model="feedbackContent"></textarea>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFeedBackVisible = false">取 消</el-button>
          <el-button type="primary" @click="feedbackSubmit()">确 定</el-button>
        </div>
      </el-dialog>
    </div>

  </el-container>
</template>

<script>
import getConfig from "../../../public/static/common/config";
import syAxios from "../../../public/static/common/axios";
import BlockComponent from './block.component';

export default {
  name: "checkList",
  components: {
    BlockComponent
  },
  data() {
    return {
      userName: '',
      feedbackContent: '',
      dialogFeedBackVisible: false,
      dialogLogOutVisible: false,
      localStorageName: ''
    }
  },
  created() {
    this.userName = window.localStorage.getItem('name') || '';
    this.localStorageName = 'checkTableData' + this.$route.query.date || '';
  },
  destroyed() {
    window.localStorage.removeItem(this.localStorageName);
  },
  methods: {
    feedback() {
      this.feedbackContent = '';
      this.dialogFeedBackVisible = true;
    },
    feedbackSubmit() {
      var that = this;
      var config = getConfig();
      var data = {
        content: encodeURIComponent(that.feedbackContent)
      };

      syAxios({
        ifProxy: true,
        url: config.login.feedback,
        data: data,
        success: function (d) {
          that.dialogFeedBackVisible = false;
          alert('使用反馈已提交!');
        },
        error: function (d) {
          that.dialogFeedBackVisible = false;
        }
      })
    },
    modifyPassword() {
      this.$router.push({
        name: 'modify'
      })
    },
    logOut() {
      this.dialogLogOutVisible = true;
    },
    logOutSure() {
      var that = this;
      var config = getConfig();

      syAxios({
        ifProxy: false,
        url: config.proxyHeader + config.login.offline,
        success: function (d) {
          that.dialogLogOutVisible = false;
          localStorage.clear();
          //跳转登录页
          that.$router.push({
            name: 'login'
          })
        },
        error: function (d) {
          that.dialogLogOutVisible = false;
          localStorage.clear();
          //跳转登录页
          that.$router.push({
            name: 'login'
          })
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.el-container {
  height: 100%;
}

.el-aside {
  height: 100%;
  background: #3f3f3f;
  border-right: 10px solid #03a6c2;
}

.el-aside > h2 {
  height: 60px;
  line-height: 60px;
  background: #03a6c2;
  color: #fff;
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.el-menu {
  background: #3f3f3f;
  border-right: none;
  border-bottom: 1px solid #4a4a4a;
}

.el-menu-item {
  border: 1px solid #4a4a4a;
  border-bottom: 1px solid #242424;
  color: #f5f5f5;
}

.el-menu-item:nth-child(1) {
  text-align: center;
}

.el-menu-item:hover,
.el-menu-item:focus {
  background: #03a6c2;
  border-right: none;
}

.el-menu-item.is-active {
  background: #03a6c2;
  border-right: none;
}

.nav-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.el-header {
  height: 60px;
  line-height: 60px;
  background: #14B8D4;
  color: #fff;
  position: relative;
  padding: 0;
}

.el-header > div {
  height: 60px;
  position: absolute;
  right: 20px;
  top: 0;
}

.el-header > div > span {
  display: inline-block;
  font-size: 18px;
  margin-right: 30px;
}

.el-header > div > span.split {
  color: #14B8D4;
  margin-right: 0px;
}

.el-header > div > a {
  display: inline-block;
  height: 60px;
  padding: 0 24px;
  text-align: center;
  line-height: 62px;
  background: #03a6c2;
  color: #fff;
  margin-right: -13px;
}

.el-header > div > a i {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-bottom: -5px;
}

i.modify {
  background: url("../../assets/modify.png") no-repeat;
}

i.quit {
  background: url("../../assets/quit.png") no-repeat;
}


.el-main {
  background: #f5f8fa;
  padding: 10px 10px 0 10px;
}

.el-button {
  background: #fff;
  border: 1px solid #14B8D4;
  border-radius: 10px;
  color: #14B8D4;
  font-weight: 600;
  padding: 6px 16px;
}

.feedback-content {
  height: 280px;
  padding: 10px;
}

.feedback-content > textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  outline: none;
}
</style>