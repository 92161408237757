<template>
  <div class="companySearch" @click.stop="showOpen">
    <el-popover
      trigger="manual"
      :value="popoverShow"
      :visible-arrow="false"
      popper-class="companyPopper"
      ref="popover"
      width="320"
    >
      <div
        class="popper--list"
        v-infinite-scroll="scrollLoad"
        :infinite-scroll-delay="300"
        infinite-scroll-disabled="disabled"
      >
        <div
          class="popper--item"
          v-for="(item, keys) in popperList"
          :key="keys"
          @click="handleItem(item)"
        >
          <span
            class="name"
            v-html="lightHight(item.cname)"
          ></span>
        </div>
      </div>
    </el-popover>
    <el-input
      v-if="companyShow"
      v-popover:popover
      v-model="tempValue"
      @keydown.enter.native="handleSearch"
      @input="handleInput"
      @blur="handleBlur"
      placeholder="请输入关键字"
      prefix-icon="el-icon-search"
    >
    </el-input>
    <el-input
      v-else
      v-model="tempValue"
      @input="handleBlur"
      @blur="handleBlur"
      placeholder="请输入关键字"
      prefix-icon="el-icon-search"
    >
    </el-input>
  </div>
</template>

<script>
let debounce = function (fn, delay) {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};
let that;
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    companyName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    companyShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    that = this;
    return {
      popoverShow: false,
      value: '',
      tempValue: '',
      popperList: [],
      pageNum: 1,
      count: 0,
      loading: false,
      show: false
    };
  },
  computed: {
    noMore() {
      return this.count >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val.length > 0) {
          if (this.pageNum == 1) {
            this.count = val.length;
            this.popperList = val;
          } else {
            this.count += val.length;
            this.popperList = this.popperList.concat(val);
          }
          this.popoverShow = true;
        } else {
          if (this.pageNum == 1) {
            this.count = 0;
            this.popperList = [];
            this.popoverShow = false;
          }
        }
      },
      immediate: true
    },
    companyName(val, oldVal) {
      if (val != oldVal) {
        this.tempValue = val || '';
      }
    }
  },
  mounted() {
    this.$VUtils.addEvent(
      document.querySelector('body'),
      'click',
      this.showClose
    );
    this.tempValue = this.companyName || '';
  },
  methods: {
    showOpen() {
      if (this.popperList.length > 0) {
        this.popoverShow = true;
      }
    },
    showClose() {
      this.popoverShow = false;
    },
    handleInput: debounce(() => {
      that.handleSearch();
    }, 300),
    handleBlur: debounce(() => {
      that.$emit('blur', that.tempValue);
    }, 300),
    handleSearch() {
      this.value = this.tempValue.trim();
      if (this.value != '') {
        this.pageNum = 1;
        this.$emit('search', this.pageNum, this.value);
      } else {
        this.pageNum = 1;
        this.count = 0;
        this.popperList = [];
        this.popoverShow = false;
      }
    },
    lightHight(data) {
      return this.$VApi.setHighlightString(data, this.value);
    },
    scrollLoad() {
      if (this.value != '') {
        this.loading = true;
        this.pageNum += 1;
        this.$emit('scrollLoad', this.pageNum, this.value);
        this.loading = false;
      }
    },
    handleItem(item) {
      this.$emit('itemClick', item);
      this.tempValue = item.cname || '';
      this.popoverShow = false;
    }
  }
};
</script>
<style>
.companyPopper {
  padding-right: 0;
}
.companyPopper.el-popper[x-placement^='top'],
.companyPopper.el-popper[x-placement^='bottom'] {
  margin: 0;
  padding: 0;
}
</style>
<style lang="less" scoped>
@import '../../../theme/var/var.less';
.popper--list {
  height: 286px;
  overflow-y: auto;
}
.popper--item {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: space-between;
  padding: 10px 13px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 0.5);
  }
  .name {
    flex: 1;
    color: @color-333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .diabled-name {
    color: #dddddd;
  }
  .type {
    width: 56px;
    height: 20px;
    background: url('../../../assets/type.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
  }
}
/deep/ .el-input {
  width: 100%;
  input {
    width: 100%;
    border-radius: 4px;
  }
}
/deep/ .high-light{
  font-weight: 600;
  color: @color-primary;
}
</style>
