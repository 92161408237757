const getConfig = function () {

    let urlHeader = '/sale_proxy';

    return {
        proxyHeader: urlHeader + '/manageInter',//登录注销等
        proxyURL: urlHeader + '/proxyInter/httpProxyInter.dhtml',//代理
        proxyBidding: urlHeader + '/proxyInter/biddingForwardGatewayProxyInter.dhtml', //代理转发招采数据接口接口

        imgUrlHeader: 'https://pic.seeyii.com/',//图片地址头
        importMould: '/importMouldManageInter.dhtml',//导入
        importMouldBidManageInter: urlHeader + '/manageInter/importMouldBidManageInter.dhtml', //提交公司名称
        importMouldBond: '/importMouldBondManageInter.dhtml', //导入（风险）
        uploadMould: '/uploadApplyCardManageInter.dhtml',//上传
        uploadFile: '/uploadApplyDocumentManageInter.dhtml', //技术支持部分上传文件
        loadFile: '/sale_proxy/manageInter/downloadFileManageInter.dhtml', //技术支持部分下载文件接口
        permitUser: '/sale_manage/applyInter/permitUserApplyInter.dhtml',//允许查看的用户列表
        updateTechnicalSupportUrl: '/sale_manage/applyInter/queryUserMenuApplyInter.dhtml', //查询人员菜单权限接口
        login: {
            login: '/loginManageInter.dhtml',//登录
            offline: '/offlineManageInter.dhtml',//注销
            sendVerificationCode: '/sendVerificationCodeManageInter.dhtml',//获取验证码
            updatePassword: '/sale_manage/manageInter/updatePasswordManageInter.dhtml',//修改用户密码
            feedback: '/sale_manage/applyInter/feedbackApplyInter.dhtml',//使用反馈
        },
        index: {
            applyFor: {
                applyRoster: '/sale_manage/applyInter/applyRosterApplyInter.dhtml',//申请试用列表
                addApplyCustomer: '/sale_manage/applyInter/addApplyCustomerApplyInter.dhtml',//添加单个申请试用
                updateApplyCustomer: '/sale_manage/applyInter/updateApplyCustomerApplyInter.dhtml',//修改申请信息
                cancelApply: '/sale_manage/applyInter/cancelApplyApplyInter.dhtml',//取消申请试用
                uploadApplyCard: '/sale_manage/applyInter/uploadApplyCardApplyInter.dhtml',//上传名片
                updateApplyCard: '/sale_manage/applyInter/updateApplyCardApplyInter.dhtml',//修改名片
                applyPostponeRoster: '/sale_manage/applyInter/applyPostponeRosterApplyInter.dhtml',//申请延期客户列表

                applyRosterBond: '/sale_manage/bondapplyInter/applyRosterBondapplyInter.dhtml',//申请试用列表(风险)
                addApplyCustomerBond: '/sale_manage/bondapplyInter/addApplyCustomerBondapplyInter.dhtml',//添加单个申请试用(风险)
                updateApplyCustomerBond: '/sale_manage/bondapplyInter/updateApplyCustomerBondapplyInter.dhtml',//修改申请信息(风险)
                cancelApplyBond: '/sale_manage/bondapplyInter/cancelApplyBondapplyInter.dhtml',//取消申请试用(风险)
                uploadApplyCardBond: '/sale_manage/bondapplyInter/uploadApplyCardBondapplyInter.dhtml',//上传名片(风险)
                updateApplyCardBond: '/sale_manage/bondapplyInter/updateApplyCardBondapplyInter.dhtml',//修改名片(风险)
                applyPostponeRosterBond: '/sale_manage/bondapplyInter/applyPostponeRosterBondapplyInter.dhtml',//申请延期客户列表(风险)
                searcheTip: '/ms/search/tip', // 采购方顶部搜索
                searcheTipUrl: urlHeader + '/proxyInter/forwardGatewayProxyInter.dhtml'
            },
            customerList: {
                customerRoster: '/sale_manage/applyInter/customerRosterApplyInter.dhtml',//客户列表
                applyPostpone: '/sale_manage/applyInter/applyPostponeApplyInter.dhtml',//申请延期
                batchApplyPostpone: '/sale_manage/applyInter/batchApplyPostponeApplyInter.dhtml',//批量申请延期

                customerRosterBond: '/sale_manage/bondapplyInter/customerRosterBondapplyInter.dhtml',//客户列表(风险)
                applyPostponeBond: '/sale_manage/bondapplyInter/applyPostponeBondapplyInter.dhtml',//申请延期(风险)
                batchApplyPostponeBond: '/sale_manage/bondapplyInter/batchApplyPostponeBondapplyInter.dhtml',//批量申请延期(风险)
            },
            customerStatistics: {
                customerUseStatisticsChart: '/sale_manage/applyInter/customerUseStatisticsChartApplyInter.dhtml',//客户总数图表
                useStatisticsChart: '/sale_manage/applyInter/useStatisticsChartApplyInter.dhtml',//用户使用情况统计图表
                customerUseStatistics: '/sale_manage/applyInter/customerUseStatisticsApplyInter.dhtml',//客户使用情况统计列表
                preWeekDate: '/sale_manage/applyInter/preWeekDateApplyInter.dhtml',//过去几周列表
            },
            customerReminder: {
                expireRemind: '/sale_manage/applyInter/customerRemindApplyInter.dhtml',//到期提醒列表
                smsWarning: '/sale_manage/applyInter/smsWarningApplyInter.dhtml',//发送短信提醒(手动、自动)
                smsWarningBond: '/sale_manage/bondapplyInter/smsWarningBondapplyInter.dhtml',//发送短信提醒(手动、自动)
                expireRemindBond: '/sale_manage/bondapplyInter/customerRemindBondapplyInter.dhtml',//到期提醒列表(风险)
            },
            customerQuery: {
                userVisitQueryStatistics: '/sale_manage/applyInter/userVisitQueryStatisticsApplyInter.dhtml',//客户查询
            },
            customerWarning: {
                warningStatistics: '/sale_manage/applyInter/warningStatisticsApplyInter.dhtml',//客户列表
                warningStatisticsBond: '/sale_manage/bondapplyInter/warningStatisticsBondapplyInter.dhtml',//客户列表（风险）
            },
            customerMember: {
                teamStatisticsChart: '/sale_manage/applyInter/teamStatisticsChartApplyInter.dhtml',//销售经理使用情况统计图表
                teamUseStatistics: '/sale_manage/applyInter/teamUseStatisticsApplyInter.dhtml',//销售经理使用情况统计列表
            },
            customerTechnology: {
                queryTechnicalPerson: '/sale_manage/applyInter/queryTechnicalPersonApplyInter.dhtml', //查询技术人员接口
                getSalesList: '/sale_manage/applyInter/saleListApplyInter.dhtml', // 获取销售人员列表
                technicalSupport: '/sale_manage/applyInter/technicalSupportApplyInter.dhtml', //技术支持列表接口（销售端展示，销售端编辑展示，技术端展示）
                addTechnicalSupport: '/sale_manage/applyInter/addTechnicalSupportApplyInter.dhtml', //添加/修改   技术支持接口
                updateTechnicalSupportProgress: '/sale_manage/applyInter/updateTechnicalSupportProgressApplyInter.dhtml', //技术支持更改状态接口（撤销,导出中 ,已导出  ,主管拒绝,技术拒绝）
                technicalSupportComplete: '/sale_manage/applyInter/technicalSupportCompleteApplyInter.dhtml', //技术支持确认已完成接口
                updateTechnicalSupportUrl: '/sale_manage/applyInter/updateTechnicalSupportUrlApplyInter.dhtml', //技术支持更新上传文件接口
            }
        },
        isReduce: 1,
        pt: 3,
        devType: 'h5',
        ver: 'v1.0.0',
        platform: 'bidding'
    }
};

export default getConfig
