/* 封装通用的方法 */

/**
 * 高亮显示
 * @params : string
 * @return : data
 */
const setHighlightString = (str, rules) => {
  rules = rules || '';
  let subArr = rules.toString().split(',');
  subArr.sort((x, y) => {
    return x.length > y.length ? -1 : 1;
  });

  let temp = [str];
  while (subArr.length > 0) {
    let curKeyword = subArr.shift();
    if (curKeyword.length === 0) {
      continue;
    }
    let arr = [];
    for (let i = 0, l = temp.length; i < l; i++) {
      let cur = temp[i];
      if (typeof cur === 'string') {
        let tempIndex = cur.indexOf(curKeyword);
        while (tempIndex >= 0) {
          if (tempIndex > 0) {
            arr.push(cur.slice(0, tempIndex));
          }
          arr.push({
            text: cur.slice(tempIndex, tempIndex + curKeyword.length),
            match: true
          });
          cur = cur.slice(tempIndex + curKeyword.length);
          tempIndex = cur.indexOf(curKeyword);
        }
        if (cur.length > 0) {
          arr.push(cur);
        }
      } else {
        arr.push(cur);
      }
    }
    temp = [].concat(arr);
  }

  for (let i = 0, l = temp.length; i < l; i++) {
    if (typeof temp[i] === 'object') {
      temp[i] = '<strong class="high-light">' + temp[i].text + '</strong>';
    }
  }
  return temp.join('');
};

export default {
  setHighlightString
};
