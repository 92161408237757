import Utils from './common.js'
import getConfig from './config.js'
import axios from 'axios'
//import Router from 'vue-router'
import router from '../../../src/router/index.js'

const syAxios = function (options) {
    if (options) {
        var datas = options.data || {};
        var config = getConfig();

        datas.pt = config.pt;
        datas.ver = config.ver;
        datas.devType = config.devType;
        datas.platForm = config.platform;
        datas.timestamp = new Date().getTime();

        if (options.ifProxy) {
            if (datas && datas.jsonObject) {
                datas = Utils.JSONtoParamsStr(Utils.createAjaxData(datas));
                datas = Utils.createAjaxData({
                    url: options.url || '',
                    params: datas,
                    jsonObject: options.data.jsonObject,
                    tab: options.data.tab || ''
                })
                for (let i in datas) {
                    datas[i] = encodeURIComponent(datas[i])
                }
                datas = Utils.JSONtoParamsStr(datas)
            } else {
                datas = Utils.JSONtoParamsStr(Utils.createAjaxData(datas));
                datas = Utils.JSONtoParamsStr(Utils.createAjaxData({
                    url: options.url,
                    params: encodeURIComponent(datas),
                }))
            }

            //拼接请求数据接口的代理接口的URL
            let proxyType = config.proxyURL;
            if (options.url && options.url.indexOf('bidding/') > -1) {
                proxyType = config.proxyBidding;
            } else if (options.url && options.url.indexOf('/ms/search/tip') > -1) {
                proxyType = config.index.applyFor.searcheTipUrl;
            } else if(options.data && options.data.jsonObject && options.data.jsonObject != '') {
                proxyType = config.importMouldBidManageInter;
            } else {
                proxyType = config.proxyURL;
            }
            axios({
                method: 'post',
                url: proxyType,
                data: datas,
            }).then(function (response) {
                var data = response.data;
                var msg = data.msg || {};
                if (msg.code + '' === '1' || data.code + '' === '200') {
                    if (typeof options.success === 'function') {
                        options.success.call({}, data);
                    }
                } else if (msg.code + '' === '10003') {
                    router.replace({
                        path: '/'
                    })
                } else {
                    if (typeof options.error === 'function') {
                        options.error.call({}, data);
                    }
                }
                if (typeof options.always === 'function') {
                    options.always.call({}, response);
                }
            }).catch(function (error) {
                if (error.response && error.response.status + '' === '801') {
                    router.replace({
                        path: '/'
                    })
                }
                if (typeof options.always === 'function') {
                    options.always.call({}, error.response);
                }
            })
        } else {
            axios({
                method: 'post',
                url: options.url,
                data: Utils.JSONtoParamsStr(Utils.createAjaxData(datas)),
            }).then(function (response) {
                var data = response.data;
                var msg = data.msg || {};
                if (msg.code + '' === '1') {
                    if (typeof options.success === 'function') {
                        options.success.call({}, data);
                    }
                } else if (msg.code + '' === '10003') {
                    router.replace({
                        path: '/'
                    })
                } else {
                    if (typeof options.error === 'function') {
                        options.error.call({}, data);
                    }
                }

                if (typeof options.always === 'function') {
                    options.always.call({}, response);
                }
            }).catch(function (error) {
                if (error.response && error.response.status + '' === '801') {
                    router.replace({
                        path: '/'
                    })
                }
                if (typeof options.always === 'function') {
                    options.always.call({}, error.response);
                }
            })
        }


        //添加响应拦截器
//		808 加密串错误
//		10003 登录过期
//		axios.interceptors.response.use(function(response){
//			console.log(response)
//			var userId = window.localStorage.getItem('userId') ||'';
//			if(userId + '' === '' || response.data.msg + '' === '10003' || response.data.msg + '' === '808'){
//				router.replace({
//					path : '/'
//				})
//			}

//		    return response;
//		},function(error){
////			console.log(error)
//		 	return Promise.reject(error);
//		});

//		axios.interceptors.request.use(function(config){
//			console.log(config)
////			if(config.data.msg + '' === '10003' || config.data.msg + '' === '808'){
////				router.replace({
////					path : '/'
////				})
////			}
//		},function(error){
////			console.log(error)
//		 	return Promise.reject(error);
//		});

    }
}
export default syAxios

