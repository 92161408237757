<template>
	<div class="loginPage">
		<canvas class="bg-canvas"></canvas>
		<header class="header">
			<h2>
				<img src="../../assets/login/logo.png" />
			</h2>
		</header>
		<section class="main">
			<div class="clear-fix">
				<div class="form-left">
					<img src="../../assets/login/form-bg-left.png" />
				</div>
				<div class="form-right">
					<form @keyup.enter="login()">
						<div class="form-group">
							<img src="../../assets/login/user.png" />
							<input v-model="userName" type="text" placeholder="请输入您的账号" />
						</div>
						<div class="form-group">
							<img src="../../assets/login/password.png" />
							<input v-model="passWord" type="password" placeholder="请输入密码" />
						</div>
						<div class="code">
							<div class="form-group">
								<img src="../../assets/login/code.png" />
								<input v-model="code" type="text" placeholder="验证码" />
							</div>
							<a href="javascript:void(0)" @click="getCode()" v-show="!getCodeAgain">获取验证码</a>
							<a class="getCode disabled" href="javascript:void(0)" v-show="getCodeAgain">{{seconds}}秒后获取</a>
						</div>
						<div class="loginBtn"><a @click="ifRequest === false ? login() : ''">登录</a></div>
					</form>
				</div>
			</div>
		</section>
		<footer class="footer">
			<p>
				<span>版权所有 </span>
				<span> 北京视野智慧数字科技有限公司</span>
			</p>
		</footer>
	</div>
</template>

<script>
	import Utils from '../../../public/static/common/common.js'
	import syAxios from '../../../public/static/common/axios.js'
	import getConfig from '../../../public/static/common/config.js'

	export default{
		name : 'Login',
		data(){
			return {
				userId : '',
				userName : '',
				passWord : '',
				getCodeAgain : false,
				seconds : '',
				code : '',
				screenWidth : document.documentElement.clientWidth || document.body.clientWidth,
				ifRequest : false
			}
		},
		components : {},
		mounted: function() {
			var that = this;
			that.drawLines(document.querySelector('.loginPage'));

			that.setMainHeight();

			window.onresize = function(){
				window.screenWidth = document.documentElement.clientWidth || document.body.clientWidth
                that.screenWidth = window.screenWidth
                that.drawLines(document.querySelector('.loginPage'))
                that.setMainHeight()
	        }
		},
		watch : {
			screenWidth (val) {
				if(!this.timer){
					var that = this;
					this.timer = true;
					setTimeout(function(){
						that.drawLines(document.querySelector('.loginPage'));
						this.timer = false;
					},400);
				}
			}
		},
		methods : {
			volidateDatas : function(value,type){
				if(type === 'user'){
					var reg = /[a-zA-Z0-9]/g;
					if(value.trim() === ''){
						alert('请输入账号！');
						return false;
					}else if(!reg.test(value.trim())){
						alert('账号输入有误！');
						return false;
					}else{
						return true;
					}
				}
				if(type === 'passWord'){
					var reg = /[a-zA-Z0-9_@]/g;
					if(value.trim() === ''){
						alert('请输入密码！');
						return false;
					}else if(!reg.test(value.trim())){
						alert('密码格式有误！');
						return false;
					}else{
						return true;
					}
				}
				if(type === 'code'){
					var reg = /[a-zA-Z0-9_@]/g;
					if(value.trim() === ''){
						alert('请输入验证码！');
						return false;
					}else if(!reg.test(value.trim())){
						alert('验证码有误！');
						return false;
					}else{
						return true;
					}
				}
			},
			getCode : function(){
				var that = this;
				var config = getConfig();

				if(that.volidateDatas(that.userName,'user') && that.volidateDatas(that.passWord,'passWord')){
					var data = {
					    loginName : encodeURIComponent(that.userName.trim()),
					    password : Utils.CryptoJS.MD5(that.passWord).toString().toUpperCase(),
					};
					syAxios({
						ifProxy : false,
						url : config.proxyHeader + config.login.sendVerificationCode,
						data : data,
						success : function(d){
							alert("验证码已发送到手机！");
							that.seconds = 60;
							that.getCodeAgain = true;
							var timer = setInterval(function(){
								that.seconds--;
								if(that.seconds <= 0){
									that.seconds = 60;
									that.getCodeAgain = false;
									clearInterval(timer);
								}
							},1000);
						},
						error : function(d){
							if(d.msg && d.msg.code + '' === '8888'){
								alert(d.msg.text);
							}else if(d.msg && d.msg.code + '' === '888'){
								alert(d.msg.text);
							}
						}
					})
				}
			},
			login : function(){
				var that = this;
				var config = getConfig();

				if(that.volidateDatas(that.userName,'user') && that.volidateDatas(that.passWord,'passWord') && that.volidateDatas(that.code,'code')){
					that.ifRequest = true;

					var data = {
					    loginName : encodeURIComponent(that.userName),
					    password : Utils.CryptoJS.MD5(that.passWord).toString().toUpperCase(), // CryptoJS.MD5(passWord).toString().toUpperCase(),
						validateCode : that.code
					};
					syAxios({
						ifProxy : false,
						url : config.proxyHeader + config.login.login,
						data : data,
						success : function(d){
							var data = d.data || {},
							result = data.result || {};

							that.ifRequest = false;
							that.userId = result.userId || '';
							that.$router.push({
	//							path : '/index',//只写path参数为空？
								name : 'index',
								params: {
									loginName : result.name,
					                userId: that.userId
					            }
							});

							if(window.localStorage){
						        window.localStorage.setItem('userId',that.userId);
						        window.localStorage.setItem('name',result.name);
						    }else{
						        setCookie('userId',that.userId);
						        setCookie('name',result.name);
						    }
						},
						error : function(d){
							if(d.msg + '' === '88'){
								alert('用户已经被禁用');
							}else if(d.msg + '' === '888'){
								alert('密码输入有误');

							}else if(d.msg + '' === '8888'){
								alert('登录用户名不存在');
							}else{
								alert(d.msg.text);
							}
							that.ifRequest = false;
						}
					})
				}
			},
			setMainHeight(){
				var headerHeight = document.querySelector('header').clientHeight
				var footerHeight = document.querySelector('footer').clientHeight
				var height = document.querySelector('.loginPage').clientHeight - headerHeight - footerHeight
				document.querySelector('.main').height = height
			},
			drawLines(target){
				var that = this;

				that.container = target;
				that.width = that.container.clientWidth;
				that.height = that.container.clientHeight;
				that.canvas = document.querySelector('.bg-canvas');
				that.canvas.width = that.width;
				that.canvas.height = that.height;
				that.ctx = that.canvas.getContext('2d');

				that.options = {
					cellWidth : 8,
			        gridLineColor : '#30c0d9',
			        gridLineWidth : 1,
			        innerLineColor : '#1cbbd6',
			        innerLineWidth : 1,
			        innerCellCount : 6,
			        backgroundColor : '#14b8d4',
				};

		        that.hLine = [];  //储存y轴坐标
		        that.vLine = [];  //储存x轴坐标

		        var yStart = Math.floor(that.width/2),xStart = Math.floor(that.height/2);
		        var i = 0 , l = 0;
		        var hLine = [],vLine = [];
		        for(i = 1;;i++){
		            if(i === 1){
		                vLine.push(yStart);
		            }
		            if(yStart - that.options.cellWidth * i > 0){
		                vLine.push(yStart - that.options.cellWidth * i);
		                vLine.unshift(yStart + that.options.cellWidth * i);
		            }else {
		                break;
		            }
		        }
		        for(i = 1;;i++){
		            if(i === 1){
		                hLine.push(xStart);
		            }
		            if(xStart - that.options.cellWidth * i > 0){
		                hLine.push(xStart - that.options.cellWidth * i);
		                hLine.unshift(xStart + that.options.cellWidth * i);
		            }else {
		                break;
		            }
		        }

		        var ctx = that.ctx;
		        var lineWidth = 0,lineColor = '#fff';
		        for(i = 0, l = hLine.length;i<l;i++){
		            ctx.save();
		            ctx.beginPath();
		            if(i % (that.options.innerCellCount + 1) === 0){
		                that.hLine.push(hLine[i]);
		                lineWidth = that.options.gridLineWidth;
		                lineColor = that.options.gridLineColor;
		            }else {
		                lineWidth = that.options.innerLineWidth;
		                lineColor = that.options.innerLineColor;
		            }
		            ctx.strokeStyle = lineColor;
		            ctx.lineWidth = lineWidth;
		            if(lineWidth === 1){
		                ctx.moveTo(0,hLine[i] - 0.5);
		                ctx.lineTo(that.width,hLine[i] - 0.5);
		            }else {
		                ctx.moveTo(0,hLine[i]);
		                ctx.lineTo(that.width,hLine[i]);
		            }
		            ctx.stroke();
		            ctx.closePath();
		            ctx.restore();
		        }

		        for(i = 0, l = vLine.length;i<l;i++){
		            ctx.save();
		            ctx.beginPath();
		            if(i % (that.options.innerCellCount + 1) === 0){
		                that.vLine.push(vLine[i]);
		                lineWidth = that.options.gridLineWidth;
		                lineColor = that.options.gridLineColor;
		            }else {
		                lineWidth = that.options.innerLineWidth;
		                lineColor = that.options.innerLineColor;
		            }
		            ctx.strokeStyle = lineColor;
		            ctx.lineWidth = lineWidth;
		            if(lineWidth === 1){
		                ctx.moveTo(vLine[i] - 0.5,0);
		                ctx.lineTo(vLine[i] - 0.5,that.height);
		            }else {
		                ctx.moveTo(vLine[i],0);
		                ctx.lineTo(vLine[i],that.height);
		            }
		            ctx.closePath();
		            ctx.stroke();
		            ctx.restore();
		        }
			}
		}
	}
</script>


<style scoped>
  input::-ms-clear{
    display: none;
  }
	.loginPage{
		width: 100%;
		height: 100%;
		background: #14b8d4;
		position: relative;
	}
	canvas{
		position: absolute;
		z-index: 1;
	}

	header{
		width: 100%;
		height: 130px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	h2{
		padding: 60px 80px;
	}
	h2>img{
		width: 280px;
	}

	.main{
		position: absolute;
		top: 130px;
		width: 100%;
		padding: 20px 0;
		z-index: 2;
	}
	.clear-fix{
		clear: both;
		width: 780px;
		height: 448px;
		margin: auto;
		background: url('../../assets/login/form-bg.png') no-repeat;
	}
	.form-left{
		float: left;
		width: 50%;
		text-align: center;
	}
	.form-left>img{
		width: 280px;
		margin:160px auto;
	}
	.form-right{
		float: left;
		width: 50%;
	}
	form{
		padding: 100px 60px;
	}
	form>.form-group{
		margin-bottom: 36px;
		background: #fff;
		height: 40px;
		padding:0 10px;
	}
	form .form-group>img{
		position: absolute;
		margin: 8px auto;
	}
	form .form-group>input{
		border: none;
		outline: none;
		width: 224px;/*build之后其他页面样式污染？？*/
		height: 40px;
		margin-left: 40px;
		font-size: 14px;
		padding-left: 0;
	}
	.code{
		margin-bottom: 36px;
	}
	.code>.form-group{
		background: #fff;
		height: 40px;
		padding:0 10px;
		width: 50%;
		display: inline-block;
	}
	.code>.form-group>input{
		width: 130px;
	}
	.code>a{
		display: inline-block;
		width: 30%;
		height: 40px;
		line-height: 41px;
		text-align: center;
		margin-left: 10%;
		background: #14B8D4;
		color: #fff;
	}
	.code>a.getCode{
		background: #ccc;
		cursor : not-allowed;
	}
	.loginBtn{
		background: #14B8D4;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
	.loginBtn>a{
		display: block;
		width: 100%;
		height: 100%;
		color: #fff;
    cursor: pointer;
	}

	footer{
		width: 100%;
		height: 70px;
		line-height: 70px;
		text-align: center;
		color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

</style>
