<template>
<el-container>
  <el-aside :width="asideWidth" :collapse="isCollapse">
    <h2><span v-show="!isCollapse">SEEYII销售管理系统</span></h2>
    <el-menu :collapse="isCollapse" :default-active="currentIndex" :collapse-transition=true>
      <el-menu-item v-model="isCollapse" @click="collapseClick" index="0">
        <img class="nav-icon" src="../../assets/applyFor/collapse.png"/>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuApply" index="1" @click="getMainContent('apply-for', '1')">
        <img class="nav-icon" src="../../assets/applyFor/apply-for.png"/>
        <span>申请试用</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuCustomer" index="2" @click="getMainContent('customer-list', '2')">
        <img class="nav-icon" src="../../assets/applyFor/customer-list.png"/>
        <span>客户列表</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuStatistics" index="3" @click="getMainContent('customer-statistics','3')">
        <img class="nav-icon" src="../../assets/applyFor/customer-statistics.png"/>
        <span>客户使用统计</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuReminder" index="4" @click="getMainContent('customer-reminder', '4')">
        <img class="nav-icon" src="../../assets/applyFor/customer-reminder.png"/>
        <span>客户到期提醒</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuQuery" index="5" @click="getMainContent('customer-query', '5')">
        <img class="nav-icon" src="../../assets/applyFor/customer-query.png"/>
        <span>客户查询</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuWarning" index="6" @click="getMainContent('customer-warning', '6')">
        <img class="nav-icon" src="../../assets/applyFor/customer-warning.png"/>
        <span>预警提醒</span>
      </el-menu-item>
      <el-menu-item v-show="isManager" index="7" @click="getMainContent('customer-member', '7')">
        <img class="nav-icon" src="../../assets/applyFor/customer-member.png"/>
        <span>团队成员</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuSale" index="8" @click="getMainContent('customer-sale', '8')">
        <img class="nav-icon" src="../../assets/applyFor/customer-sale.png"/>
        <span>技术支持-销售</span>
      </el-menu-item>
      <el-menu-item v-show="menuFlag.menuTech" index="9" @click="getMainContent('customer-technology', '9')">
        <img class="nav-icon" src="../../assets/applyFor/customer-tech.png"/>
        <span>技术支持-技术</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
  <el-container>
    <el-header>
      <div>
        <span>你好，管理员{{userName}}!</span>
        <a href="javascript:void(0)" @click="feedback()"><i class="modify"></i><span>使用反馈</span></a>
        <span class="split">|</span>
        <a href="javascript:void(0)" @click="modifyPassword()"><i class="modify"></i><span>修改密码</span></a>
        <span class="split">|</span>
        <a href="javascript:void(0)" @click="logOut()"><i class="quit"></i><span>退出</span></a>
      </div>
    </el-header>
    <el-main>
      <!--<keep-alive>-->
      <apply-for v-if="componentName === 'apply-for'"></apply-for>
      <customer-list v-else-if="componentName === 'customer-list'"></customer-list>
      <customer-statistics v-else-if="componentName === 'customer-statistics'"></customer-statistics>
      <customer-reminder v-else-if="componentName === 'customer-reminder'"></customer-reminder>
      <customer-query v-else-if="componentName === 'customer-query'"></customer-query>
      <customer-warning v-else-if="componentName === 'customer-warning'"></customer-warning>
      <customer-member v-else-if="componentName === 'customer-member'"></customer-member>
      <customer-sale v-else-if="componentName === 'customer-sale'"
                     :flag="menuFlag.menuSale === true ? 'sale' : ''"></customer-sale>
      <customer-technology v-else-if="componentName === 'customer-technology'"
                           :flag="menuFlag.menuTech === true ? 'tech' : ''"></customer-technology>
      <!--</keep-alive>-->
    </el-main>
  </el-container>

  <!--退出-->
  <div class="dialog-container">
    <el-dialog title="提示" :visible.sync="dialogLogOutVisible" width="300px">
      <span>确定要退出吗？</span>
      <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogLogOutVisible = false">取 消</el-button>
			    <el-button type="primary" @click="logOutSure()">确 定</el-button>
			  </span>
    </el-dialog>

    <el-dialog title="使用反馈" :visible.sync="dialogFeedBackVisible">
      <div class="feedback-content">
        <textarea v-model="feedbackContent"></textarea>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFeedBackVisible = false">取 消</el-button>
        <el-button type="primary" @click="feedbackSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</el-container>
</template>

<script>
import syAxios from '../../../public/static/common/axios.js'
import getConfig from '../../../public/static/common/config.js'

import ApplyFor from '@/pages/applyFor/applyFor'
import CustomerList from '@/pages/customerList/customerList'
import CustomerStatistics from '@/pages/customerStatistics/customerStatistics'
import CustomerReminder from '@/pages/customerReminder/customerReminder'
import CustomerQuery from '@/pages/customerQuery/customerQuery'
import CustomerWarning from '@/pages/customerWarning/customerWarning'
import CustomerMember from '@/pages/customerMember/customerMember'
import CustomerTechnology from '@/pages/customerTechnology/customerTechnology'


export default {
  name: 'ApplyFor',
  data() {
    return {
      userId: '',
      userName: '',
      feedbackContent: '',
      dialogFeedBackVisible: false,
      dialogLogOutVisible: false,
      isCollapse: false,
      componentName: "",
      asideWidth: "180px",
      currentIndex: '',
      isManager: false,
      menuFlag: {
        menuApply: false,
        menuCustomer: false,
        menuStatistics: false,
        menuReminder: false,
        menuQuery: false,
        menuWarning: false,
        menuSale: false,
        menuTech: false,
      }
    }
  },
  components: {
    'apply-for': ApplyFor,
    'customer-list': CustomerList,
    'customer-statistics': CustomerStatistics,
    'customer-reminder': CustomerReminder,
    'customer-query': CustomerQuery,
    'customer-warning': CustomerWarning,
    'customer-member': CustomerMember,
    'customer-technology': CustomerTechnology,
    'customer-sale': CustomerTechnology
  },
  created: function() {
    let that = this;
    that.userId = window.localStorage.getItem('userId') || '';
    that.userName = window.localStorage.getItem('name') || '';
    that.getPermitUser();
    that.getMenuList();
  },
  methods: {
    logOut: function() {
      this.dialogLogOutVisible = true;
    },
    logOutSure: function() {
      var that = this;
      var config = getConfig();

      syAxios({
        ifProxy: false,
        url: config.proxyHeader + config.login.offline,
        success: function(d) {
          that.dialogLogOutVisible = false;
          localStorage.clear();
          //跳转登录页
          that.$router.push({
            name: 'login'
          })
        },
        error: function(d) {
          that.dialogLogOutVisible = false;
          localStorage.clear();
          //跳转登录页
          that.$router.push({
            name: 'login'
          })
        }
      })
    },
    modifyPassword: function() {
      this.$router.push({
        name: 'modify'
      })
    },
    collapseClick: function() {
      var that = this;

      that.isCollapse = !that.isCollapse;
      if (that.isCollapse) {
        that.asideWidth = "74px"
      } else {
        that.asideWidth = "180px"
      }
    },
    getMainContent: function(componentName, index) {
      this.componentName = componentName
      this.currentIndex = index + ''
    },
    feedback: function() {
      var that = this;
      that.feedbackContent = '';
      that.dialogFeedBackVisible = true;
    },
    feedbackSubmit: function() {
      var that = this;
      var config = getConfig();
      var data = {
        content: encodeURIComponent(that.feedbackContent)
      };

      syAxios({
        ifProxy: true,
        url: config.login.feedback,
        data: data,
        success: function(d) {
          that.dialogFeedBackVisible = false;
          alert('使用反馈已提交!');
        },
        error: function(d) {
          that.dialogFeedBackVisible = false;
        }
      })
    },
    getPermitUser: function() {
      var that = this;
      var config = getConfig();

      syAxios({
        ifProxy: true,
        url: config.permitUser,
        success: function(d) {
          var data = d.data || {},
            data_list = data.data_list || [];
          if (data_list.length > 0) {
            that.isManager = true
          }
        }
      })
    },
    getMenuList: function() {
      let that = this;
      let config = getConfig();

      syAxios({
        ifProxy: true,
        url: config.updateTechnicalSupportUrl,
        success: function(d) {
          let data = d.data || {};
          data = data.data_list || [];
          if (data.length > 0) {
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i] + '' === '1') {
                that.menuFlag.menuApply = true;
              }
              if (data[i] + '' === '2') {
                that.menuFlag.menuCustomer = true;
              }
              if (data[i] + '' === '3') {
                that.menuFlag.menuStatistics = true;
              }
              if (data[i] + '' === '4') {
                that.menuFlag.menuReminder = true;
              }
              if (data[i] + '' === '5') {
                that.menuFlag.menuQuery = true;
              }
              if (data[i] + '' === '6') {
                that.menuFlag.menuWarning = true;
              }
              if (data[i] + '' === '7') {
                that.menuFlag.menuSale = true;
              }
              if (data[i] + '' === '8') {
                that.menuFlag.menuTech = true;
              }
            }

            let index = data[0] || 1;
            if (index + '' === '7') {
              that.currentIndex = '8';
              that.componentName = 'customer-sale';
            } else if (index + '' === '8') {
              that.currentIndex = '9';
              that.componentName = 'customer-technology';

            } else {
              that.currentIndex = index;
              if (index + '' === '1') {
                that.componentName = 'apply-for';
              }
              if (index + '' === '2') {
                that.componentName = 'customer-list';
              }
              if (index + '' === '3') {
                that.componentName = 'customer-statistics';
              }
              if (index + '' === '4') {
                that.componentName = 'customer-reminder';
              }
              if (index + '' === '5') {
                that.componentName = 'customer-query';
              }
              if (index + '' === '6') {
                that.componentName = 'customer-warning';
              }
            }
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.el-container {
  height: 100%;
}

.el-aside {
  height: 100%;
  background: #3f3f3f;
  border-right: 10px solid #03a6c2;
}

.el-aside > h2 {
  height: 60px;
  line-height: 60px;
  background: #03a6c2;
  color: #fff;
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.el-menu {
  background: #3f3f3f;
  border-right: none;
  border-bottom: 1px solid #4a4a4a;
}

.el-menu-item {
  border: 1px solid #4a4a4a;
  border-bottom: 1px solid #242424;
  color: #f5f5f5;
}

.el-menu-item:nth-child(1) {
  text-align: center;
}

.el-menu-item:hover,
.el-menu-item:focus {
  background: #03a6c2;
  border-right: none;
}

.el-menu-item.is-active {
  background: #03a6c2;
  border-right: none;
  color: #fff;
}

.nav-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.el-header {
  height: 60px;
  line-height: 60px;
  background: #14B8D4;
  color: #fff;
  position: relative;
}

.el-header > div {
  height: 60px;
  position: absolute;
  right: 20px;
}

.el-header > div > span {
  display: inline-block;
  font-size: 18px;
  margin-right: 30px;
}

.el-header > div > span.split {
  color: #14B8D4;
  margin-right: 0px;
}

.el-header > div > a {
  display: inline-block;
  height: 60px;
  padding: 0 24px;
  text-align: center;
  line-height: 62px;
  background: #03a6c2;
  color: #fff;
  margin-right: -13px;
}

.el-header > div > a i {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-bottom: -5px;
}

i.modify {
  background: url("../../assets/modify.png") no-repeat;
}

i.quit {
  background: url("../../assets/quit.png") no-repeat;
}


.el-main {
  background: #f5f8fa;
  padding: 10px 10px 0 10px;
}

.el-button {
  background: #fff;
  border: 1px solid #14B8D4;
  border-radius: 10px;
  color: #14B8D4;
  font-weight: 600;
  padding: 6px 16px;
}

.feedback-content {
  height: 280px;
  padding: 10px;
}

.feedback-content > textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  outline: none;
}
</style>
