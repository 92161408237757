<template>
	<div class="modifyPage">
		<header>
			<div class="logo">
				<h2><img src="../../assets/modifyPassWord/logo.png" /></h2>
				<span>修改密码</span>
			</div>
			<div class="index">
				<router-link :to="{name:'index'}">首页</router-link>
			</div>
		</header>
		<section class="main">
			<form>
				<span>请输入原密码：</span>
				<div class="form-group">
					<img src="../../assets/modifyPassWord/pw.png" />
					<input v-model="oldPW" type="password" placeholder="请输入原密码" />
				</div>
				<span class="checkInfo" v-show="oldPWInfoVisible">{{oldPWInfo}}</span>
        <br/>
				<span>请输入新密码：</span>
				<div class="form-group">
					<img src="../../assets/modifyPassWord/pw.png" />
					<input v-model="newPW" type="password" placeholder="请输入新密码" />
				</div>
				<span class="checkInfo" v-show="newPWInfoVisible">{{newPWInfo}}</span>
        <br/>
				<span>请确认新密码：</span>
				<div class="form-group">
					<img src="../../assets/modifyPassWord/pw.png" />
					<input v-model="newPWAgain" type="password" placeholder="请确认新密码" />
				</div>
				<span class="checkInfo" v-show="newPWAgainInfoVisible">{{newPWAgainInfo}}</span>

				<div class="modifyBtn"><a href="javascript:void(0)" @click="modifyPassWord()">修改密码</a></div>
			</form>
		</section>

		<!--修改成功-->
		<div class="dialog-container">
			<el-dialog :visible.sync="modifyDialogVisible" width="300px">
			  <div class="modifyOk">
			  	<img src="../../assets/modifyPassWord/ok.png" />
			  	<p>修改密码成功！</p>
			  </div>
			  <div class="dialog-footer" @click="goLogin()">知道了</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import Utils from '../../../public/static/common/common.js'
	import syAxios from '../../../public/static/common/axios.js'
	import getConfig from '../../../public/static/common/config.js'

	export default{
		name : 'modifyPassWord',
		data(){
			return {
				userId : '',
				oldPW : '',
				newPW : '',
				newPWAgain : '',
				oldPWInfoVisible : false,
				newPWInfoVisible : false,
				newPWAgainInfoVisible : false,
				oldPWInfo : '请输入密码',
				newPWInfo : '请输入新密码',
				newPWAgainInfo : '请确认密码',
				modifyDialogVisible : false
			}
		},
		components : {},
		mounted: function() {
			this.userId = window.localStorage.getItem('userId') ||'';
		},
		methods : {
			validateDatas : function(value,type){
				if(type === 'oldPW'){
					var reg = /^[a-zA-Z0-9_@]{0,}$/;
					if(value.trim() === ''){
						this.oldPWInfo = '密码不能为空';
						this.oldPWInfoVisible = true;
						return false;
					}else if(!reg.test(value.trim())){
						this.oldPWInfo = '请输入由字母数字下划线组成的密码';
						this.oldPWInfoVisible = true;
						return false;
					}else{
						this.oldPWInfoVisible = false;
						return true;
					}
				}

				if(type === 'newPW'){
					var reg = /^[a-zA-Z0-9_@]{0,}$/;
					if(value.trim() === ''){
						this.newPWInfo = '新密码不能为空';
						this.newPWInfoVisible = true;
						return false;
					}else if(!reg.test(value.trim())){
						this.newPWInfo = '请输入由字母数字下划线组成的密码';
						this.newPWInfoVisible = true;
						return false;
					}else{
						this.newPWInfoVisible = false;
						return true;
					}
				}

				if(type === 'newPWAgain'){
					var reg = /^[a-zA-Z0-9_@]{0,}$/;
					if(value.trim() === ''){
						this.newPWAgainInfo = '新密码不能为空';
						this.newPWAgainInfoVisible = true;
						return false;
					}else if(value.trim() != this.newPW){
						this.newPWAgainInfo = '两次输入的密码不一致';
						this.newPWAgainInfoVisible = true;
						return false;
					}else{
						this.newPWAgainInfoVisible = false;
						return true;
					}
				}
			},
			goLogin : function(){
				this.$router.push({
					name : 'login'
				})
			},
			modifyPassWord : function(){
				var that = this;
				var config = getConfig();

				if(that.validateDatas(that.oldPW,'oldPW') && that.validateDatas(that.newPW,'newPW') && that.validateDatas(that.newPWAgain,'newPWAgain')){
					var data = {
						oldPassword : Utils.CryptoJS.MD5(that.oldPW).toString().toUpperCase(),
					    newPassword : Utils.CryptoJS.MD5(that.newPWAgain).toString().toUpperCase() // CryptoJS.MD5(passWord).toString().toUpperCase(),
					};
					syAxios({
						ifProxy : true,
						url : config.login.updatePassword,
						data : data,
						success : function(d){
							var data = d.data || {},
							ifSuccess = data.success || {};
							if(data.return_msg){
								alert(data.return_msg);
							}else{
								if(ifSuccess === true){
									that.modifyDialogVisible = true;
								}else{
									alert("修改密码失败！");
								}
							}
						}
					})
				}else{
					that.validateDatas(that.oldPW,'oldPW');
					that.validateDatas(that.newPW,'newPW');
					that.validateDatas(that.newPWAgain,'newPWAgain');
				}
			}
		}
	}
</script>

<style scoped>
	.modifyPage{
		width: 100%;
		height: 100%;
		background: #fff;
	}
	header{
		width: 100%;
		height: 130px;
		line-height: 130px;
		padding: 0 60px;
		background: #14B8D4;
	}
	header>div{
		width: 60%;
		font-size: 18px;
		color: #fff;
		float: left;
	}
	header>div.index{
		width: 40%;
		float: right;
		text-align: right;
	}
	header>div.index>a{
		display: block;
		text-align: center;
		color: #fff;
		font-size: 16px;
	}
	h2{
		display: inline-block;
	}
	h2>img{
		width: 280px;
		margin-right: 40px;
	}

	.main{
		position: absolute;
		top: 130px;
		width: 100%;
		padding: 20px 0;
		z-index: 2;
	}

	form{
		width: 400px;
		margin: 60px auto;
	}
	form>.form-group{
		display: inline-block;
		margin-bottom: 36px;
		background: #fff;
		height: 36px;
		padding:0 10px;
		border: 1px solid #ccc;
	}
	form .form-group>img{
		position: absolute;
		margin: 8px auto;
	}
	form .form-group>input{
		border: none;
		width: 224px;
		height: 34px;
		margin-left: 36px;
		font-size: 14px;
	}
	.checkInfo{
		display: block;
		margin: -26px 0 10px 102px;
		color: #FF0000;
	}

	.modifyBtn{
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
	.modifyBtn>a{
		display: block;
		background: #14B8D4;
		margin-left: 102px;
		width: 200px;
		height: 100%;
		color: #fff;
	}

	.el-dialog__body{
		padding: 0 0;
	}
	.modifyOk{
		margin: 0 -20px;
		text-align: center;
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
	}
	.modifyOk p{
		color: #999;
		padding: 5px;
	}

	.dialog-footer{
		display: block;
		text-align: center;
		color: #14B8D4;
		cursor: pointer;
		padding: 10px 0;
	}
</style>
