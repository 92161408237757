<style scoped lang="less">
.header-title{
  font-size: 16px;
}
.apply-for>header{
  background: #fff;
}
.apply-for>div{
  background: #fff;
}

.apply-for-header>form{
  padding: 10px 10px 0 10px;
}

.content-header{
  padding: 10px;
  border-bottom: 1px solid #14B8D4;
}
.content-header>div{
  display: inline-block;
  line-height: 30px;
}
.block{
  display: inline-block;
  margin: 0 10px 0 0;
}
.block.start-date{
  margin: 0 0 0 0;
}
.el-date-editor.el-input{
  width: 130px;
  height: 30px;
  border-radius: 0;
}
.el-input__inner{
  width: 130px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  border: 1px solid #ccc;
}
.form-header input.searchInfo{
  height: 28px;
  padding-left: 10px;
  color: #666;
  margin: -1px 10px 0 0;
}

.form-header{
  padding: 10px;
}
label{
  margin-right: 20px;
}
.form-header input.searchInfo{
  height: 28px;
  padding-left: 10px;
  color: #666;
  border: 1px solid #aaa;
}
.form-header button{
  height: 32px;
  padding: 0 15px;
  background: #14B8D4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.query-result{
  padding: 10px;
}
.query-result>header{
  padding: 0 0 20px 0px;
  color: #333;
}
.query-result>header span{
  margin-right: 20px;
}
.charts-container{
  width: 100%;
  height: 480px;
  min-height: 480px;
}
#chart{
  width: 100%;
  height: 100%;
}
.no-data{
  width: 100%;
  height: 480px;
  padding: 20px 20px;
}

input::-ms-clear{
  display: none;
}

.form-input {
  display: inline-block;
  width: 200px;
  height: 32px;
  margin-left: 10px;
  /deep/ .el-input, /deep/ input {
    width: 100%;
    height: 100%;
  }
}
.date-text {
  margin-left: 10px;
}

</style>

<template>
  <section class="apply-for">
    <permit-header v-on:getCustomerList="updateUserVisitQueryStatistics" v-on:getQueryId="initQueryId"></permit-header>

    <div class="apply-for-content">
      <header class="content-header">
        <div class="header-title">
          <span>客户查询</span>
          <div class="block start-date">
            <el-date-picker
                v-model="startDate"
                class="form-input"
                type="date"
                size="small"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
                @change="checkDate">
            </el-date-picker>
          </div>
          <span class="date-text">至</span>
          <div class="block">
            <el-date-picker
                v-model="endDate"
                class="form-input"
                type="date"
                size="small"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="结束日期"
                @change="checkDate">
            </el-date-picker>
          </div>
        </div>
      </header>
      <section>
        <header class="form-header">
          <!--<form>-->
          <!--<input type="text" v-on:blur="validatePhone(phone)" v-model="phone" placeholder="请输入手机号" />-->
          <label for="searchPhone">
            <el-radio type="radio" id="searchPhone" :label="1" v-model="flag">电话查询</el-radio>
          </label>
          <label for="searchCompany">
            <el-radio type="radio" id="searchCompany" :label="0" v-model="flag">公司查询</el-radio>
          </label>

          <el-input class="form-input searchInfo" v-show="flag + '' === '1'" type="text" v-model="searchContent" placeholder="请输入手机号" />
          <el-input class="form-input searchInfo" v-show="flag + '' === '0'" type="text" v-model="searchContent" placeholder="请输入公司名称" />
          <button @click="updateUserVisitQueryStatistics()">查询</button>
          <!--</form>-->
        </header>
        <div class="query-result">
          <header v-show="ifHasData">
            <span v-show="flag + '' === '1' ? true : false">姓名：{{name}}</span>
            <span v-show="flag + '' === '1' ? true : false">公司： {{company}}</span>

            <div v-show="flag + '' === '0' ? true : false">
              <label v-for="(item,index) in chartCompanys" @click="updateChartsData(item.data)"><input type="radio" name="company" :checked="index === 0 ? true : false" />{{item.company}} </label>
            </div>
          </header>
          <section class="charts-container">
            <div id="chart"></div>
          </section>
        </div>
      </section>
    </div>

    <!--提示信息-->
    <div class="dialog-container">
      <el-dialog :visible.sync="queryResultDialogVisible" width="400px">
        <span>{{queryResult}}</span>
        <span slot="footer" class="dialog-footer"></span>
      </el-dialog>
    </div>

  </section>
</template>


<script>
import PermitUserHeader from '../../components/permitUserHeader'
import echarts from '../../../public/static/common/echarts.js'
import syAxios from '../../../public/static/common/axios.js'
import getConfig from '../../../public/static/common/config.js'

export default{
  data(){
    return{
      userId : '',
      queryId : '',
      startDate : '',
      endDate : '',
      dateRange : '',
      flag : 1,
      searchContent : '',//18500313373
      name : '',
      company : '',
      pageSize : 10,
      pageNum : 1,
      queryResult : '',
      ifHasData : false,
      queryResultDialogVisible : false,
      optionsData : [],
      echarts : {
        chart : null
      },
      chartCompanys : []
    }
  },
  components : {
    'permit-header' : PermitUserHeader,
  },
  mounted : function(){
    var that = this;
    that.userId = window.localStorage.getItem('userId') ||'';

    that.startDate = that.format(new Date(new Date().getTime() - 7*24*3600*1000),'yyyy-MM-dd');
    that.endDate = that.format(new Date(),'yyyy-MM-dd');

    window.onresize = function(){
      that.onResize();
    }
  },
  methods : {
    onResize : function(){
      var that = this;
      for(var i in that.echarts){
        if(that.echarts.hasOwnProperty(i) && that.echarts[i].resize){
          that.echarts[i].resize();
        }
      }
    },
    format : function(time, format){
      var t = new Date(time);
      var tf = function(i){return (i < 10 ? '0' : '') + i};
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
        switch(a){
          case 'yyyy':
            return tf(t.getFullYear());
            break;
          case 'MM':
            return tf(t.getMonth() + 1);
            break;
          case 'mm':
            return tf(t.getMinutes());
            break;
          case 'dd':
            return tf(t.getDate());
            break;
          case 'HH':
            return tf(t.getHours());
            break;
          case 'ss':
            return tf(t.getSeconds());
            break;
        }
      })
    },
    checkDate : function(data){
      var that = this;

      if(new Date(that.endDate).getTime() < new Date(that.startDate).getTime()){
        alert("结束时间不能早于开始时间！")
      }
    },
    unique : function unique(arr){
      var newArr = [arr[0]];
      for(var i=1;i<arr.length;i++){
        if(newArr.indexOf(arr[i]) == -1){
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    validatePhone : function(value){
      var that = this;
      var reg = /^1[3|4|5|7|8][0-9]\d{8}$/g;
      if(reg.test(Number(value))){
        that.queryResult = '';
        return true;
      }else{
        that.queryResult = '请输入正确的手机号码';
        setTimeout(function(){
          that.queryResultDialogVisible = false;
        },2000);
        return false;
      }
    },
    submitQueryResult : function(){
      this.queryResultDialogVisible = false;
    },
    initQueryId : function(queryId){
      this.queryId = queryId;
    },
    updateUserVisitQueryStatistics : function(){
      var that = this;
      var config = getConfig();
      if(new Date(that.endDate).getTime() < new Date(that.startDate).getTime()){
        alert("结束时间不能早于开始时间！")
      }else{
        if(that.flag + '' === '1'){
          if(that.validatePhone(that.searchContent)){
            var options = {
              xAxis : {
                axisLine : {
                  lineStyle : {
                    color : '#eee',
                    width : 2
                  }
                },
                axisLabel : {
                  color :'#333'
                },
                nameTextStyle : {
                  color :'#333'
                },
                name : '访问日期',
                data : []
              },
              yAxis : {
                axisLine : {
                  lineStyle : {
                    color : '#eee'
                  }
                },
                axisLabel : {
                  color :'#333'
                },
                nameTextStyle : {
                  color :'#333'
                },
                splitLine : {
                  lineStyle : {
                    color : ['#eee']
                  }
                },
                name : '访问数量',
                minInterval : 1
              },
              grid : {
                left : 60,
                right : 100,
              },
              tooltip : {
                trigger : 'axis'
              },
              legend: {
                data:[]
              },
              series : []
            };

            that.echarts.chart = echarts.init(document.getElementById('chart'));
            that.echarts.chart.showLoading();

            var data = {
              queryId : that.queryId,
              startDate : that.startDate,
              endDate : that.endDate,
              flag : that.flag,
              searchContent : encodeURIComponent(that.searchContent.trim())
            };

            syAxios({
              ifProxy : true,
              url : config.index.customerQuery.userVisitQueryStatistics,
              data : data,
              success : function(d){
                var data = d.data || {},
                    data_list = data.data_list || [],
                    len = data_list.length;
                if(len >0){
                  that.ifHasData = true;

                  var result = data.result || {},
                      name = result.name || '',
                      company = result.company || '';
                  that.name = name;
                  that.company = company;

                  var seriesData = [];
                  for(var i=0;i<len;i++){
                    var num = data_list[i].num === undefined ? '' : data_list[i].num,
                        useDate = data_list[i].useDate === undefined ? '' : data_list[i].useDate;

                    options.xAxis.data.push(useDate);
                    seriesData.push(num);
                  }

                  options.series.push({
                    name : name,
                    type : 'line',
                    data : seriesData
                  });

                  that.echarts.chart.clear();
                  that.echarts.chart.showLoading();
                  that.echarts.chart.setOption(options);
                  that.echarts.chart.hideLoading();
                }else{
                  that.ifHasData = false;
                  that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
                  that.queryResultDialogVisible = true;
                  that.echarts.chart.clear();
                  that.echarts.chart.hideLoading();
                }
              },
              error : function(){
                that.ifHasData = false;
                that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
                that.queryResultDialogVisible = true;
                that.echarts.chart.clear();
                that.echarts.chart.hideLoading();
              }
            })
          }else{
            that.queryResult = '请输入正确的手机号码';
            that.queryResultDialogVisible = true;
            setTimeout(function(){
              that.queryResultDialogVisible = false;
            },2000);
            return false;
          }
        }else{
          var data = {
            queryId : that.queryId,
            startDate : that.startDate,
            endDate : that.endDate,
            flag : that.flag,
            searchContent : encodeURIComponent(that.searchContent.trim())
          };

          syAxios({
            ifProxy : true,
            url : config.index.customerQuery.userVisitQueryStatistics,
            data : data,
            success : function(d){
              var data = d.data || {},
                  result = data.result || {};

              that.chartCompanys = [];
              if(result){
                that.ifHasData = true;
                var count = 0;
                for(var key in result){
                  that.chartCompanys.push({
                    company : key,
                    data : result[key]
                  })
                  count++;
                  if(count === 1){
                    that.updateChartsData(result[key]);
                  }
                }
              }
              if(data.remind){
                that.ifHasData = false;
                that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
                that.queryResultDialogVisible = true;
                that.echarts.chart.clear();
                that.echarts.chart.hideLoading();
              }
            },
            error : function(){
              that.ifHasData = false;
              that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
              that.queryResultDialogVisible = true;
              that.echarts.chart.clear();
              that.echarts.chart.hideLoading();
            }
          })
        }
        return false;
      }
    },
    updateChartsData : function(data){
      var that = this;

      var options = {
        xAxis : {
          axisLine : {
            lineStyle : {
              color : '#eee',
              width : 2
            }
          },
          axisLabel : {
            color :'#333'
          },
          nameTextStyle : {
            color :'#333'
          },
          name : '访问日期',
          data : []
        },
        yAxis : {
          axisLine : {
            lineStyle : {
              color : '#eee'
            }
          },
          axisLabel : {
            color :'#333'
          },
          nameTextStyle : {
            color :'#333'
          },
          splitLine : {
            lineStyle : {
              color : ['#eee']
            }
          },
          name : '访问数量',
        },
        grid : {
          top : 180,
          left : 60,
          right : 100,
        },
        tooltip : {
          trigger : 'axis',
          confine : true
        },
        legend: {
          data:[]
        },
        series : []
      };

      that.echarts.chart = echarts.init(document.getElementById('chart'));
      that.echarts.chart.showLoading();

      if(data){
        that.ifHasData = true;

        for(var key in data){
          var seriesData = [];
          for(var ii=0,ll=data[key].length;ii<ll;ii++){
            var num = data[key][ii].num === undefined ? '' : data[key][ii].num,
                useDate = data[key][ii].useDate === undefined ? '' : data[key][ii].useDate;
            options.xAxis.data.push(useDate);
            seriesData.push(num);
          }

          options.legend.data.push(key);
          options.series.push({
            name : key,
            type : 'line',
            data : seriesData
          });
        }
        options.xAxis.data = that.unique(options.xAxis.data);
        that.echarts.chart.clear();
        that.echarts.chart.showLoading();
        that.echarts.chart.setOption(options);
        that.echarts.chart.hideLoading();
      }
    }
  }
}

</script>
