<style scoped lang="less">
	.header-title {
		font-size: 16px;
	}

	.apply-for>header {
		background: #fff;
	}

	.apply-for>div {
		background: #fff;
	}

  .apply-for-content2{
    margin-top: 10px;
  }

	.apply-for-header>form {
		padding: 10px 10px 0 10px;
	}

	.content-header {
		padding: 10px;
		border-bottom: 1px solid #14B8D4;
	}

	.content-header>div {
		display: inline-block;
		line-height: 30px;
	}

	.block {
		display: inline-block;
		margin: 0 10px 0 0;
	}

	.block.start-date {
		margin: 0 0 0 0;
	}

	.el-date-editor.el-input {
		width: 130px;
		height: 30px;
		border-radius: 0;
	}

	.el-input__inner {
		width: 130px;
		height: 30px;
		line-height: 30px;
		border-radius: 0;
		border: 1px solid #ccc;
	}

	.form-header input.searchInfo {
		height: 28px;
		padding-left: 10px;
		color: #666;
		margin: -1px 10px 0 0;
	}

	.form-header {
		padding: 10px;
	}

	.form-header input.searchInfo {
		height: 28px;
		padding-left: 10px;
		color: #666;
		border: 1px solid #aaa;
	}

	.form-header button {
		height: 32px;
		padding: 0 15px;
		background: #14B8D4;
		color: #fff;
		border: none;
		border-radius: 4px;
		cursor: pointer;
	}

	.query-result {
		padding: 10px;
	}

	.query-result>header {
		padding: 0 0 20px 0px;
		color: #333;
	}

	.query-result>header span {
		margin-right: 20px;
	}

	.charts-container {
		width: 100%;
		height: 360px;
		min-height: 360px;
	}

	#chart {
		width: 100%;
		height: 100%;
	}

	.allDates{
		word-break: break-all;
	}
	.allDates span{
		color: #14B8D4;
		cursor: pointer;
		text-decoration: underline;
		display: inline-block;
		margin-left: 5px;
	}

	.no-data {
		width: 100%;
		height: 360px;
		padding: 20px 20px;
	}

  input::-ms-clear{
    display: none;
  }

  .form-input {
    display: inline-block;
    width: 200px;
    height: 32px;
    margin-left: 10px;
    /deep/ .el-input, /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
  .date-text {
    margin-left: 10px;
  }

</style>

<template>
	<section class="apply-for">
		<permit-header :isMemberModel='isMemberModel'
                   v-on:getCustomerList="updatePageData()"
                   v-on:getQueryId="initQueryId">
		</permit-header>
		<div class="apply-for-content">
			<header class="content-header form-header">
				<div class="header-title">
					<span>团队成员</span>
					<div class="block start-date">
						<el-date-picker class="form-input" v-model="startDate" type="date" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="开始日期" @change="checkDate">
						</el-date-picker>
					</div>
					<span class="date-text">至</span>
					<div class="block">
						<el-date-picker  class="form-input" v-model="endDate" type="date" size="small" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="结束日期" @change="checkDate">
						</el-date-picker>
					</div>
					<div class="block">
						<el-button @click="updatePageData()">查询</el-button>
					</div>
				</div>
			</header>
			<section class="query-result">
				<div class="charts-container">
					<div id="chart"></div>
				</div>
			</section>
		</div>
		<div class="apply-for-content apply-for-content2">
			<header class="content-header">
				<div class="header-title">
					<span>{{startDate}}至{{endDate}}客户的使用情况</span>
				</div>
			</header>
			<section>
				<div class="query-result" v-show="showDataContainer">
					<div class="table-wrapper">
						<table>
							<thead>
								<tr>
									<th>电话号码</th>
									<th>姓名</th>
									<th>使用日期</th>
									<th>访问页面数(仅网站)</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
									<td>
										<div>{{item.phone}}</div>
									</td>
									<td>
										<div>{{item.name}}</div>
									</td>
									<td class="text-left">
										<div class="allDates">{{item.useDate.split(';').slice(0,7).join(',')}}<span v-show="item.useDate.split(';').length > 7 ? true : false" @click="showAllDates(item.useDate)">全部</span></div>
									</td>
									<td>{{item.num}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<page-control v-on:getPageData="renderData" v-on:getCustomerList="initCustomerList" :customerData="customerListStore" :currentPage="currentPage" :pageNum="pageNum" :firstPageShow="firstPageShow" :prePageShow="prePageShow" :nextPageShow="nextPageShow" :pageRefer="pageRefer" :loading="imgLoading">
					</page-control>
				</div>
				<div class="no-data" v-show='noData'>
					<img src="../../assets/noData.png" />
					<span>暂无数据</span>
				</div>
			</section>
		</div>

		<!--提示信息-->
		<div class="dialog-container">
			<el-dialog :visible.sync="queryResultDialogVisible" width="600px">
				<span>{{queryResult}}</span>
				<span slot="footer" class="dialog-footer"></span>
			</el-dialog>
		</div>

		<div>
			<el-dialog title="使用日期" :visible.sync="allDatesDialogVisible" width="600px">
			  <div class="allDates">{{allDatesInDialog}}</div>
			  <span slot="footer" class="dialog-footer"></span>
			</el-dialog>
		</div>

	</section>
</template>

<script>
	import PermitUserHeader from '../../components/permitUserHeader'
	import PageControl from '@/components/PageControl'
	import echarts from '../../../public/static/common/echarts.js'
	import syAxios from '../../../public/static/common/axios.js'
	import getConfig from '../../../public/static/common/config.js'

	export default {
		data() {
			return {
				userId: '',
				queryId: '',
				startDate: '',
				endDate: '',
				ifHasData: false,
				noData: false,
				showDataContainer: false,
				allDatesInDialog : '',
				allDatesDialogVisible : false,
        pageSize : 10,
        pageNum : 1,
        currentPage : 0,
        queryResult: '',
				firstPageShow: false,
				prePageShow: false,
				nextPageShow: false,
				imgLoading: false,
				isMemberModel: true,
				queryResultDialogVisible: false,
				pageRefer: 'customerMember',
				customerList: [],
				customerListStore: [],
				echarts: {
					chart: null
				},
				chartMembers: []
			}
		},
		components: {
			'permit-header': PermitUserHeader,
			'page-control': PageControl
		},
		mounted: function() {
			var that = this;

			that.userId = window.localStorage.getItem('userId') || '';

			that.startDate = that.format(new Date(new Date().getTime() - 7 * 24 * 3600 * 1000), 'yyyy-MM-dd');
			that.endDate = that.format(new Date(), 'yyyy-MM-dd');

			window.onresize = function() {
				that.onResize();
			}

			that.updatePageData();
		},
		methods: {
			onResize: function() {
				var that = this;
				for(var i in that.echarts) {
					if(that.echarts.hasOwnProperty(i) && that.echarts[i].resize) {
						that.echarts[i].resize();
					}
				}
			},
			initQueryId: function(queryId) {
				this.queryId = queryId;
			},
			updatePageData: function() {
				var that = this;

				if(new Date(that.startDate).getTime() > new Date(that.endDate).getTime()) {
					alert('开始时间不能晚于结束时间，请重新选择！');
				} else {
					that.updateUserUseStatistics();
					that.initCustomerList(true);
				}
			},
			unique: function unique(arr) {　　
				var newArr = [arr[0]];　　
				for(var i = 1; i < arr.length; i++) {　　　
					if(newArr.indexOf(arr[i]) == -1) {
						newArr.push(arr[i]);
					}
				}
				return newArr;
			},
			checkDate: function(data) {
				var that = this;

				if(new Date(that.endDate).getTime() < new Date(that.startDate).getTime()) {
					alert("结束时间不能早于开始时间！");
					return;
				}
			},
			showAllDates : function(dates){
				var that = this;
				that.allDatesInDialog = dates;
				that.allDatesDialogVisible = true;
			},
			format: function(time, format) {
				var t = new Date(time);
				var tf = function(i) {
					return(i < 10 ? '0' : '') + i
				};
				return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
					switch(a) {
						case 'yyyy':
							return tf(t.getFullYear());
							break;
						case 'MM':
							return tf(t.getMonth() + 1);
							break;
						case 'mm':
							return tf(t.getMinutes());
							break;
						case 'dd':
							return tf(t.getDate());
							break;
						case 'HH':
							return tf(t.getHours());
							break;
						case 'ss':
							return tf(t.getSeconds());
							break;
					}
				})
			},
			updateUserUseStatistics: function() {
				var that = this;
				var config = getConfig();
				if(new Date(that.endDate).getTime() < new Date(that.startDate).getTime()) {
					alert("结束时间不能早于开始时间！")
				} else {
					var data = {
						queryId: that.queryId,
						startDate: that.startDate,
						endDate: that.endDate
					};
					syAxios({
						ifProxy: true,
						url: config.index.customerMember.teamStatisticsChart,
						data: data,
						success: function(d) {
							var data = d.data || {},
								result = data.data_list || {};

							that.chartMembers = [];
							for(var i in result){
								if(result.hasOwnProperty(i)) {
									that.ifHasData = true;
									for(var key in result) {
										that.chartMembers.push({
											name: key,
											data: result[key]
										})
									}
									that.updateChartsData(result);
								}else{
									that.ifHasData = false;
									that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
									that.queryResultDialogVisible = true;
									that.echarts.chart.clear();
									that.echarts.chart.hideLoading();
								}
							}

						},
						error: function() {
							that.ifHasData = false;
							that.queryResult = data.remind === undefined ? '暂无记录' : data.remind;
							that.queryResultDialogVisible = true;
							that.echarts.chart.clear();
							that.echarts.chart.hideLoading();
						}
					})
					return false;
				}
			},
			updateChartsData: function(data) {
				var that = this;

				var options = {
					xAxis: {
						axisLine: {
							lineStyle: {
								color: '#ccc',
								width: 2
							}
						},
						axisLabel: {
							color: '#333'
						},
						nameTextStyle: {
							color: '#333'
						},
						name: '访问日期',
						data: []
					},
					yAxis: {
						axisLine: {
							lineStyle: {
								color: '#ccc'
							}
						},
						axisLabel: {
							color: '#333'
						},
						nameTextStyle: {
							color: '#333'
						},
						splitLine: {
							lineStyle: {
								color: ['#eee']
							}
						},
						name: '访问数量',
						minInterval: 1
					},
					grid: {
						top: 60,
						left: 60,
						right: 100,
					},
					tooltip: {
						trigger: 'axis',
						confine: true
					},
					legend: {
						data: []
					},
					series: []
				};

				that.echarts.chart = echarts.init(document.getElementById('chart'));
				that.echarts.chart.showLoading();

				if(data) {
					that.ifHasData = true;

					for(var key in data) {
						var seriesData = [];
						for(var ii = 0, ll = data[key].length; ii < ll; ii++) {
							var num = data[key][ii].num === undefined ? '' : data[key][ii].num,
								useDate = data[key][ii].useDate === undefined ? '' : data[key][ii].useDate;
							options.xAxis.data.push(useDate);
							seriesData.push(num);
						}

						options.legend.data.push(key);
						options.series.push({
							name: key,
							type: 'line',
							data: seriesData
						});
					}
					options.xAxis.data = that.unique(options.xAxis.data);
					that.echarts.chart.clear();
					that.echarts.chart.showLoading();
					that.echarts.chart.setOption(options);
					that.echarts.chart.hideLoading();
				}
			},
			initCustomerList: function(init) {
				var that = this;
				var config = getConfig();
				var data = {
					queryId: that.queryId,
					startDate: that.startDate,
					endDate: that.endDate,
					pageNum: that.pageNum
				};
				if(init) {
					data.pageNum = 1;
					that.pageNum = 1;
					that.currentPage = 0;
				}
				that.firstPageShow = false;
				that.prePageShow = false;
				that.nextPageShow = false;
				syAxios({
					ifProxy: true,
					url: config.index.customerMember.teamUseStatistics,
					data: data,
					success: function(d) {
						var pageSize = that.pageSize;
						var data = d.data || {},
							data_list = data.data_list || [],
							l = data_list.length;

						var tableData = [];
						if(l > 0) {
							for(var i = 0; i < l; i++) {
								var phone = data_list[i].phone || '',
									name = data_list[i].name || '',
									useDate = data_list[i].useDate || '',
									num = data_list[i].num || '';

								tableData.push({
									phone: phone,
									name: name,
									useDate: useDate,
									num: num
								})
							}
							that.showDataContainer = true;
							that.noData = false;
						}
						that.customerList = [].concat(tableData);

						if(init) {
							if(l > 0) {
								that.customerListStore = [].concat(tableData);
								if(l === pageSize) {
									that.nextPageShow = true;
									that.pageNum++;
									that.currentPage = that.pageNum - 1;
								}
							} else {
								that.noData = true;
								that.showDataContainer = false;
							}
						} else {
							if(l > 0) {
								that.customerListStore = that.customerListStore.concat(tableData);
								that.firstPageShow = true;
								that.prePageShow = true;
								if(l === pageSize) {
									that.nextPageShow = true;
									that.pageNum++;
									that.currentPage = that.pageNum - 1;
								} else {
									that.nextPageShow = false;
									that.currentPage = that.pageNum;
								}
							} else {
								that.firstPageShow = true;
								that.prePageShow = true;
								that.nextPageShow = false;
								that.currentPage = that.pageNum - 1;
								that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize, that.customerListStore.length);
							}
						}
					}
				})
			},
			renderData: function(data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow) {
				var that = this;

				that.customerList = [].concat(data);
				that.pageNum = pageNum;
				that.currentPage = currentPage;
				that.firstPageShow = firstPageShow;
				that.prePageShow = prePageShow;
				that.nextPageShow = nextPageShow;
			}
		}
	}
</script>
