<style scoped lang="less">
.apply-for-content {
  background-color: #fff;
  padding-bottom: 10px;
}
.header-title {
  font-size: 16px;
}

.apply-for > header {
  background: #fff;
}

.apply-for > div {
  background: #fff;
}

.apply-for-header > form {
  padding: 20px 10px 0 10px;
}

.content-header {
  padding: 10px;
  border-bottom: 1px solid #14B8D4;
}

.content-header > div {
  display: inline-block;
  line-height: 30px;
}

</style>

<template>
  <div class="apply-for-content">
    <header class="content-header">
      <div class="header-title">
        <span>校验列表</span>
      </div>
    </header>
    <component :is="currentView"></component>
  </div>
</template>

<script>
import ComInvest from './components/com-invest.component';
import ComRisk from './components/com-risk.component';
import ComBidding from './components/com-bidding.component';

export default {
  data() {
    return {
      currentView: 'com-invest',
      localStorageName: ''
    }
  },
  components: {
    ComInvest,
    ComRisk,
    ComBidding
  },
  mounted() {
    this.currentView = this.$route.query.type || 'com-invest';

  },

}
</script>
