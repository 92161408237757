<style scoped lang="less">
  .header-title {
    font-size: 16px;
  }

  .apply-for>header {
    background: #fff;
  }

  .apply-for>div {
    background: #fff;
  }

  .apply-for-header>form {
    padding: 10px 10px 0 10px;
  }

  .content-header {
    padding: 10px;
    border-bottom: 1px solid #14B8D4;
  }

  .content-header>div {
    display: inline-block;
    line-height: 30px;
  }

  select {
    height: 26px;
    border: 1px solid #ccc;
    outline: none;
    margin: 0 5px;
  }

  .send-message {
    display: inline-block;
    font-size: 14px;
    margin-left: 20px;
  }

  .send-message label {
    margin: 0 10px;
  }

  .send-img .send {
    cursor: pointer;
  }

  .send-img img {
    width: 24px;
    margin-top: 6px;
  }

  input::-ms-clear{
    display: none;
  }
  .form-select {
    width: 100px;
    height: 32px;
    margin-right: 10px;
    /deep/ .el-input, /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
</style>

<template>
  <div class="apply-for-content">
    <header class="content-header">
      <div class="header-title">
        <span>客户到期提醒</span>
      </div>
    </header>
    <section>
      <div class="header-title pt-10 pl-10">
        <el-select class="form-select" v-model="dateRange" @change="initCustomerList(true)">
          <el-option value="oneMonth" label="一月">一月</el-option>
          <el-option value="twoWeek" label="两周">两周</el-option>
          <el-option value="oneWeek" label="一周">一周</el-option>
        </el-select>
        <el-select class="form-select" v-model="userRole" @change="initCustomerList(true)">
          <el-option value="2" label="付费">付费</el-option>
          <el-option value="0" label="试用">试用</el-option>
          <el-option value="1" label="试用过期">试用过期</el-option>
          <el-option value="3" label="付费过期">付费过期</el-option>
        </el-select>
      </div>
      <div class="query-result" v-show="showDataContainer">
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th>手机号</th>
              <th>姓名</th>
              <th>公司名称</th>
              <th>到期时间</th>
              <th v-if="userRole + '' === '2'">发送短信</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
              <td>
                <div>{{item.phone}}</div>
              </td>
              <td>
                <div>{{item.name}}</div>
              </td>
              <td class="text-left">
                <div>{{item.company}}</div>
              </td>
              <td>
                <div>{{item.token}}</div>
              </td>
              <td class="send-img" v-if="userRole + '' === '2'">
                <div v-if="item.way + '' === '1'">
                  <img src="../../assets/cutomerReminder/sended.png" />
                </div>
                <div v-if="item.way + '' === '0'" class="send" @click="sendCustomerList(item)">
                  <img src="../../assets/cutomerReminder/send.png" />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <page-control v-on:getPageData="renderData" v-on:getCustomerList="initCustomerList" :customerData="customerListStore" :currentPage="currentPage" :pageNum="pageNum" :firstPageShow="firstPageShow" :prePageShow="prePageShow" :nextPageShow="nextPageShow" :pageRefer="pageRefer" :loading="imgLoading">
        </page-control>
      </div>
      <div class="no-data" v-show='noData'>
        <img src="../../assets/noData.png" />
        <span>暂无数据</span>
      </div>
    </section>
  </div>
</template>

<script>
  import syAxios from '../../../public/static/common/axios.js'
  import getConfig from '../../../public/static/common/config.js'
  import PageControl from '@/components/PageControl'

  export default {
    data() {
      return {
        dateRange: 'oneMonth',
        userRole: '2',
        querySelectId: '2',
        pageSize: 10,
        pageNum: 1,
        currentPage: 0,
        showDataContainer: false,
        noData: false,
        phoneError: false,
        applyLengthenDialogVisible: false,
        ifHasData: false,
        firstPageShow: false,
        prePageShow: false,
        nextPageShow: false,
        imgLoading: false,
        pageRefer: 'customerList',
        optionsData: [],
        customerList: [],
        customerListStore: []
      }
    },
    components: {
      'page-control': PageControl,
    },
    props: {
      tab: {
        type: String,
        default: function(){
          return '';
        }
      },
      queryId: {
        type: String,
        default: function(){
          return '';
        }
      }
    },
    watch: {
      queryId: function(newVal){
        if(newVal){
          this.initCustomerList(true);
        }
      }
    },
    mounted: function() {
      this.initCustomerList(true);
    },
    methods: {
      initCustomerList: function(init) {
        let that = this;
        let config = getConfig();
        let data = {
          queryId: that.queryId,
          customerRemind: that.dateRange,
          userRole: that.userRole,
          pageNum: that.pageNum
        };
        if(init) {
          data.pageNum = 1;
          that.pageNum = 1;
          that.currentPage = 0;
        }
        that.firstPageShow = false;
        that.prePageShow = false;
        that.nextPageShow = false;

        let url = config.index.customerReminder.expireRemind;
        if(that.tab === 'com-risk'){
          url = config.index.customerReminder.expireRemindBond;
        }
        syAxios({
          ifProxy: true,
          url: url,
          data: data,
          success: function(d) {
            let pageSize = that.pageSize;
            let data = d.data || {},
              data_list = data.data_list || [],
              l = data_list.length;

            let tableData = [];
            if(l > 0) {
              for(let i = 0; i < l; i++) {
                let phone = data_list[i].phone || '',
                  name = data_list[i].name || '',
                  company = data_list[i].company || '',
                  token = data_list[i].token || '',
                  way = data_list[i].way === undefined ? '' : data_list[i].way,
                  userId = data_list[i].userId || '',
                  applyUserId = data_list[i].applyUserId || '',
                  beaTime = data_list[i].beaTime || '',
                  saleName = data_list[i].saleName || '';

                tableData.push({
                  phone: phone,
                  name: name,
                  company: company,
                  token: token,
                  way: way,
                  userId: userId,
                  applyUserId : applyUserId,
                  beaTime : beaTime,
                  saleName : saleName
                })
              }
              that.showDataContainer = true;
              that.noData = false;
            }
            that.customerList = [].concat(tableData);

            if(init) {
              if(l > 0) {
                that.customerListStore = [].concat(tableData);
                if(l === pageSize) {
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }
              } else {
                that.noData = true;
                that.showDataContainer = false;
              }
            } else {
              if(l > 0) {
                that.customerListStore = that.customerListStore.concat(tableData);
                that.firstPageShow = true;
                that.prePageShow = true;
                if(l === pageSize) {
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                } else {
                  that.nextPageShow = false;
                  that.currentPage = that.pageNum;
                }
              } else {
                that.firstPageShow = true;
                that.prePageShow = true;
                that.nextPageShow = false;
                that.currentPage = that.pageNum - 1;
                that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize, that.customerListStore.length);
              }
            }
          }
        })
      },
      sendCustomerList: function(item) {
        let that = this;
        let config = getConfig();
        let data = {
          userId : that.userId,
          queryId: that.queryId,
          applyUserId : item.applyUserId,
          customerRemind: that.dateRange,
          userRole: that.userRole,
          beaTime : encodeURIComponent(item.beaTime)
        };
        let url = config.index.customerReminder.smsWarning;
        if(that.tab === 'com-risk'){
          url = config.index.customerReminder.smsWarningBond;
        }

        syAxios({
          ifProxy: true,
          url: url,
          data: data,
          success: function(d) {
            if(data.applyUserId){
              let customerList = that.customerList;
              for(let i = 0, l = customerList.length; i < l; i++) {
                if(customerList[i].applyUserId === data.applyUserId) {
                  customerList[i].way = '1';
                }
              }
            } else {
              that.initCustomerList(true);
            }
          }
        })
      },
      renderData: function(data, currentPage, pageNum, firstPageShow, prePageShow, nextPageShow) {
        let that = this;

        that.customerList = [].concat(data);
        that.pageNum = pageNum;
        that.currentPage = currentPage;
        that.firstPageShow = firstPageShow;
        that.prePageShow = prePageShow;
        that.nextPageShow = nextPageShow;
      }
    }
  }
</script>
