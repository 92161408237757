<style scoped lang="less">
  .header-title{
    font-size: 16px;
  }
  .apply-for>header{
    background: #fff;
  }
  .apply-for>div{
    background: #fff;
  }

  .content-header{
    padding: 10px;
    border-bottom: 1px solid #14B8D4;
  }
  .content-header>div{
    display: inline-block;
    line-height: 30px;
  }
  select{
    height: 26px;
    border: 1px solid #ccc;
    outline: none;
    margin-right: 5px;
  }
  .form-select {
    width: 180px;
    height: 32px;
    margin-right: 10px;
    /deep/ .el-input, /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
</style>

<template>
  <div class="apply-for-content">
    <header class="content-header">
      <div class="header-title">
        <span>预警提醒</span>
      </div>
    </header>
    <section>
      <div class="header-title pt-10 pl-10">
        <el-select class="form-select" v-model="useUser" @change="initCustomerList(true)">
          <el-option value="1" label="开通未使用用户">开通未使用用户</el-option>
          <el-option value="2" label="近两个月未使用用户">近两个月未使用用户</el-option>
          <el-option value="3" label="具有流失风险的用户">具有流失风险的用户</el-option>
        </el-select>
        <el-select class="form-select" v-show="useUser + '' === '1'" v-model="unUseTime" @change="initCustomerList(true)">
          <el-option value="twoWeek" label="两周以内">两周以内</el-option>
          <el-option value="oneMonth" label="一月以内">一月以内</el-option>
          <el-option value="beyond1Month" label="一月到三月">一月到三月</el-option>
        </el-select>
        <el-select class="form-select" v-show="useUser + '' === '2' || useUser + '' === '3'" v-model="userRole" @change="initCustomerList(true)">
          <el-option value="" label="全部">全部</el-option>
          <el-option value="2" label="付费">付费</el-option>
          <el-option value="0" label="试用">试用</el-option>
          <el-option value="1" label="试用过期">试用过期</el-option>
          <el-option value="3" label="付费过期">付费过期</el-option>
        </el-select>
      </div>
      <div class="query-result" v-show="showDataContainer">
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th>手机号</th>
              <th>姓名</th>
              <th>公司名称</th>
              <th>申请时间</th>
              <th>通过时间</th>
              <th>会员有效期</th>
              <th>付费状态</th>
              <th>最近登录时间</th>
              <th>销售经理</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in customerList" :class="index % 2 === 0 ? '' : 'tr-even'">
              <td><div>{{item.phone}}</div></td>
              <td><div>{{item.name}}</div></td>
              <td><div>{{item.company}}</div></td>
              <td><div>{{item.createTime}}</div></td>
              <td><div>{{item.insertTime}}</div></td>
              <td><div>{{item.token}}</div></td>
              <td>
                <div v-if="item.userRole + '' === '4'" class="unpassed">未付费</div>
                <div v-if="item.userRole + '' === '3'" class="unpassed">付费过期</div>
                <div v-if="item.userRole + '' === '2'" class="unpassed">已付费</div>
                <div v-if="item.userRole + '' === '1'" class="unpassed">试用过期</div>
                <div v-if="item.userRole + '' === '0'" class="unpassed">试用</div>
              </td>
              <td><div>{{item.loginTime}}</div></td>
              <td>{{item.saleName}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <page-control
          v-on:getPageData="renderData"
          v-on:getCustomerList="initCustomerList"
          :customerData="customerListStore"
          :currentPage="currentPage"
          :pageNum="pageNum"
          :firstPageShow="firstPageShow"
          :prePageShow="prePageShow"
          :nextPageShow="nextPageShow"
          :pageRefer="pageRefer"
          :loading="imgLoading">
        </page-control>
      </div>
      <div class="no-data" v-show='noData'>
        <img src="../../assets/noData.png" />
        <span>暂无数据</span>
      </div>
    </section>
  </div>
</template>

<script>
  import PageControl from '@/components/PageControl'
  import syAxios from '../../../public/static/common/axios.js'
  import getConfig from '../../../public/static/common/config.js'
  import ComTabs from "../../components/tabs.component";
  import ComInvest from "../customerList/block.component";

  export default{
    data(){
      return{
        useUser : '3',
        unUseTime : 'twoWeek',
        userRole : '',
        pageSize : 10,
        pageNum : 1,
        currentPage : 0,
        firstPageShow : false,
        prePageShow : false,
        nextPageShow : false,
        imgLoading : false,
        pageRefer : 'customerList',
        customerList : [],
        showDataContainer : false,
        noData : false,
        customerListStore : [],
      }
    },
    components : {
      PageControl,
      ComTabs,
      ComInvest,
      ComRisk: {extends: ComInvest}
    },
    props: {
      tab: {
        type: String,
        default: function(){
          return '';
        }
      },
      queryId: {
        type: String,
        default: function(){
          return '';
        }
      }
    },
    watch: {
      queryId: function(newVal){
        if(newVal){
          this.initCustomerList(true);
        }
      }
    },
    mounted : function(){
      this.initCustomerList(true);
    },
    methods : {
      initCustomerList : function(init){
        let that = this;
        let config = getConfig();
        let data = {
          queryId : that.queryId,
          warningUser : that.useUser,
          querySelectId : that.unUseTime,
          pageNum : that.pageNum
        };
        if(that.useUser + '' !== '1'){
          data.querySelectId = '';
        }
        if(that.useUser + '' === '2' || that.useUser + '' === '3'){
          data.userRole = that.userRole;
        }
        if(init){
          data.pageNum = 1;
          that.pageNum = 1;
          that.currentPage = 0;
        }
        that.firstPageShow = false;
        that.prePageShow = false;
        that.nextPageShow = false;

        let url = config.index.customerWarning.warningStatistics;
        if(that.tab === 'com-risk'){
          url = config.index.customerWarning.warningStatisticsBond;
        }
        syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            let pageSize = that.pageSize;
            let data = d.data || {},
              data_list = data.result || [],
              l = data_list.length;

            let tableData = [];
            if(l > 0){
              for(let i=0;i<l;i++){
                let phone = data_list[i].phone || '',
                  name = data_list[i].name || '',
                  company = data_list[i].company || '',
                  createTime = data_list[i].createTime || '',
                  insertTime = data_list[i].insertTime || '',
                  token = data_list[i].token || '',
                  userRole = data_list[i].userRole === undefined ? '' : (data_list[i].userRole + ''),
                  loginTime = data_list[i].loginTime || '',
                  saleName = data_list[i].saleName || '';

                tableData.push({
                  phone : phone,
                  name : name,
                  company : company,
                  createTime : createTime,
                  insertTime : insertTime,
                  token : token,
                  userRole : userRole,
                  loginTime : loginTime,
                  saleName : saleName
                })
              }
              that.showDataContainer = true;
              that.noData = false;
            }
            that.customerList = [].concat(tableData);

            if(init){
              if(l > 0){
                that.customerListStore = [].concat(tableData);
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }
              }else{
                that.noData = true;
                that.showDataContainer = false;
              }
            }else{
              if(l > 0){
                that.customerListStore = that.customerListStore.concat(tableData);
                that.firstPageShow = true;
                that.prePageShow = true;
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }else{
                  that.nextPageShow = false;
                  that.currentPage = that.pageNum;
                }
              }else{
                that.firstPageShow = true;
                that.prePageShow = true;
                that.nextPageShow = false;
                if(that.pageNum === 2){
                  that.firstPageShow = false;
                  that.prePageShow = false;
                }
                that.currentPage = that.pageNum - 1;
                that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize,that.customerListStore.length);
              }
            }
          }
        })
      },
      renderData : function(data,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow){
        let that = this;

        that.customerList = [].concat(data);
        that.pageNum = pageNum;
        that.currentPage = currentPage;
        that.firstPageShow = firstPageShow;
        that.prePageShow = prePageShow;
        that.nextPageShow = nextPageShow;
      }
    }
  }
</script>
