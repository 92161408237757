<style scoped lang="less">
  .header-title{
    position: relative;
    font-size: 16px;
  }

  select{
    height: 28px;
  }
  .search-info{
    width: 200px;
    height: 32px;
    margin-left: 10px;
    /deep/ input {
      width: 100%;
      height: 100%;
    }
  }
  .header-title .el-button{
    height: 32px;
    padding: 0 15px;
    margin-left: 10px;
    background: #14B8D4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .form-select {
    width: 100px;
    margin-left: 10px;
    /deep/ input {
      width: 100%;
    }
  }

  .apply-for>header{
    background: #fff;
  }
  .apply-for>div{
    background: #fff;
  }

  .apply-for-header>form{
    padding: 10px 10px 0 10px;
  }
  .content-header{
    border-bottom: 1px solid #14B8D4;
    padding: 10px;
  }
  .content-header>div{
    line-height: 30px;
  }

  .checkAll{
    margin-right: 4px;
  }

  .block{
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .block.start-date{
    margin: 0 0 0 0;
  }

  .el-date-editor.el-input{
    width: 130px;
    height: 30px;
    border-radius: 0;
  }
  .el-input__inner{
    width: 130px;
    height: 30px;
    line-height: 30px;
    border-radius: 0;
    border: 1px solid #ccc;
  }

  .el-dialog{
    border-radius: 20px;
    padding: 20px;
  }
  .form-container{
    padding: 20px 20px 0 20px;
  }
  .form-group{
    margin-bottom: 20px;
    color: #333;
  }
  .form-group span{
    display: inline-block;
    text-align: right;
    width: 120px;
    margin: 0 4px;
  }
  .form-group span.ifAbroadMarket{
    width: 164px;
  }
  .form-group span.validate-tip{
    width: 100%;
    padding: 10px 140px 0;
    text-align: left;
    display: block;
    color: #f00;
  }
  .form-group mark{
    background: #fff;
    color: #f00;
    margin: 4px;
    font-size: 16px;
  }
  .form-group > input{
    width: 320px;
    height: 32px;
    padding-left: 20px;
  }

  input.date-deadline{
    width: 120px;
    margin-right: 20px;
  }
  .form-group select{
    width: 100px;
    height: 32px;
    color: #333;
  }
  .el-button{
    background: #fff;
    border: 1px solid #14B8D4;
    border-radius: 10px;
    color: #14B8D4;
    font-weight: 600;
    padding: 6px 16px;
  }

  .count-wrapper{
    padding: 10px;
    border-bottom: 1px solid #eee;

    > span {
      margin-right: 5px;
    }
  }

  .header-title .position-right{
    position: absolute;
    right: 0;
  }
  .el-radio {
    margin-right: 20px;
  }
</style>
<style>
  @import '../../../public/static/css/common.css';
</style>


<template>
  <section>
    <div class="count-wrapper">
      <span>公司总数：{{companyNum}}</span>
      <span>客户总数：{{customerNum}}</span>
    </div>
    <div class="apply-for-content">
      <section>
        <header class="content-header">
          <div class="header-title">
            <span>客户列表</span>
            <el-button class="position-right" @click="batchApply()">批量申请延期</el-button>
          </div>
        </header>
        <div>
          <div class="header-title pt-10 pl-10">
            <span>{{ tab === 'com-invest' || tab === 'com-risk' ? '付费状态' : '用户类型' }}</span>
            <el-select v-if="tab === 'com-invest' || tab === 'com-risk'"  class="form-select" v-model="userRole" @change="initCustomerList(true)">
              <el-option value="" label="全部"></el-option>
              <el-option value="2" label="付费"></el-option>
              <el-option value="0" label="试用"></el-option>
              <el-option value="1" label="试用过期"></el-option>
              <el-option value="3" label="付费过期"></el-option>
              <!--<option value="4">未付费</option>-->
            </el-select>

            <el-select v-if="tab === 'com-bidding'" class="form-select" v-model="userRole" @change="initCustomerList(true)">
              <el-option value="" label="全部"></el-option>
              <el-option value="0" label="采购方"></el-option>
              <el-option value="2" label="供应商"></el-option>
            </el-select>

            <el-input class="search-info" v-model="searchInfo" placeholder="查询手机号码" />
            <el-input class="search-info" v-model="searchCompany" placeholder="查询公司" />
            <el-button @click="initCustomerList(true)">查询</el-button>
          </div>
          <div class="query-result" v-show="showDataContainer">
            <div class="table-wrapper" id="iScrollCustomerList">
              <table>
                <thead>
                <tr>
                  <th>
                    <el-checkbox class="checkAll" type="checkbox" v-model="checkModel" @change="checkAll">全选</el-checkbox>
                  </th>
                  <th>手机号</th>
                  <th>姓名</th>
                  <th>公司名称</th>
                  <th v-if="tab === 'com-bidding'">用户类型</th>
                  <th>申请时间</th>
                  <th>通过时间</th>
                  <th>会员有效期</th>
                  <th v-if="tab === 'com-invest'">是否有海外权限</th>
                  <th v-if="tab === 'com-invest' || tab === 'com-risk'">付费状态</th>
                  <th>最近登录时间</th>
                  <th>销售经理</th>
                  <th>申请延期</th>
                  <th v-if="tab === 'com-invest'">申请方向</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in customerList" :key="index" :class="index % 2 === 0 ? '' : 'tr-even'">
                  <td>
                    <el-checkbox v-if="item.postponeNum !== '0'" type="checkbox" :value="false" disabled="disabled" />
                    <el-checkbox v-if="item.postponeNum === '0'" type="checkbox" v-model="item.checked" @change="checkOne(item.applyUserId)" />
                  </td>
                  <td><div>{{item.phone}}</div></td>
                  <td><div>{{item.name}}</div></td>
                  <td class="text-left"><div>{{item.company}}</div></td>
                  <td v-if="tab === 'com-bidding'"><div>{{(item.userRole === '0' || item.userRole === '1') ? '采购方' : (item.userRole === '2' || item.userRole === '3') ? '供应商': '--'}}</div></td>
                  <td><div>{{item.createTime}}</div></td>
                  <td><div>{{item.insertTime}}</div></td>
                  <td><div>{{item.token}}</div></td>
                  <td v-if="tab === 'com-invest'"><div>{{item.abroadMarket + '' === '1' ? '是' : '否'}}</div></td>
                  <td v-if="tab === 'com-invest' || tab === 'com-risk'">
                    <div v-if="item.userRole === '4'" class="unpassed">未付费</div>
                    <div v-if="item.userRole === '3'" class="unpassed">付费过期</div>
                    <div v-if="item.userRole === '2'" class="unpassed">已付费</div>
                    <div v-if="item.userRole === '1'" class="unpassed">试用过期</div>
                    <div v-if="item.userRole === '0'" class="unpassed">试用</div>
                  </td>
                  <td><div>{{item.loginTime}}</div></td>
                  <td><div>{{item.saleName}}</div></td>
                  <td>
                    <div v-if="item.postponeNum === '0'" @click="applyLengthen(item)">
                      <a><img src="../../assets/customerList/apply-lengthen.png" /></a>
                    </div>
                    <div v-if="item.postponeNum !== '0'">
                      <a><img src="../../assets/customerList/apply-not.png" /></a>
                    </div>
                  </td>
                  <td v-if="tab === 'com-invest'">
                    <div>{{ childPlatform(item.childPlatform) }}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <page-control
              v-on:getPageData="renderData"
              v-on:getCustomerList="initCustomerList"
              :customerData="customerListStore"
              :currentPage="currentPage"
              :pageNum="pageNum"
              :firstPageShow="firstPageShow"
              :prePageShow="prePageShow"
              :nextPageShow="nextPageShow"
              :pageRefer="pageRefer"
              :showPageNum="showPageNum"
              :loading="imgLoading">
            </page-control>
          </div>
          <div class="no-data" v-show='noData'>
            <img src="../../assets/noData.png" />
            <span>暂无数据</span>
          </div>
        </div>

      </section>
    </div>

    <div class="dialog-container">
      <!--申请延期-->
      <el-dialog title="申请延期" :visible.sync="applyLengthenDialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>延期时长:</span>
              <el-input class="form-select date-deadline" type="text" v-model="dateDate" />
              <el-select class="form-select" v-model="dateType">
                <el-option value="日">日</el-option>
                <el-option value="周">周</el-option>
                <el-option value="月">月</el-option>
                <el-option value="年">年</el-option>
              </el-select>
            </div>
            <div class="form-group" v-if="tab === 'com-invest'">
              <span class="ifAbroadMarket"><mark>*</mark>是否有海外权限:</span>
              <el-radio type="radio" label="1" name="abroadMarket" v-model="abroadMarket">是</el-radio>
              <el-radio type="radio" label="0" name="abroadMarket" v-model="abroadMarket">否</el-radio>
            </div>
          </form>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitApplyLengthen()" :disabled="isDisabled">提交</el-button>
			    <el-button @click="applyLengthenDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>

      <!--批量申请延期-->
      <el-dialog title="申请延期" :visible.sync="batchApplyLengthenDialogVisible" width="600px">
        <div class="form-container">
          <form>
            <div class="form-group">
              <span><mark>*</mark>延期时长:</span>
              <el-input class="form-select date-deadline" type="text" v-model="batchDateDate" />
              <el-select class="form-select" v-model="batchDateType">
                <el-option value="日">日</el-option>
                <el-option value="周">周</el-option>
                <el-option value="月">月</el-option>
                <el-option value="年">年</el-option>
              </el-select>
            </div>
            <div class="form-group" v-if="tab === 'com-invest'">
              <span class="ifAbroadMarket"><mark>*</mark>是否有海外权限:</span>
              <el-radio type="radio" label="1" name="batchAbroadMarket" v-model="batchAbroadMarket">是</el-radio>
              <el-radio type="radio" label="0" name="batchAbroadMarket" v-model="batchAbroadMarket">否</el-radio>
            </div>
          </form>
        </div>
        <span slot="footer" class="dialog-footer">
			  	<el-button type="primary" @click="submitBatchApplyLengthen()" :disabled="isDisabledBatch">提交</el-button>
			    <el-button @click="batchApplyLengthenDialogVisible = false">取 消</el-button>
			  </span>
      </el-dialog>

    </div>
  </section>
</template>

<script>
  import PageControl from '@/components/PageControl'
  import getConfig from "../../../public/static/common/config";
  import syAxios from "../../../public/static/common/axios";

  export default {
    data(){
      return{
        isDisabled : false,
        isDisabledBatch : false,
        pageSize : 10,
        pageNum : 1,
        currentPage : 0,
        searchInfo : '',
        searchCompany : '',
        companyNum : '',
        customerNum : '',
        phoneError : false,
        applyLengthenDialogVisible : false,
        batchApplyLengthenDialogVisible : false,
        showDataContainer : false,
        noData : false,
        firstPageShow : false,
        prePageShow : false,
        nextPageShow : false,
        showPageNum : true,
        numPerPage : 10,
        imgLoading : false,
        pageRefer : 'customerList',
        currentEditId : '',
        checkedId : [],
        optionsData : [],
        customerList : [],
        customerListStore : [],
        dateDate : '7',
        dateType : '日',
        abroadMarket : '0',
        batchDateDate : '7',
        batchDateType : '日',
        batchAbroadMarket : '0',
        userRole : '',
        checkModel: false
      }
    },
    components: {PageControl},
    props: {
      tab: {
        type: String,
        default: function(){
          return '';
        }
      },
      queryId: {
        type: String,
        default: function(){
          return '';
        }
      },
    },
    watch: {
      queryId: function(newVal){
        if(newVal){
          this.initCustomerList(true);
        }
      }
    },
    mounted() {
      this.initCustomerList(true);
    },
    methods: {
      childPlatform(platform) {
        let text = '';
        switch (platform + '') {
          case '0':
            text = '招采';
            break;
          case '1':
            text = '产融平台';
            break;
          case '2':
            text = '乡村振兴';
            break;
          case '3':
            text = '绿色金融';
            break;
        }
        return text || '--';
      },
      initCustomerList : function(init,numPerPage){
        let that = this;
        let config = getConfig();
        if(numPerPage){
          that.pageSize = Number(numPerPage);
          that.numPerPage = that.pageSize;
        }
        let data = {
          queryId : that.queryId,
          pageNum : that.pageNum,
          numPerPage : that.numPerPage,
          phone : encodeURIComponent(that.searchInfo.trim()),
          company : encodeURIComponent(that.searchCompany.trim()),
          userRole : that.userRole
        };

        if(init){
          data.pageNum = 1;
          that.pageNum = 1;
          that.currentPage = 0;
        }
        that.firstPageShow = false;
        that.prePageShow = false;
        that.nextPageShow = false;

        let url = config.index.customerList.customerRoster;
        if(that.tab === 'com-risk'){
          url = config.index.customerList.customerRosterBond;
        } else if(that.tab === 'com-invest') {
          data.platformNo = 1
        } else if(that.tab === 'com-bidding') {
          data.platformNo = 0
        }
          syAxios({
          ifProxy : true,
          url : url,
          data : data,
          success : function(d){
            let pageSize = that.pageSize;
            let data = d.data || {},
              data_list = data.data_list || [],
              l = data_list.length,
              statistics_json = data.statistics_json || {};

            let tableData = [];

            that.companyNum = statistics_json.companyNum;
            that.customerNum = statistics_json.customerNum;
            that.checkedId = [];
            that.checkModel = false;
            if(l > 0){
              for(let i=0;i<l;i++){
                let phone = data_list[i].phone || '',
                  name = data_list[i].name || '',
                  company = data_list[i].companyName || '',
                  createTime = data_list[i].createTime || '',
                  insertTime = data_list[i].insertTime || '',
                  token = data_list[i].token || '',
                  abroadMarket = data_list[i].abroadMarket === undefined ? '0' : (data_list[i].abroadMarket + ''),
                  loginTime = data_list[i].loginTime || '',
                  saleName = data_list[i].saleName || '',
                  applyId = data_list[i].applyId || '',
                  applyUserId = data_list[i].applyUserId || '',
                  postponeNum = data_list[i].postponeNum === undefined ? '' : (data_list[i].postponeNum + ''),
                  userRole = data_list[i].userRole === undefined ? '' : (data_list[i].userRole + ''),
                  childPlatform = data_list[i].childPlatform === undefined ? '' : (data_list[i].childPlatform + '');

                tableData.push({
                  phone : phone,
                  name : name,
                  company : company,
                  loginTime : loginTime,
                  createTime : createTime,
                  insertTime : insertTime,
                  token : token,
                  abroadMarket : abroadMarket,
                  saleName : saleName,
                  applyId : applyId,
                  applyUserId : applyUserId,
                  userRole : userRole,
                  postponeNum : postponeNum,
                  childPlatform: childPlatform,
                  checked: false
                })
              }
              that.showDataContainer = true;
              that.noData = false;
            }
            that.customerList = [].concat(tableData);

            if(init){
              if(l > 0){
                that.customerListStore = [].concat(tableData);
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }
              }else{
                that.noData = true;
                that.showDataContainer = false;
              }
            }else{
              if(l > 0){
                that.customerListStore = that.customerListStore.concat(tableData);
                that.firstPageShow = true;
                that.prePageShow = true;
                if(l === pageSize){
                  that.nextPageShow = true;
                  that.pageNum++;
                  that.currentPage = that.pageNum - 1;
                }else{
                  that.nextPageShow = false;
                  that.currentPage = that.pageNum;
                }
              }else{
                that.firstPageShow = true;
                that.prePageShow = true;
                that.nextPageShow = false;
                that.currentPage = that.pageNum - 1;
                that.customerList = that.customerListStore.slice(that.customerListStore.length - that.pageSize,that.customerListStore.length);
              }
            }
          }
        })
      },
      renderData : function(data,currentPage,pageNum,firstPageShow,prePageShow,nextPageShow,pageRefer){
        let that = this;

        that.customerList = [].concat(data);
        that.pageNum = pageNum;
        that.currentPage = currentPage;
        that.firstPageShow = firstPageShow;
        that.prePageShow = prePageShow;
        that.nextPageShow = nextPageShow;
        that.pageRefer = pageRefer;

        that.checkedId = [];
        that.customerList.forEach(function(item){
          if(item.postponeNum === '0' && item.checked){
            if (that.checkedId.indexOf(item.applyUserId) == -1) {
              that.checkedId.push(item.applyUserId);
            }
          }
        })
        that.checkModel = that.checkedId.length > 0 && that.checkedId.length === that.customerList.length;
      },
      applyLengthen : function(data){
        let that = this;
        that.dateDate = '7';
        that.abroadMarket = data.abroadMarket || '0';
        that.currentEditId = data.applyUserId;
        that.applyLengthenDialogVisible = true;
      },
      submitApplyLengthen : function(){
        let that = this;
        let config = getConfig();

        if(that.dateDate.trim() + '' === ''){
          alert('请输入申请时间!');
        }else{
          let data = {
            applyUserId : that.currentEditId,
            abroadMarket : that.abroadMarket,
            dateType : encodeURIComponent((that.dateDate + '').trim() + that.dateType)
          };
          that.isDisabled = true;
          let url = config.index.customerList.applyPostpone;
          if(that.tab === 'com-risk'){
            url = config.index.customerList.applyPostponeBond;
          }
          syAxios({
            ifProxy : true,
            url : url,
            data : data,
            success : function(d){
              alert('申请成功！');

              let customerList = that.customerList;
              for(let i in customerList){
                if(customerList[i].applyUserId + '' === that.currentEditId){
                  customerList[i].postponeNum = '1';
                  customerList[i].abroadMarket = that.abroadMarket || '0';
                }
              }

              that.currentEditId = '';
              that.applyLengthenDialogVisible = false;
              that.isDisabled = false;
            },
            error : function(){
              that.currentEditId = '';
              that.applyLengthenDialogVisible = false;
              that.isDisabled = false;
            }
          })
        }
      },
      batchApply : function(){
        let that = this;
        let batchApplyUserId = that.checkedId;
        if(batchApplyUserId.length === 0){
          alert('请选择延长申请人员！');
        }else{
          that.batchDateDate = '7';
          that.batchAbroadMarket = '0';
          that.batchApplyLengthenDialogVisible = true;
        }
      },
      checkOne : function(applyUserId){
        let that = this;
        let applyUserIdIndex = that.checkedId.indexOf(applyUserId);

        if(applyUserIdIndex >= 0){
          that.checkedId.splice(applyUserIdIndex,1);
        }else{
          that.checkedId.push(applyUserId);
        }
        that.checkModel = that.checkedId.length > 0 && that.checkedId.length === that.customerList.length;
      },
      checkAll : function(isCheckedAll){
        let that = this;
        if (isCheckedAll) {
          that.customerList.forEach(function(item, keys){
            if(item.postponeNum === '0'){
              that.$set(that.customerList[keys], 'checked', true);
              if (that.checkedId.indexOf(item.applyUserId) == -1) {
                that.checkedId.push(item.applyUserId);
              }
            }
          })
        } else {
          that.checkedId = [];
          that.customerList.forEach(function(item, keys){
            if(item.postponeNum === '0'){
              that.$set(that.customerList[keys], 'checked', false);
            }
          })
        }
      },
      submitBatchApplyLengthen : function(){
        let that = this;
        let config = getConfig();
        if(that.batchDateDate.trim() + '' === ''){
          alert('请输入申请时间!');
        }else{
          let data = {
            applyUserId : that.checkedId.join(','),
            abroadMarket : that.batchAbroadMarket,
            dateType : encodeURIComponent((that.batchDateDate + '').trim() + that.batchDateType)
          };
          that.isDisabledBatch = true;
          let url = config.index.customerList.batchApplyPostpone;
          if(that.tab === 'com-risk'){
            url = config.index.customerList.batchApplyPostponeBond;
          }
          syAxios({
            ifProxy : true,
            url : url,
            data : data,
            success : function(d){
              alert('申请成功！');

              that.initCustomerList(true);

              that.checkedId = [];
              that.batchApplyLengthenDialogVisible = false;
              that.isDisabledBatch = false;
              that.checkModel = false;
            },
            error : function(){
              that.checkedId = [];
              that.batchApplyLengthenDialogVisible = false;
              that.isDisabledBatch = false;
              that.checkModel = false;
            }
          })
        }
      },
    }
  }
</script>
