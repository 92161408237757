<style lang="less">
  @import (reference) '../theme/var/var';
  .com-tabs{
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;

    ul{
      width: 100%;
      text-align: center;

      li{
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;

        a{
          display: inline-block;
          padding: 5px 10px;
          border: 1px solid @default-border-color;

          &.active{
            background-color: @color-primary;
            color: #fff;
          }
        }
      }
    }
  }
</style>

<template>
 <div class="com-tabs">
   <ul>
     <li v-for="(item, index) in tabs" :key="index" @click="tabChange(index)">
       <a role="button" :class="item.active ? 'active' : ''">{{item.text}}</a>
     </li>
   </ul>
 </div>
</template>

<script type="text/ecmascript-6">

  export default{
    props: {
      tabs : {
        type : Array,
        default : function(){return []}
      },
    },
    methods : {
      tabChange : function(index){
        this.$emit('tabChange', index);
      }
    },
  }
</script>
