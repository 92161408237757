<style lang="less">
.apply-for {

  >section{
    background-color: #fff;
  }
}
</style>

<template>
	<section class="apply-for">
		<permit-header v-on:getQueryId="initQueryId"></permit-header>
    <com-tabs :tabs="tabsData" @tabChange="tabChange"></com-tabs>
    <keep-alive>
      <component :is="curTab" :tab="curTab" :queryId="queryId"></component>
    </keep-alive>
	</section>
</template>

<script>
  import ComTabs from '../../components/tabs.component'
  import ComInvest from './block.component'
	import PermitUserHeader from '../../components/permitUserHeader'

	export default{
		data(){
			return{
        curTab: 'com-invest',
        tabsData: [
          {text: '产融平台申请', com: 'com-invest', active : true},
          {text: '债权平台申请', com: 'com-risk', active : false},
          {text: '智能招采申请', com: 'com-bidding', active : false}
        ],
				isDisabled : false,
				isDisabledBatch : false,
				queryId : ''
			}
		},
		components : {
			'permit-header' : PermitUserHeader,
      ComTabs,
      ComInvest,
      ComRisk: {extends: ComInvest},
      ComBidding: {extends: ComInvest},
		},
		methods : {
      tabChange (index){
        let data = this.tabsData || [];
        for (let i = 0; i < data.length; i++) {
          data[i].active = false;
        }
        data[index].active = true;
        this.curTab = data[index].com || '';
      },
			initQueryId : function(queryId){
				this.queryId = queryId;
			},
		}
	}
</script>
