/**
 * 添加事件
 * @param el
 * @param type
 * @param fn
 * @param capture
 */
const addEvent = (el, type, fn, capture) => {
  if (el.length && el.length > 0) {
    for (let i = 0, l = el.length; i < l; i++) {
      let curEl = el[i];
      if (curEl.addEventListener) {
        curEl.addEventListener(type, fn, !!capture);
      } else {
        if (typeof fn === 'object' && fn.handleEvent) {
          curEl.attachEvent('on' + type, function () {
            fn.handleEvent.call(fn);
          });
        } else {
          curEl.attachEvent('on' + type, fn);
        }
      }
    }
  } else {
    if (el.addEventListener) {
      el.addEventListener(type, fn, !!capture);
    } else {
      if (typeof fn === 'object' && fn.handleEvent) {
        el.attachEvent('on' + type, function () {
          fn.handleEvent.call(fn);
        });
      } else {
        el.attachEvent('on' + type, fn);
      }
    }
  }

  return {
    destroy: function () {
      removeEvent(el, type, fn, capture);
    }
  };
};

/**
 * 移除事件
 * @param el
 * @param type
 * @param fn
 * @param capture
 */
const removeEvent = (el, type, fn, capture) => {
  if (el.removeEventListener) {
    el.removeEventListener(type, fn, !!capture);
  } else {
    if (typeof fn === 'object' && fn.handleEvent) {
      el.detachEvent('on' + type, function () {
        fn.handleEvent.call(fn);
      });
    } else {
      el.detachEvent('on' + type, fn);
    }
  }
};

/**
 * 触发事件
 * @param target  元素或元素集合
 * @param type  事件类型
 * @param bubbles 是否冒泡
 */
const dispatchEvent = (target, type, bubbles) => {
  let elements = target;
  if (Array.isArray(target) === false) {
    elements = [target];
  }
  if (bubbles === undefined || bubbles === null) {
    bubbles = true;
  }
  for (let i = 0, l = elements.length; i < l; i++) {
    let el = elements[i];
    let evt = document.createEvent('HTMLEvents');
    evt.initEvent(type, !!bubbles, true);
    el.dispatchEvent(evt);
  }
};

export default {
  addEvent,
  removeEvent,
  dispatchEvent
};
