<style lang="less">
  .apply-for {

    >section{
      background-color: #fff;
    }
  }
</style>

<template>
	<section class="apply-for">
		<permit-header v-show="changeListFlag + '' === '1'" v-on:getQueryId="initQueryId"></permit-header>
		<permit-header v-show="changeListFlag + '' === '0'" v-on:getQueryId="initQueryId"></permit-header>
    <com-tabs :tabs="tabsData" @tabChange="tabChange"></com-tabs>
    <keep-alive>
      <component :is="curTab" :tab="curTab" :queryId="queryId"></component>
    </keep-alive>
	</section>
</template>
com-invest.component.vue
<script>
	import PermitUserHeader from '../../components/permitUserHeader'
  import ComTabs from '../../components/tabs.component'
  import ComInvest from './block.component'

	export default{
		data(){
			return{
        curTab: 'com-invest',
        tabsData: [
          {text: '产融平台申请', com: 'com-invest', active : true},
          {text: '债权平台申请', com: 'com-risk', active : false},
          {text: '智能招采申请', com: 'com-bidding', active : false}
        ],
				changeListFlag : '1',
				queryId : ''
			}
		},
		components : {
			'permit-header' : PermitUserHeader,
      ComTabs,
      ComInvest,
      ComRisk: {extends: ComInvest},
      ComBidding: {extends: ComInvest}
		},
    created() {
      let type = this.$route.query.type || '';
      if (type !== '') {
        this.tabsData.forEach(item => {
          item.active = false;
          if (item.com === type) {
            item.active = true;
            this.curTab = item.com;
          }
        })
      }
    },
		methods : {
      tabChange (index){
        let data = this.tabsData || [];
        for (let i = 0; i < data.length; i++) {
          data[i].active = false;
        }
        data[index].active = true;
        this.curTab = data[index].com || '';
        this.$router.replace({
          path: 'index',
          query: {
            type: data[index].com
          }
        })
      },
			initQueryId : function(queryId){
				this.queryId = queryId;
			},
		}
	}
</script>
