import { render, staticRenderFns } from "./companySearch.component.vue?vue&type=template&id=2eff1aee&scoped=true&"
import script from "./companySearch.component.vue?vue&type=script&lang=js&"
export * from "./companySearch.component.vue?vue&type=script&lang=js&"
import style0 from "./companySearch.component.vue?vue&type=style&index=0&id=2eff1aee&prod&lang=css&"
import style1 from "./companySearch.component.vue?vue&type=style&index=1&id=2eff1aee&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eff1aee",
  null
  
)

export default component.exports